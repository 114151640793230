.Loader, .gridLoader {
	display: block!important;
	margin-left:-6em;
	max-width: 80px!important;
	max-height: 80px!important;
	position: fixed;
	top: 40% !important;
	left: 50% !important;
	z-index: 9999;
}
.gridLoader {
	position: relative !important;
	top: 20% !important;
}
.Loader::after, .gridLoader::after {
	content: " "!important;
	display: block!important;
	position: absolute!important;
	top: 45%!important;
	left: 45%!important;
	transform: translate(-50%, -50%);
	z-index: 2!important;
	width: 100px!important;
	height: 100px!important;
	margin: 8px!important;
	border-radius: 50%!important;
	border: 6px solid #3826db!important;
	border-color: #3826db transparent #3826db transparent!important;
	animation: lds-dual-ring 1.2s linear infinite!important;
}

@media (max-width: 767px) {
	.Loader, .gridLoader {
		left: 60% !important;
	}
}


@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
