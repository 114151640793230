tds-typography__paragraphbody {
  margin: 0 !important;
  /* background: #fcfcfc !important; */
  background: #ffffff !important;
  font-family: Arial, sans-serif !important;
  padding: 0px !important;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block !important;
}

*,
::after,
::before {
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

.page-inner,
.page-wrapper {
  width: 100% !important;
  position: relative !important;
  overflow: hidden !important;
}

html, body, p, ul,  li, a, button, span, label, select{
  font-family: Arial, sans-serif !important;
  font-size:15.3px !important
}

.body_modal{
  margin-right:15px !important;
  overflow-y:hidden !important;
  overflow-x:hidden !important;
}

.tippy-content div span h3 {
 font-family: Arial, sans-serif !important;
 font-size: 15.3px !important;
}

@media (max-width: 768px){
  .body_modal{
    margin-right:0px !important;
    overflow-y:hidden !important;
    overflow-x:hidden !important;
  }
  .tds-header__h1{
    font-size: 1.802rem !important;
  }
  .tds-header__h2, .quickLinksHeader h2{
    font-size: 1.609rem;
  }
  .tds-header__h3{
    font-size: 1.424rem !important;
  }
  .tds-header__h4{
    font-size: 1.266rem !important;
  }
}

.mt-6,
.my-6 {
  margin-top: 5rem !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 1200px) {
  .paymentDetails .container, .page-header .container, .container-sm, .container-md, .container-lg, .container-xl {
     width: 1224px !important;
     padding-left: 15px !important;
     padding-right: 15px !important;
  }
}
.header__logo-wrapper {
  border: none !important;
  margin: 20px 15px 0 !important;
  padding: 0 !important;
  min-height: 54px !important;
  color: rgba(0, 0, 0, .5) !important;
  box-sizing: border-box !important;
}

.header__logo {
  margin-top: 6px !important;
  display: block !important;
  width: 178px !important;
  height: 52px !important;
}

.tds-header__h3__medium {
  font-family: Arial, sans-serif !important;
  font-weight: inherit !important;
}

.tds-header__medium {
  font-family: Arial, sans-serif !important;
  font-weight: inherit !important;
}

.uhc-sans-medium {
  font-family: Arial, sans-serif !important;
}

.loginForm {
  width: 90% !important;
  max-width: 400px !important;
  margin-top: 3rem !important;
}

.loginForm .tds-link {
  font-size: 14px !important;
}

/* alert message  */
.alert {
  position: relative !important;
  padding: 1px !important;
  margin-bottom: 1rem !important;
  border: .167rem solid transparent !important;
  border-radius: 0.25rem !important;
  font-weight: bold !important;
}

.alert .img-icon {
  position: absolute !important;
  font-size: 2em !important;
  width: 3.25rem !important;
  height: 2rem !important;
  top: 40% !important;
  margin-top: -1rem !important;
  text-align: center !important;
}

.alert .alert-body {
  color: #333333 !important;
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  padding: .833rem 1.5rem .833rem 1.083rem;
  margin-left: 3.25rem;
  min-height: 2.917rem !important;
  line-height: 120% !important;
  background: #fff !important;
}

.alert-danger {
  background-color: #E91B18 !important;
  border-color: #E91B18 !important;
}
/* form components  */
label.form-label {
  display: inline-block !important;
  margin-bottom: 0.2rem !important;
  color: #484848 !important;
  font-size: 13px !important;
  line-height: 1.3 !important;
  font-weight: 600 !important;
  font-family: Arial, sans-serif !important;
}

.form-control {
  display: block !important;
  width: 100% ;
  max-width: 100%;
  height: calc(1.5em + 0.5rem + 2px) !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #333333 !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.paymentTotalAmt {
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.3;
    color: #002677;
    font-family: Arial,sans-serif !important;
    margin: 5px 0;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none !important;
  }
}

.form-control::-ms-expand {
  background-color: transparent !important;
  border: 0 !important;
}

.form-control:-moz-focusring {
  color: transparent !important;
  text-shadow: 0 0 0 #495057 !important;
}

.form-control:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

.form-control::-webkit-input-placeholder {
  color: #6c757d !important;
  opacity: 1 !important;
}

.form-control::-moz-placeholder {
  color: #6c757d !important;
  opacity: 1 !important;
}

.form-control:-ms-input-placeholder {
  color: #6c757d !important;
  opacity: 1 !important;
}

.form-control::-ms-input-placeholder {
  color: #6c757d !important;
  opacity: 1 !important;
}

.form-control::placeholder {
  color: #6c757d !important;
  opacity: 1 !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef !important;
  opacity: 1 !important;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.form-group {
  margin-bottom: 1rem !important;
}

.form-help-text {
  display: block !important;
  margin-top: 0.25rem !important;
  font-style: italic
}

.text-muted {
  color: #535A5F !important;
}

.small,
small {
  font-size: 80% !important;
  font-weight: 400 !important;
}

.invalid-feedback {
  display: none;
  width: 100% !important;
  margin-top: 0.25rem !important;
  /* font-size: 80% !important; */
  font-size: 13.2px !important;
  color: #D0021B !important;
}

.invalid-feedback span{
  font-size: 13.2px !important;
}

.invalid-tooltip {
  position: absolute !important;
  top: 100% !important;
  left: 0 !important;
  z-index: 5 !important;
  display: none !important;
  max-width: 100% !important;
  padding: 0.25rem 0.5rem !important;
  margin-top: .1rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  color: #fff !important;
  background-color: rgba(220, 53, 69, 0.9) !important;
  border-radius: 0.25rem !important;
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
  display: block !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border: 2px solid #E91B18 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important; */
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTRweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMTQgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+QWxlcnQvaWNfd2FybmluZzwvdGl0bGU+CiAgICA8ZyBpZD0iRGVza3RvcC1WaWV3IiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iUGF5bWVudC10b2dnbGUtMyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTg2Mi4wMDAwMDAsIC01NDUuMDAwMDAwKSIgZmlsbD0iI0QwMDIxQiI+CiAgICAgICAgICAgIDxnIGlkPSJPdGhlci1Db250YWluZXIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDcyNy4wMDAwMDAsIDQ5OS4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJBbGVydC9pY193YXJuaW5nIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMzAuMDAwMDAwLCA0MC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNNS41LDE3LjUgTDE4LjUsMTcuNSBMMTIsNi41IEw1LjUsMTcuNSBMNS41LDE3LjUgWiBNMTIuNTkwOTA5MSwxNS43NjMxNTc5IEwxMS40MDkwOTA5LDE1Ljc2MzE1NzkgTDExLjQwOTA5MDksMTQuNjA1MjYzMiBMMTIuNTkwOTA5MSwxNC42MDUyNjMyIEwxMi41OTA5MDkxLDE1Ljc2MzE1NzkgTDEyLjU5MDkwOTEsMTUuNzYzMTU3OSBaIE0xMi41OTA5MDkxLDEzLjQ0NzM2ODQgTDExLjQwOTA5MDksMTMuNDQ3MzY4NCBMMTEuNDA5MDkwOSwxMS4xMzE1Nzg5IEwxMi41OTA5MDkxLDExLjEzMTU3ODkgTDEyLjU5MDkwOTEsMTMuNDQ3MzY4NCBMMTIuNTkwOTA5MSwxMy40NDczNjg0IFoiIGlkPSJWZWN0b3IiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+") !important;
  /* background-image: url("./tokens/icons/ic_warning.svg") !important; */
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #e91b18 !important;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem) !important;
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem) !important;
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: #e91b18 !important;
}

.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip,
.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip {
  display: block !important;
}

.no-capitalize-transform {
  text-transform: none !important;
}


.tds-button {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  letter-spacing: 0px;
  font-weight: 400; 
}

.tds-modal .tds-button{
  text-transform: none;
}

.page-title {
  background-color: rgb(0, 38, 119) !important;
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.change-details-icon .tds-link__icon--medium {
  height: 20px !important;
  width: 22px !important;
}

.change-details-icon-span {
  cursor: pointer !important;

}

.change-details-icon-span:hover {
  text-decoration: underline !important;
}

.tds--highimpact {
  color: #002677 !important;
}


.token-color-gray-dark-base {
  color: #333333 !important;
}

.token-color-gray-dark-text {
  color: rgb(51, 51, 51) !important;
}

.token-color-gray-dark-text-bold {
  color: rgb(51, 51, 51) !important;
  font-weight:700;
}

.uhc-recurring-payment {
  position: relative !important;
  top: 0px !important;
}

.color-gray-base {
  background-color: #fcfcfc !important;
}

.color-black {
  color: #333 !important;
}

.center {
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
  vertical-align: middle !important;
}

.cancel-spinner {
  width: 100%;
  display: flex !important;
  justify-content: center !important;
}

.float-L {
  float: left !important;
}

.float-R {
  float: right !important;
}

.align_Left_container {
  padding: 52px 40px 72px 43px !important;
}

.font-W100 {
  font-weight: 100 !important;
}

.pad0 {
  padding: 0 !important;
}

.amountText {
  /* opacity: 100% !important; */
  color: #196ECF 100% !important;
  font-face: Arial, sans-serif !important;
  font-size: 15px !important;
  character: auto !important;
  line: 19px(1.2) !important;
  paragraph: 0px !important;
}

.amountText:hover {
  text-decoration: underline !important;
  cursor: pointer !important;
}

.circle {
  /* width: 34px !important;
  height: 34px !important; */
  /* border: 2px solid #002677 !important; */
  /* border-radius: 50% !important; */
  /* text-align: center !important; */
  margin-right: 11px !important;
  /* display: inline-block  !important;
  font-size: 1.5rem !important;
  font-weight: 600 !important; */
  line-height: 1.1 !important;
  /* color: #002677 !important; */
}

.payment-detail-label-div {
  /* margin-left: 0.5rem !important; */
}



.formDiv {
  margin: 30px 0 0 50px !important;
}

.radioCircle {
  width: 20px !important;
  height: 20px !important;
}

.pad34 {
  padding-left: 34px !important;
}

.pad5 {
  padding-top: 5px !important;
}

.pad10 {
  padding: 10px !important;
}

.pad20 {
  padding: 20px !important;
}

.pad25 {
  padding: 25px !important;
}

.pad40 {
  padding-left: 40px !important;
}

.pad55 {
  padding-left: 55px !important;
}

.pad-B20 {
  padding-bottom: 20px !important;
}

.text-R {
  text-align: right !important;
}

.dpt-left {
  text-align: left !important;
  /* padding-left: 25px !important; */
}

.inputBox {
  width: 130px !important;
  height: 35px !important;
  margin-top: 8px !important;
  border-radius: 4px !important;
  border: 1px solid !important;
}

.des {
  padding: 35px 0px 0 50px !important;
}

.border {
  border: 1px solid #dbdbdb !important;
  border-width: 1px 0 0 1px !important;
  bottom: 0 !important;
  display: grid !important;
  margin: 12px 0 42px 0 !important;
}


.paymentDetailBorder {
  border: 1px solid #dbdbdb !important;
  border-width: 1px 0 0 1px !important;
  bottom: 0 !important;
  display: grid !important;
  margin: 0px 0 12px 0 !important;
}

label {
  display: inline-block !important;
  margin-bottom: .3rem !important;
}

.form-text {
  display: block !important;
  margin-top: .25rem !important;
}

.headerForm {
  margin: 5px 0 0 50px !important;
}

.content-align-51 {
  padding-left: 51px !important;
}

@media(max-width:590px) {

  .content-align-51 {
    padding-left: 0 !important;
  }
}

.formBorder {
  border: 1px solid #006dd6 !important;
  /* margin: 0 !important; */
  border-radius: 6px !important;

}

.summery {
  /* height: 450px !important;*/
  border: 1px solid #ccc !important;
  border-radius: 0px 0px 6px 6px !important;
}

.borderBottom {
  border-bottom: 1px solid #ccc !important;
}

.link-underline {
  text-decoration: underline !important;
}

.loginForm .tds-link__icon {
  fill: #ffffff !important;
  height: 25px !important;
  width: 24px !important;
}

.content-center-btn {
  justify-content: center !important;
  align-items: center !important;
  display: inline-flex !important;
}

.content-center {
  justify-content: center !important;
  align-items: center !important;
  display: grid !important;
}

.content-center-btn a {
  display: flex !important;
}

.member-nxt-btn {
  width: 120px !important;
}

.member-confirm-pay-btn {
  width: 212px !important;
}

.banner-back-btn {
  height: 45px !important;
  width: 45px !important;
  transform: rotate(270deg) !important;
  /* margin-top: 20px !important;
  margin-right: 35px !important;
  margin-left: 20px !important; */
}

.footer {
  position: inherit !important;
  left: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  text-align: left;
}

.footer-list {
  list-style-type: none !important;
}

.footer-list li {
  font-size: 20px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.bold-text {
  color: #000 !important;
}

.light-color {
  color: #196ecf !important;
}

.footer-flex {
  display: flex !important;
  justify-content: space-evenly !important;
  width: 80% !important;
  height: 150px !important;
}

.p-text {
  text-align: left !important;
  padding: 0px 0px 0px 40px !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  margin: 0px !important;
}

.F-text {
  margin: 0px 0px 0px 89px !important;
}

.F-text ul {
  list-style-type: none !important;
  margin: 7px 0px 0px 0px !important;
  text-align: left !important;
}

.F-text ul li {
  display: inline !important;
}

.F-text ul li a {
  font-weight: 600 !important;
  text-decoration: none !important;
  color: #000 !important;
}

.confirm-payment {
  box-sizing: border-box !important;
  height: 51px !important;
  width: 100% !important;
  color: #066804 !important;
  font-family: Arial, sans-serif !important;
  font-size: 20px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 25px !important;
  text-align: center !important;
  border: 1px solid #0D880B !important;
  background-color: #DEF2DE !important;
  margin-top: 10px !important;
}

.confirm-payment-2 {
  height: 30px !important;
  width: 100% !important;
  color: #066804 !important;
  font-family: Arial, sans-serif !important;
  font-size: 20px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 25px !important;
  text-align: center !important;
  margin-top: 10px !important;
}

.confirm-payment-text-2 {
  height: 23px !important;
  width: 100% !important;
  color: #277AD8 !important;
  font-family: Arial, sans-serif !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 22.4px !important;
  text-align: center !important;
  margin-top: 25px !important;
}

/* .display-inline {
  display: inline !important;
} */
/* .summary-input-wrap {
  height: 37px !important;
  width: 451px !important;
} */
.summary-input-wrap-2 {
  box-sizing: border-box !important;
  height: 36px !important;
  width: 100% !important;
  border: 1px solid #333333 !important;
  border-radius: 4px !important;
  background-color: #FFFFFF !important;
  float: left !important;
}

.summary-input-text {
  height: 22.4px !important;
  width: 357px !important;
  color: #333333 !important;
  font-family: Arial, sans-serif !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 22.4px !important;
  margin-left: 15px !important;
  margin-top: 6px !important;
}

.send-button-wrap {
  height: 34px !important;
  width: 77px !important;
  border-radius: 0px 4px 4px 0px !important;
  background-color: #002677 !important;
  margin-left: -4px !important
}

.send-button-text {
  color: #FFFFFF !important;
  font-family: Arial, sans-serif !important;
  font-size: 15px !important;
  font-weight: 100 !important;
  letter-spacing: 0.15px !important;
  line-height: 19.8px !important;
}

.medicare-only .tds-link {
  font-family: Arial, sans-serif !important;
}

.hmfm-id .tds-link {
  font-family: Arial, sans-serif !important;
  cursor: pointer;
}

.sp-header {
  line-height: 0px !important;
}

.pc-header {
  font-family: Arial, sans-serif !important;
  font-weight: 700;
}

.ibc {
  border: 1px solid #333333 !important;
  border-radius: 4px !important;
  width:48% !important;
  min-height: 24px !important;
}

.manageWallet .ibc {
  width:48% !important;
}

.input-error-text {
  color: #e91b18 !important;
}

/* .cannot-exceed{
  margin-left: 40px !important;
} */
.p-sub-error {
  margin-left: 55px !important;
}

.input-group-t {
  display: flex !important;
  border: 1px solid #000 !important;
  border-radius: 3px !important;
}

.input-prepend-t {
  margin-right: -1px !important;
  /* width: 25px !important; */
}

.input-prepend-text-t {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  /* text-align: center !important; */
}

.input-prepend-text-t button {
  margin-top: 3px !important;
  font-size: 16px !important;
  background-color: #fcfcfc !important;
  border: none !important;
  line-height: 28px !important;
}

.oa-input-control {
  /* width: 100px !important; */
  /* height: 36px !important; */
  background: rgb(255, 255, 255) !important;
  /* border: 1px solid rgb(51, 51, 51) !important; */
  border-radius: 4px !important;
  /* margin-top: 10px !important; */
}

.oa-int-wrp {
  margin-left: 30px !important;
  width: 135px !important;
}

.oa-input-control.form-control {
  border: none;
}

.pcr-box {
  display: block !important;
}

.mp0-0 {
  margin: 0px !important;
  padding: 0px !important;
}

.tpad10 {
  padding: 0px !important;
}

.pbc-pull-up {
  position: relative !important;
  top: -85px !important;
}

.pbc-pull-up-noerror {
  position: relative !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.print-mr {
  margin-right: 10px !important;
  cursor: pointer;
  text-decoration: none;
}


















#paymentsummary {
  display: inline-block !important;
  height: 100% !important;
}

.RadioDiv {
  margin-top: 0px !important;
}

.link-icon {
  margin-left: 5px !important;
  vertical-align: middle !important;
}

/* .pdd-icon{
  background: url("./tokens/icons/rsz_dollar-sign-27.png") no-repeat scroll 7px 7px !important;
  background-repeat: no-repeat !important;
    background-position: left calc(0.375em + 0.1875rem) center !important;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
    padding-left: calc(1.5em + 0.75rem) !important;
} */
@media (max-width:1024px) {
  #paymentsummary {
    display: inline-block !important;
    height: 100% !important;
    top: 12% !important;
    margin: 0px !important;
    padding: 5px !important;
  }
}

@media print {
  .d-print-mt {
    margin-top: 0rem !important;
  }

  .d-print-mb {
    margin-bottom: 0rem !important;
  }

  .d-print-zero-pb {
    margin-bottom: 0rem !important;
  }

  .prefooter {
    display: none !important;
  }

  .uhcg-nav .unauthenticated {
    display: none !important;
  }

  .tds-card .row {
    margin-top: 1rem !important;
  }

  .tds-card__content {
    padding-top: 0px !important;
  }

  .print-col {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media (max-width:768px) {
  #paymentsummary {
    display: inline-block !important;
    height: 100% !important;
    width: 90% !important;
    margin-bottom: 20px !important;
    height: fit-content !important;
    border: 1px solid rgb(0, 109, 214) !important;
    box-shadow: 0px 2px 16px 0px rgba(161, 199, 211, 0.15) !important;
    border-radius: 5px !important;
    padding: 5px !important;
  }

  .p-sub-error {
    margin-left: 2px !important;
    widows: 98% !important;
  }

  .pbc-pull-up {
    position: relative !important;
    top: 0px !important;
  }

  .dpt-left {
    text-align: left !important;
    padding-left: 5px !important;
  }

  .tpad10 {
    padding: 10px !important;
  }

  .headerForm {
    margin: 1rem !important;
  }

  .formDiv {
    margin: 0 !important;
    padding: 0 !important;
  }

  .oa-int-wrp {
    margin-left: 25px !important;
    width: 125px !important;
  }

  .mp0-0 {
    margin: 0px !important;
    padding: 0px !important;
  }

  .RadioDiv,
  .payment-detail-radio-div {
    margin: 0px !important;
  }

  .align_Left_container {
    padding: 1rem !important;
  }

  .pdtb-margin {
    margin-bottom: 20px !important;
    padding: 0px !important;
  }

  .pcr-box {
    display: none !important;
  }

  .footer-flex {
    display: flex !important;
    justify-content: space-evenly !important;
    width: 80% !important;
    height: auto !important;
  }

  .F-text {
    margin: 0px 0px 0px 0px !important;
  }

  .mymodal {
    width: 90% !important;
    /* transform: translate(-67%, -50%) !important;
    height: 90% !important; */
  }

}

@media (max-width: 992px) {
  .sm-show {
    display: inherit !important;
  }

  .sm-hide {
    display: none !important;
  }
}

@media (min-width: 993px) {
  .sm-show {
    display: none !important;
  }
}

@media only screen and (min-width: 501px) {
  .xs-show {
    display: none !important;
  }
}

@media (min-width: 500px) and (max-width: 1024px) {
  .paymentDetailsIpad {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .content-align-51 {
    padding-left: 5px !important;
  }

}

@media only screen and (max-width: 500px) {

  .paymentDetails> :first-child {
    padding: 0px !important;
  }

  .content-align-51 {
    padding-left: 5px !important;
  }

  .xs-show {
    display: inherit !important;
  }

  .xs-hide {
    display: none !important;
  }
  .manageWallet .xs-hide {
    display: block !important;
  }
  .alert-body {
    margin-left: 0 !important;
    padding: .833rem 1rem !important;
  }

  #paymentsummary {
    display: inline-block !important;
    height: 100% !important;
    width: 90% !important;
    margin: 0 auto !important;
    margin-bottom: 20px !important;
    border: 1px solid rgb(0, 109, 214) !important;
    box-shadow: 0px 2px 16px 0px rgba(161, 199, 211, 0.15) !important;
    border-radius: 5px !important;
  }

  .mb-sm-2 {
    margin-bottom: 10px !important;
  }

  .pbc-pull-up {
    position: relative !important;
    top: 0px !important;
  }

  .input-group-t {
    width: 55% !important;
  }

  .RadioDiv,
  .formDiv {
    margin: 0px !important;
  }

  .payment-detail-radio-div {
    margin: 0px 0px 0px 12px !important;
  }

  .headerForm {
    margin-left: 0px !important;
  }

  .change-details-icon {
    text-align: right !important;
    margin-bottom: 10px !important;
  }

  .change-details-icon:nth-child(1) {
    order: 0 !important;
  }

  .align_Left_container {
    padding: 52px 20px 72px 20px !important;
  }

  .topheader {
    order: 1 !important;
  }

  .pcr-box {
    display: none !important;
  }

  .payment-mas-top {
    position: relative !important;
    top: -15px !important;
    background: #fff !important;
    border-radius: 4px !important;
    /* margin: 0 10px !important; */
  }

  .header__logo-wrapper {
    margin: 14px 25px 0 5px !important;
  }

  .header__logo {
    width: auto !important;
    max-width: 160px !important;
    margin: 0 !important;
  }

  .banner-back-btn {
    margin-right: 15px !important;
    float: right !important;
  }

  .p-sub-cont {
    /* margin-top: -90px !important; */
    margin-top: 30px !important;
  }

  .p-sub-error {
    /* margin-top: -120px !important; */
    margin-left: -14px !important;
    width: 99% !important;
    top: 77px !important;
    position: absolute !important;
  }

  .uhc-recurring-payment {
    position: relative !important;
    top: 0px !important;
  }

  .uhc-tile-brd-wdgt {

    margin-bottom: 20px !important;

    background-color: #fff !important;
  }

  .uhc-tile-brd-wdgt-payment {
  /* margin-top: -240px !important; */
    background-color: #fff !important;
  }

  .uhc-pay-txt-cont .uhc-pay-txt {
    color: #fff !important;
    position: relative !important;
    top: -200px !important;
    text-align: center !important;
    font-size: 80% !important;
    font-weight: 400 !important;
  }

   .page-title{
   min-height: 250px !important;
  }
  .banner-title {
    margin: 0 auto;
  }

  .mp0-0 {
    margin: 0px !important;
    padding: 0px 0px 0px 16px !important;
  }

  .oa-int-wrp {
    width: 255px !important;
    margin-left: 30px !important;
  }

  .dpt-left {
    position: relative !important;
    top: -74px !important;
    left: 126px !important;
  }

  .mbm-top {
    margin-top: 30px !important;
  }

  .tpad10 {
    padding: 0px !important;
  }

  /* .container {
        height: 50px !important;
        width: 100% !important;
    }
    .img-responsive {
        height: 35px !important;
        padding: 10px !important;
    }
    .tds-header__h1 {
        text-transform: capitalize !important;
        padding: 10px !important;
    } */

  .footer {
    position: unset !important;
    left: 0 !important;
    bottom: 0 !important;
    width: 100% !important;
    text-align: center !important;
  }

  .footer-flex {
    display: flex !important;
    justify-content: space-evenly !important;
    width: 80% !important;
    height: auto !important;
    flex-direction: column !important;
  }

  .F-text {
    margin: 0px 0px 0px 43px !important;
  }

  .F-text ul {
    list-style-type: none !important;
    margin: 10px 0px 19px 0px !important;
    text-align: left !important;
  }

  .alert {
    margin: 1rem
  }

  .alert-xs {
    margin: 1rem 0px 1rem 0px !important;
  }
}

.mb-20 {
  margin-bottom: 20px !important;
}

/* AEM overrides */
.uhcg-nav.unauthenticated {
  border-bottom-width: 0px !important;
  margin-bottom: 9px;
}

a+a {
  border-left: 0px solid #e0e0e0 !important;
}

.uhcg-nav:before {
  height: 0px !important;
}

.react-datepicker-wrapper {
  display: block !important;
}

.mw-100 {
  max-width: 100% !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

label.form-label.font-weight-normal {
  font-weight: 500 !important;
}

a:focus,
input:focus,
.form-control:focus,
.react-datepicker__day--keyboard-selected:focus,
select:focus,
.form-control:invalid:focus,
.form-control.is-invalid:focus,
button:focus:not(.react-datepicker__navigation) {
  outline: 5px solid #6C992B !important;
  position: relative;
  
}

.react-datepicker__navigation:focus,
.modal-content-container:focus {
  outline: 5px solid #6C992B !important;
}

a.error-href+a.error-href {
  margin-left: 0px !important;
  padding-left: 0px !important;
}

a.error-href {
  text-decoration: none !important;
  cursor: pointer;
}
.alert-body button:hover {
  text-decoration: underline;;
}
.error-href {
  padding-right: 0px !important;
  padding-left: 3px !important;
  cursor: pointer;
}

.open-new-window {
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -10px;
}

.linkBtn {
  background-color: white;
  border-width: 0;
}

.ReactModal__Content {
  overflow-y: auto;
}

/* mobile and tab */
@media (min-width: 320px) and (max-width: 1024px) {
  .footer-list .last-updated {
    text-align: center !important;
    float: none !important;
    padding: 3px;
  }

  .footer-list .copy-right-info {
    text-align: center !important;
    float: none !important;
    padding: 3px;
  }
}


.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0
}

span.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0
}

#rec_sch_id .sr-only {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0
}

.btn {
  text-shadow: 0 0 black !important;
}

fieldset {
  border-width: 0;
}

.loginForm .form-label{
  white-space: nowrap !important;
  display: flex !important;
  align-items: flex-end !important;
}

.config-box {
  background: #fff;
  border: 1px solid #dbdbdb;
  box-shadow: 0 2px 8px 0 rgba(161,199,211,.15);
  border-radius: 0 0 5px 5px;
}

.config-box-border-line {
  border-bottom: 1px solid #ccc!important;
  padding-bottom: 1rem;
  padding-left: 10px;
}

.review-request {
  display: inline-flex;
}


@media (min-width: 769px)
{
  .early-warning-zero {
    width: auto !important;
  }
}

@media all and (-ms-high-contrast:none)
{
.early-warning-zero { width:520px !important } /* IE10 */
*::-ms-backdrop, .early-warning-zero { width:520px !important } /* IE11 */
}

.text-blur {
	filter: blur(8px);
	-webkit-filter: blur(8px);
  pointer-events: none;
}

.text-semi-blur {
	filter: blur(4px);
	-webkit-filter: blur(4px);
}

span.download-csv-btn{
  position: relative;
  text-transform: initial;
  font-weight: bold;
}
