table td, table th {
  padding: 0 !important;
  border: unset !important;
}

td, th {
    padding-left: 0 !important;
    text-align: center !important;
}

.paymentAmountRadios {
    width: 100%;
    max-width: 100%;
    margin-top: 30px;
}

.choosePayMethod {
    width: 100%;
    max-width: 100%;
    margin-bottom: 3rem;
  }

.paymentMethods.moreMethods > .walletPaymentMethod:last-of-type {
  border-bottom:2px solid rgb(218, 219, 220);
}

.recentlySavedItems > .walletPaymentMethod:last-child, .moreSavedItems > .walletPaymentMethod:last-child{
  border-bottom:2px solid rgb(218, 219, 220);
}

.paymentSchedule h3.tds-header__h3 {
  padding-bottom: 20px;
}

.paymentSchedule.updt h3.tds-header__h3 {
  padding-bottom: 10px;
}

.choosePayMethod > h6 {
    margin-left: .5rem;
  }

  .choosePayMethod > h4 {
    font-size: 1.266rem !important;
    margin-top: 1.5rem;
    font-family: Arial, sans-serif !important
  }

.choosePayMethod > .tds-header__h6 {
    margin-top: 1.5rem;
  }
.paymentSchedule {
    margin-bottom: 1.25rem;
  }
.scheduleRadios {
  margin-left: 1.25rem;
}

.scheduleRadiosUpdt{
  margin-left: 0rem;
}

.scheduleRadiosUpdt > form > div > fieldset {
  margin-left: 1rem;
}

.scheduleRadiosUpdt > form > div > fieldset > label:first-child {
    margin-bottom: .75rem !important;
  }

.scheduleRadiosUpdt > form > div > fieldset > label:first-child {
    margin-bottom: .75rem !important;
  }

.scheduleRadios > form > div > fieldset > label:last-child {
    margin-bottom: 1.0rem !important;
  }

.scheduleRadiosUpdt > form > div > fieldset > label:last-child {
    margin-bottom: 1.0rem !important;
  }
  
.datePicker {
    margin-bottom: 2.7rem;
  }

.datePicker.recSchPayDP p.tds-text__paragraph:not(:first-child){
  margin-bottom:0px !important;
}

.datePicker.recSchPayDP .tds-form__select{
  width:40% !important;
}

.scheduleRadios.recSchPayRadios{
  margin: 30px 0px !important ;
}

h5.recentSaved{
  font-size: 19.25px !important;
  font-family: Arial, sans-serif !important;
}

.datePicker.updt{
  margin-bottom: 1.7rem;
}

.datePicker > div > div > div > div > input{
  height: 2.25rem !important;
  min-height: 2.25rem !important;
  min-width: 10.63rem !important;
  padding: 4px 0 !important;
  font-size: 1rem !important;
}



.datePicker > div > div > div > div:nth-child(2) > div:first-child > div > div:first-child {
  width: 66% !important;  
}

.datePicker > div > div > div > div:nth-child(2) > div:first-child > div > div:last-child {
  width: 40% !important;
}

.datePicker > div {
    margin-left: 1.25rem;
  }

.datePicker > p {
    margin-bottom: 1.25rem !important;
  }

.datePicker > div > div > label {
      margin-bottom: 0 !important;
  }

.showLessMorePayments {
    margin-top: 1.4rem;
    font-family: Arial,sans-serif;
    background: none !important;
    border: 0px !important;
    color:rgb(25, 110, 207) !important;
    padding:0px !important;
  }
  .showLessMorePayments:focus{
    border-radius: 0px !important;
  }
.showMoreLessHide {
  margin-bottom: 2rem;
}

.paymentMethods > p {
    margin: 0 !important;
    margin-bottom: 2.6rem !important;
  }

.walletAccount > div > div {
  display: flex;
}
.walletAccountRec > div > div {
  display: flex !important;
}
.walletAccountRec > div > div > div{
  margin-left: 1.5rem;
}
.walletAccount > div > div > div {
  margin-left: 1.5rem;
}

.accountIcon {
  width: 7%;
  display: flex;
  margin-right: 1.25rem;
  align-items: center;
}

.accountIcon > img {
  width: 2.25rem;
  height: 1.5rem;
}
.addPaymentMethod.fedEftForm .eftForm fieldset{
  padding: 0px;
  width: 100%;
}
.addPaymentMethod.fedEftForm .eftForm .formBorder.eftFormWidth{
  max-width:100%
}
.fedRecNote{
  margin:28px 0px;
}
.cancel_Rec_Title + label.tds-form__checkbox.uhc-lineheight-22 {
  margin-top: 25px !important;
}

.tds-header__h4.cancel_Rec_Title{
  font-size: 21.78px !important;
  font-family: Arial, sans-serif !important;
}

.cancel_Rec_Title + p{
  margin:20px 0px !important;
}

.upd-mop{
  text-transform: capitalize;
  font-weight: bold;
}

.uhc-sans-medium.token-color-gray-dark-base.upd-mop{
  font-family: Arial, sans-serif !important;
}

.oa-int-wrp-updt + .enterAmt + .ml-4 {
  margin-left: 19px!important;
}

.oa-int-wrp-updt label, .datePicker.updt label{
  font-weight: normal !important;
  font-size: 1rem !important
}

.oa-int-wrp-updt{
  margin-left: 20px ;
  width: 135px !important;
}

.oa-int-wrp-updt + p#enterAmtInput{
  margin-left:20px !important
}

.update-payment-notice p {
  max-width: 100%;
}

.input-OtherAmt {
  margin-top: .25rem;
}

.input-OtherAmt > form {
  margin: 0 !important;
}

#scheduleDate > div > div {
  width: 13.19rem;
}

#otherAmountInput > div:focus {
  border: none;
  outline: 5px solid #6C992B !important

}

#otherAmountInput > div > span, #otherAmountInput > div > input {
  margin: 0 !important;
  outline: 0 !important;
}

#otherAmountInput > div > span {
  padding: 0 !important;
  justify-content: flex-start !important;
  width: 20px !important;
}

#otherAmountInput > div > input {
  width: 5rem;
}

#otherAmountInput {
  min-width: 135px !important;
}
@media (min-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {
  .update-payment-notice {
    margin-left: 0 !important;
  }
  
}

@media (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {
  .update-payment-notice {
    margin-left: 0 !important;
  }
  .datePicker.recSchPayDP > div {
    margin-left: 2rem;
  }
  .datePicker.recSchPayDP .tds-form__select {
    width: 60% !important;
}

h5.recentSaved{
  margin-left: 32px ;
}
.datePicker.recSchPayDP .tds-form__select.recSchLabel
  .oa-int-wrp-updt{
    margin-left: 55px ;
    width: 135px !important;
  }
  .oa-int-wrp-updt + p#enterAmtInput{
    margin-left:55px !important
  }

  .oa-int-wrp-updt > div {
    margin-left: 2rem;
  }

  .paymentDetails .accountIcon {
    margin-left: 0 !important;
  }

  .scheduleRadiosUpdt .tds-form__input-combo .tds-form__input{
    width: 50% !important;
  }

  .scheduleRadiosUpdt {
    margin-left: 2.4rem;
  }

  p.tds-text__paragraph.update-payment-notice {
    margin-left: 30px !important;
  }
  
  #cancelpay > div:last-child {
    justify-content: center !important;
  }
}

@media (max-width: 429px) and (-webkit-min-device-pixel-ratio: 2) {
  .choosePayMethod.mt-5 {
    margin-top: 1rem !important;
  }

 .cancelRecSec > p {
    margin-left: 2rem !important;
    width: 85%;
  }

  .recSchPayRadios > form > div > fieldset, .scheduleRadios > form > div > fieldset {
    margin-left: 2.5rem !important;
  }

  .walletAccountRec {
    margin-left: .5rem;
  }

  .accountIcon {
    align-self: center;
    margin-bottom: .5rem;
  }
}

@media (max-width: 400px) {
  .accountIcon {
    margin-bottom: .75rem;
  }
}