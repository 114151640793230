.tds-accordion-content__container {
    width: 100%;
    max-width: 100%;
    margin: 0;
}

button.tds-link.tds-link--medium, button.tds-link.tds-link--medium {
    background: none;
    font-family: Arial, sans-serif !important;
    border:0px;
    padding:0px;
    cursor: pointer;
    text-transform: initial;
    text-align: left;
    
}
.payInfo button.tds-link.tds-link--medium{
    display: flex;
}

button.tds-link.tds-link--medium.payDueAmt, button.tds-link.tds-link--medium.payDueAmt {
    background: none;
    border:0px;
    padding:0px;
    cursor: pointer;
    display:flex;
    padding-bottom: 3px;
}



.paymentMethodHeader p:first-child {
    padding-bottom: 3px;
}

.paymentAmount .tds-modal .tds-modal__footer button {
    display: flex;
    justify-content: center !important;
}

.planDetails {
    display: flex;
    margin: 0 1.4rem;
    background-color: rgb(255, 255, 255);
}

.makepaymentBtn a {
    text-decoration: none;
    text-transform: initial;
}
button.ctaEditLink span.tds-link__icon-wrapper{
    margin-left: -10px !important;
    margin-right: 0px !important;
}
.ctaEditLink span {
    margin-left: 10px;
    vertical-align: middle;
    position: relative;
}
.cancelCtaEditLink span {
    margin-right: -20px;
    margin-left: 10px;
    vertical-align: middle;
    position: relative;
}
.recurringH3 .ctaEditLink span, .recurringPaymentText.recurringH3 .ctaEditLink span {
    top: -1px;
}
.recurringPaymentText .ctaEditLink span {
    top: 0px;
}
.setupCmsLinks{
    display: flex;
    margin-left:65px;
    margin-top:0px;
    padding:0px;
}
.setupCmsLinks li{
    display:inline-flex;
}
.setupCmsLinks li:first-child{
    display:inline-flex;
}
.setupCmsLinks span.setup_SSARRB_links{
    padding-left:60px;
}
.ctaEditLink .edit_txt_lbl{
    font-weight: 700 !important;
    margin-left: 0px !important;
}
.setup_SSARRB_links .edit_txt_lbl{
    margin-left:65px;
}
.setup_SSARRB_links .cancel_txt_lbl{
    margin-left:10px;
}
.recDiscountFlag span.restEasy_txt {
    color: #001F61;
    font-size: 16px;
    font-weight: bold;
    margin-left: 19px;
    margin-right: 5px;
}
.tds-text__paragraph.recDiscountFlag{
    padding:0px 10px !important;
    margin-bottom: 0.1rem !important;
}
.wallet_ship_txt{
    color: rgb(51, 51, 51);
    display:block;
    margin-top:25px;
    font-size: 14px;
    font-style: italic;
}
.wallet_fed_txt {
    width: 53%;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 30px;
    color: rgb(51, 51, 51);
    font-size: 14px;
    font-style: italic;
}
.ctaManageWalet {
    display: inline-block;
}
.wallet_fed_txt span {
    width: 25px;
    float: left;
    height: 38px;
}
.paymentMethod .paymentInfo, .paymentMethod .recurringButton{
    padding-left: 30px;
    margin-top: 29px;
}
.paymentMethod {
    padding: 0px !important;
}
.reqSSA img, .reqSSACancelLink img {
    top: -1px;
    position: relative;
}
.paymentMethod .recurringH3, .paymentMethod .recurringPaymentText{
    padding-left: 30px;
    padding-top: 25px;
    padding-right: 10px;
    position: relative;
    color: #5a5a5a !important;
}
.paymentMethod .recurringPaymentText{
    display: flex;
    flex-wrap: wrap;
}
.reqEditSSA span.setup_SSARRB_links {
    margin-left: auto;
    margin-right: 20px;
    font-style: normal !important;
}
.recurringH3.reqSSA .setup_SSARRB_links, .recurringH3.reqSSA .ctaEditLink{
    position:absolute;
    right:35px
}   
.paymentMethodGrid .recurringPaymentText > span {
    color: #5a5a5a;
}
.recurringH3.reqSSACancelLink button[aria-describedby="rec_sch_id"]{
    position: absolute;
    right: 35px;
}
.recurringH3.reqSSACancelLink button[aria-describedby="rec_sch_id"]{
    position: absolute;
    right: 35px;
}
.reqSSABtn{
    display: inline-flex;
    position: absolute;
    right: 35px;
}
.paymentDueText{
    font-family: Arial,sans-serif !important;
    font-size: 18px !important;
    font-weight: 600;
    color: #002677 !important;
    padding:3px 0px; 
}

.tds-popup-tip svg {
    width: 16px;
    height: 16px;
}
.tds-popup-tip {
    top: -4px;
    vertical-align: middle;
}
.paymentAmount .tds-popup-tip {
    top: -2px !important;
}
.monthlyPremium .tds-popup-tip {
    top: -4px !important;
}

.payment {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    padding-top: 1rem;
    width: 36%;
    background: rgb(251, 252, 254);
    padding: 25px 30px 30px 30px;
    border: 1px solid rgb(222, 222, 222);
    border-radius: 6px 0px 0px 6px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
}

.paymentgovt {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    padding-top: 1rem;
    width: 35%;
    background: rgb(251, 252, 254);
    padding: 25px 30px 30px 30px;
    border: 1px solid rgb(222, 222, 222);
    border-radius: 6px 0px 0px 6px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
}

.paymentAmount {
    display: flex;
    flex-direction: column;
    width: 40%;
}

/* .paymentAmount button {
    display: flex;
   
} */
.paymentAmount .tds-popup-tip .tds-popup-tip__content{
    padding: 10px 0px;
}



.tds-popup-tip__button:focus, .tds-popup-tip__button:focus:before, .tds-popup-tip__close:focus, .tds-popup-tip__close:focus:before {
    border: 0px solid #196ecf !important;
    padding: 3px 0 0 !important;
}

.paymentAmount .tds-popup-tip svg {
    width: 16px !important;
    height: 16px !important;
}

.tds-popup-tip__button:focus, .tds-popup-tip__close:focus{
    outline: none;
    cursor: pointer;
    background: none;
    box-sizing: border-box;
    line-height: 24px;
    border-radius: 50%;
    overflow: hidden;
    padding: 2px 0 0;
    border: 1px solid transparent;
}

.paymentMethodRecurring .paymentMethodGrid .paymentInfo.mt-4 {
    width: 90%;
    margin-left: 30px;
    padding-left:0px;
    margin-top: 29px !important;
}

.paymentMethodRecurring .paymentMethodGrid .paymentInfo.marginTop {
    width: 90%;
    margin-left: 30px;
    margin-top: 80px !important;
}

.paymentMethodRecurring .paymentMethodGrid p.recurringPaymentText{
    margin-top: 30px !important;
    margin-left: 30px;
    margin-right: 10px;
}

.paymentMethodRecurring .paymentMethodGrid h3.recurringPaymentText, .paymentMethodRecurring .paymentMethodGrid .recurringH3{
    margin-top: 30px !important;
    margin-left: 30px;
    margin-right: 10px;
}

.makepaymentBtn.btnWrap small span{
    font-size: 14px !important
}

.twoBtn button + span{
    margin-left: 20px;
    margin-top: 10px;
   
}

.twoBtn button svg{
    margin-left:15px;
}
.cms_Gap_recurring{
    margin-top: 5px;
    padding-left: 19px;
}
.shipPremBrkDwn{
    margin-left:10px !important;
}
.premBrkDwnBlueBg{
    background-color: #E5F8FB;
    padding:10px;
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right:20px;
}
.shipPremBrkDwn + div h3{
    color: #002677 !important;
    font-size:17px !important;
    font-weight: bold !important;
    margin-top:10px ;
    margin-bottom:5px !important;
}
.shipPremBrkDwn + div table{
    width: 100%;
}
.brkdwnheading{
    margin-top: 30px !important;
    text-transform: capitalize;
}
.shipPremBrkDwn + div table thead th{ 
    text-align: left !important;
    padding-top:7px !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    width:80%;
    padding-bottom:7px !important;
    border-bottom: 1px solid #000 !important
}
.shipPremBrkDwn + div table tbody td{ 
    text-align: left !important;
    padding-top:7px !important;
    padding-bottom:7px !important;
    font-weight: 700;
    color: #5A5A5A;
    font-size: 15px;
    border-bottom: 1px solid #DADBDB !important
}
.brkdwnsubtext{
    font-weight:normal !important;
    color: #000 !important
}
.householddata{
    margin-bottom:25px !important;
}
table.householddata tbody tr td:last-child{
    text-align: right !important;
    padding-right: 20px !important;
}
table.householddata thead tr th:last-child{
    padding-left: 10px !important;
    text-align: right !important;
    padding-right: 20px !important;
}
.householdtotaltext{
    font-size:15px;
    font-weight: bold;
    color:#393939;
    padding-left:15px;
}
.householdtotalamt{
    font-size:19px;
    font-weight: bold;
    color: #002677;
    display:block;
    text-align: right;
}
.premBrkDwnAmt{
    font-weight:bold !important;
    color: #002677 !important;
    font-size:16px !important;
}
.brkdwnsubtext span{
    padding-left: 20px;
    display: block;
}
@media (max-width: 767px){
    .brkdwnsubtext{
        font-weight:normal !important;
        color: #000 !important
    }
    .brkdwnsubtext span{
        padding-left: 10px;
        display: block;
    }
    .premBrkDwnBlueBg{
        padding: 20px 20px 20px 0px !important;
    }
    .premBrkDwnBlueBg .householdtotaltext{
        width: 50%;
    }
    .payInfo.ship .monthlyPremium > button{
        flex: 1 1 100%;
        text-align: right;
        margin-top:10px;
    }
    .payInfo.ship button.tds-link.tds-link--medium{
        display:block;
    }
    table.householddata thead{
        display:none;
    }
    table.householddata tbody tr td:first-child{
        width:60%;
        padding: 15px 10px !important;
    }
    table.householddata tbody tr:first-child td{
        border-top: 1px solid #DADBDB !important;
    }
    table.householddata tbody tr:last-child td{
        border-bottom: 0px solid #DADBDB !important;
    }
    table.householddata tbody tr td:last-child{
        text-align: right !important;
        padding-right: 20px !important;
    }
    .premBrkDwnBlueBg {
        margin-top:0px !important;
    }
    .householddata{
        margin-bottom:0px!important;
    }
    .twoBtn button + span{
        margin-left: 0px;
        margin-top: 10px;
    }
    .payInfo.ship {
        width: 100% !important;
    }
    .monthlyPremium{
        display: flex;
        flex-wrap:wrap
    }
    .cms_Gap_recurring{
        margin-top: 5px;
        padding-left: 0px ;
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .monthlyPremium{
        display: flex;
        flex-wrap:wrap
    }
}
.paymentMethodRecurring button.tds-button.tds-button--secondary-one{
    margin-left: 30px;
}

.paymentInfo > .paymentAmount > button > svg {
    margin-left: 5px;
    margin-top: 2px;
}

.ml-auto.hmfm-id span{
    font-size: 13px !important;
}

.paymentAmount > .tds-header__h3, .upcomingPayment > .tds-header__h3 {
    font-family: Arial,sans-serif !important;
    font-size: 18px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.3;
    color: #002677;
}


.amountPastDueDate {
    display: flex;
    flex-direction: column;
    width: 25%;
}

.amountPastDueDate > p:last-child {
    font-family: Arial,sans-serif !important;
    font-size: 18px !important;
    font-weight: 600;
    color: rgb(0, 38, 119) !important;
    padding:3px 0px; 
}

.payment > p {
    font-weight: bold;
    font-size:20px;
}

.paymentgovt > p {
    font-weight: bold;
    font-size:20px;
}

.paymentInfo {
    display: flex;
    width: 100%;
    margin: 1rem 0 0rem 0;
}
.paymentInfo.pdue.mt-4, .paymentInfo.mt-4.recWrap, .paymentInfo.isPreEff{
    flex-wrap: wrap;
}
ul.paymentInfo.pdue.mt-4.margBtm {
    margin-bottom: 75px !important;
}
.paymentInfo.mt-4.recWrap{
    width: 90%;
}
.recurringButton.recBtnWrap{
    display:initial;
    width: 100%;
    padding:0px;
}
.cmsCancelPaymentLink svg {
    margin-left: 0px;
}
.tds-text__paragraph.recurringPaymentText img{
    padding-top:3px 
}
.recurringPaymentText .cmsCancelPaymentLink{
    padding-top:4px;
}
.recurringH3 img{
    position: relative;
    top: 3px;
}
@media (max-width: 767px){
    .paymentInfo.pdue.mt-4, .paymentInfo.mt-4.recWrap{
        flex-wrap: wrap-reverse;
    }
    .paymentInfo.marginTop{
        flex-wrap: wrap-reverse !important;
    }
    .paymentMethodHeader.itemOrder{
        order:2 !important
    }
    .setupCmsLinks span.setup_SSARRB_links{
        padding-left:0px;
    }
    .setupCmsLinks{
        display: block;
    }
}

.paymentInfo.marginTop{
   /* margin-top: 75px !important;*/
    flex-wrap: wrap;
}

.paymentInfo + div button, .paymentInfo + button{
    text-transform: initial !important;
}

.makepaymentBtn{
    display: flex;
    width: 100%;
}

.makepaymentBtn.btnWrap{
    margin-top:25px
}

.makepaymentBtn a:hover{
    text-decoration: underline;;
}

.makepaymentBtn small p {
    font-size: 13px !important;
}

.makepaymentBtn > a{
    text-transform: initial;
    width: 90%;
    margin-right: 1rem;
}

.makepaymentBtn > button{
    text-transform: initial;
    width: auto!important;
    margin-right: 1rem;
    height: fit-content;
}

.paymentMethod {
    display: flex;
    width: 73%;
    padding:35px 30px 20px 30px;
    border: 1px solid rgb(222, 222, 222);
    border-radius: 0px 6px 0px 0px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
}

.paymentMethodRecurring{
    display: flex;
    width: 64%;
    padding:0px 0px 20px 0px;
    border: 1px solid rgb(222, 222, 222);
    border-radius: 0px 6px 0px 0px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
}

.payInfo p.tds-typography__paragraph div div p a {
    text-transform: capitalize !important;
}

.recDiscountFlag{
    background: rgb(244, 244, 244);
    border-radius: 0px 6px 0px 0px;
    padding: 11px 10px;
    color: rgb(0, 38, 119);
    display:flex;
    margin-bottom:29px;
    align-items: center;
}

.recDiscountFlag > h4 {
    font-family: Arial,sans-serif !important;
    font-size: 1rem;
    margin-bottom: 0;
    margin-right: 0.25rem;
    margin-left: 5px;
}

.recDiscountFlag button{
    font-weight: normal;
}

.recDiscountFlag p{
    font-size:1rem;
    margin-right: .25rem;
}

.recDiscountFlag img {
    width: 35px;
    height: 26px;
}

.recDiscountFlag span {
    padding: 4px 0px 0px 0px;
}

.recDiscountFlag > div {
    padding-left:1.125rem;
}

.recurringPaymentStartFlag {
color: rgb(90, 90, 90);
font-size: 16px;
font-weight: bold;
font-style: italic;
}

.recurringPaymentStartFlagImg{
    width: 13.5px;
    height: 15px;
}
.paymentHeader > p {
    font-size: 20px;
    font-weight:bold;
    margin-bottom: 5px !important;
}

.paymentMethodGrid {
    width: 100%;
}

.payInfo .tds-modal h3{
    display:flex;
}
.payInfo .tds-modal h3 > img{
    width:24px;
    height:20px;
}
.payInfo .tds-modal h3 > span{
    padding-left:10px;
}

.payInfo .tds-modal h3 > span > sup{
    font-size:11px;
    font-weight:normal;
}

.payInfo .tds-modal.right a > img, .payInfo .tds-modal.right a > img {
    padding-left: 5px;
}

.paymentMethodInfo {
    display: grid;
    grid-template-rows: 45% 2rem;
    grid-template-columns: 33% 9% 35% 5% 15%;
}

.paymentMethodHeader {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    width: 36%;
}



.paymentMethodHeader-exp {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    width: 55%;
}

.paymentMethodHeaderLarge {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    width: 43%;
}

.tds-popup-tip__container[aria-hidden=true]{
    pointer-events: none;
    left: -99999px;
    opacity: 0;
    display:none
}

.upcomingPayment {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 33%;
}



.upcomingPayment .tds-popup-tip {
    display: inline-block;
    position: relative;
    bottom:3px;
}
.paymentDueText .uhc-question-icon {
    display: inline-flex;
    margin-left: 10px;
}
.paymentDueText .uhc-question-icon.iscms {
    display: inline-flex;
    margin-left: 0px;
}
.paymentDueText .tds-popup-tip{
    top: 0px;
}
.upcomingPayment .tds-popup-tip__container[aria-hidden=false] {
    z-index: 9999;
}

.dueDate {
    display: flex;
    flex-direction: column;
    width: 15%;
    padding-left: 0px !important;
}

.dueDate  > p:last-child{
    font-family: Arial,sans-serif !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: rgb(0, 38, 119) !important;
    padding:3px 0px; 

}
.dueDateIsb {
    display: flex;
    flex-direction: column;
    width: 19%;
    padding-left: 0px !important;
}

.dueDateIsb  > p:last-child{
    font-family: Arial,sans-serif !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: rgb(0, 38, 119) !important;
    padding:3px 0px; 

}

.paymentHeader > p > span {
    color: green;
    font-weight: bold;
    font-size: 20px;
}

.paymentGrid {
    display: flex;
    width: 100%;
}

.paymentHeader {
    display: flex;
    width: 100%;
}

.payment > p {
    font-weight: bold;
    font-size: 20px;
}

.paymentgovt > p {
    font-weight: bold;
    font-size: 20px;
}

.payment > h3{
    font-weight: bold;
    font-size: 20px !important; 
    font-family: Arial,sans-serif !important;
    color: rgb(51, 51, 51) !important;
}

.isbBanner h3{
    font-weight: bold !important;
    font-size: 24.63px !important; 
    font-family: Arial, sans-serif !important;
    color: #002677;
    margin-bottom: 5px;
}

.paymentgovt > h3{
    font-weight: bold;
    font-size: 19px !important; 
    font-family: Arial,sans-serif !important;
    color: rgb(51, 51, 51) !important;
}

.curPayVerticalLine, .dueVerticalLine, .pastDueVerticalLine {
    display: flex;
    width: 2px;
    background-color: rgb(216, 216, 216);
    margin-right: 1rem;
    margin-left: 1rem;
}

.methodType {
    display: flex;
    align-items: center;
}

.planContainer .title-header {
    display: flex;
    flex-wrap: wrap;
    margin: 0 1.4rem;
}

.planName > p {
    margin-left: 0rem !important;
    font-weight: bold;
    font-size: .8rem;
}

.tds-card.tds-card--primary > .tds-card__content{
    padding: 20px 0px !important;
}

.planName {
    justify-items: left;
    margin-top: 1.3rem;
    width: 75%;
}

.planName.ship {
    width: 63%;
}

.payInfo {
    display: flex;
    justify-content: flex-end;
    align-content: flex-start;
    width: 25%;
    margin-top: 2.3rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
}

.payInfo.ship {
    width: 37%;
}

.shipBreakdownModal {
    margin-left: 1rem;
}

.pastDue {
    grid-row: 1/3;
    align-items: center;
    background-color: rgb(254, 249, 234);
    height: 4.5rem;
    width: 100%;
}

.shipDiscountModalContent {
    color: black;
}

.shipDiscountModalContent > button:last-of-type {
    margin: 0 !important;
}

.shipDiscountModalContent > p{
    margin: 1rem 0 2rem 0 !important;
}

.shipDiscountModalHeader > h2 {
    font-size: 28.83px;
    width: 32.94rem;
}

.paymentHistoryJump {
    font-size: 13px !important;
    cursor: pointer;
}

.recDiscountFlag > div > button {
    font-weight: bold;
}

.pastNotice {
    display: none;
    align-items: center;
    justify-items: flex-start;
    grid-column: 1/2;
    grid-row: 2/3;
    width: 100%;
    background-color: rgb(254, 249, 234);
    height: 4.5rem;
}

.paidInFullBanner {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
}

.preEffectiveBanner{
    width: 100%;
    display: flex;
    margin-bottom: 10px;
}

.preEffectiveBanner p {
    max-width: 55% !important;
}

.overPaymentBanner {
    width: 100%;
    display: flex;
}

.pastDueBanner {
    padding:0px;
    width: 100%;
    display: flex;
}

.pastDueBanner p, .overPaymentBanner p, .paidInFullBanner p, .isbBanner p{
    max-width: 100% !important;
}

.pastDueBanner > div, .overPaymentBanner > div, .paidInFullBanner > div, .preEffectiveBanner > div, .isbBanner > div{
    flex-grow: 1;
}

.pastDueBanner > div  > p, .overPaymentBanner > div  > p, .paidInFullBanner > div  > p, .preEffectiveBanner > div  > p, .isbBanner > div  > p{
    padding:2px 8px;
   
}
@media (min-width: 770px){
    .recurringPaymentText img, .recurringPaymentText span{
        padding-right:4px;
        margin-top: 5px;
    }
    .recurringPaymentText img{
        margin:0px !important;
    }
    .setup_SSARRB_links {
        position: relative;
    }
    .recurringPaymentText .setup_SSARRB_links {
        position: relative;
        top:-2px; 
    }
}
@media (max-width: 768px){
    .reqSSABtn{
        display: block;
        position:initial;
    }
    .recurringPaymentText span{
        font-size: 16px !important;
        font-weight:normal
    }
    .recurringPaymentText img, .recurringPaymentText span{
        padding-right:3px;
        width: max-content;
        vertical-align: middle;
    }
    .recurringPaymentText .ctaEditLink span {
        top: 0px !important;
    }
}
.pastDueBanner > a{
    margin-right: 80px;
    text-transform: initial;
    text-decoration: none !important;
}

.pastDueBanner a:hover{
    text-decoration: underline;
}

.pastDueBanner > img, .overPaymentBanner  > img, .paidInFullBanner > img, .preEffectiveBanner > img {
    width:23px;
    height: 23px;
}

.pastDueBanner > button, .overPaymentBanner  > button, .paidInFullBanner  > button, .preEffectiveBanner  > button, .isbBanner  > button{
    background:none;
    border:0px;
    padding:0px;
    cursor: pointer;
}
.makePayment {
    display: none;
    justify-content:center;
    align-items: center;
    grid-row: 2/3;
    grid-column: 2/3;
    width: 100%;
    background-color: rgb(254, 249, 234);
}

.tds-link {
    line-height: 1.25rem;
}

.tds-popup-tip_button {
    background-color: rgb(0, 38, 119);
    border: 1px solid rgb(0, 38, 119);
}

.monthlyPremium span.tds-popup-tip__container {
    left: -186px !important;
    z-index: 99999 !important;
}

.monthlyPremium span.tds-popup-tip__container:after {
    left: calc(85% - 5px) !important;
    z-index: -99999!important;
}

.monthlyPremium .tds-popup-tip__container[aria-hidden=true] {
    pointer-events: none;
    left: -99999px;
    opacity: 0;
    display: none;
}

.monthlyPremium{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 10px;
}
.monthlyPremium > span{
    font-size:17px !important;
    padding-top: 3px;
}
.monthlyPremium > button{
    background: none;
    border:0px;
    margin:0px;
    padding: 0px;
    
}

.makepaymentBtn > small{
    font-style: normal;
    width: 55%;
}

.makepaymentBtn > small > span.ml-0.tds-popup-tip{
    
}

.makepaymentBtn > small > span.ml-0.tds-popup-tip h3{
    font-size: 19px !important;
    color: #002677;
    margin-bottom: 0px !important;
    line-height: 25px !important;
    font-family: Arial, sans-serif !important;
}

.makepaymentBtn > small > span.ml-0.tds-popup-tip p{
    margin-top: 10px !important;
    margin-bottom: 0px !important;
    font-size: 16px !important;
    font-weight: normal;
}

.uhc-question-icon{
    display: flex;
}

.uhc-question-icon > button{
    background: none;
    border:0px;
    margin:0px;
    padding: 0px;
    position: relative;
    top: -2px;
    left: -5px;
}
.monthlyPremAmt{
    font-family: "UHC Serif Semibold",Georgia Bold,serif;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    
}
.monthlyPremium .badge, .uhc-question-icon .badge{
    display: inline-block;
    min-width: 10px;
    height: 16px;
    padding: 1px 3.5px;
    font-size: 12px;
    font-weight: bold;
    color: #196ecf;
    line-height: 1;
    vertical-align: middle;
    white-space: nowrap;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #196ecf !important;
    margin-left: 5px;
    margin-top: 2px;
    cursor: pointer;
  }
  .mobile-only{
    display: none;
  }
  .desktop-only{
      display: flex;
  }

  @media (max-width: 768px){
    .preEffectiveBanner p {
        max-width: 100% !important;
    }
    .makepaymentBtn .paymentLabel small{
        display: flex !important;
        flex-wrap: wrap;
    }
    .makepaymentBtn a {
        text-decoration: none;
        text-transform: initial;
    }
    .planContainer .title-header {
        margin: 0;
        flex-direction: column-reverse;
        padding: 0 10px;
    }

    .planContainer {
        padding: .75rem;
    }

    .monthlyPremium .tds-popup-tip__container.tds-popup-tip__container-bottom.tds-margin-none.tds-typography__paragraph--light {
        left: -210px !important;
    }

    .monthlyPremium > p{
        width: 100%;
        display: contents;
    }

    .planName{
        width:100%;
        margin-top:0px;
        order: 2;
    }  

    .planName > p {
        width: 100%;
    }

    .pastDueBanner > button, .overPaymentBanner > button, .paidInFullBanner > button, .isbBanner > button {
        order:3;
        align-self: flex-start;
    }
    
    .payInfo{
        width: 100%;
        justify-content: flex-end;
        margin-bottom: 0rem;
        margin-top: 1rem;
        order: 3;
    }

    .paymentInfo {
        margin-top: 1rem !important;
        flex-wrap: wrap;
    }

    .paymentAmount {
        width: 40%;
        order:1;
    }
    
    .amountPastDueDate {
        width: 40%;
        order: 3;
    }

    .makepaymentBtn > button {
        margin-top: 2rem !important;
        margin: 0 auto;
        margin-bottom: 2rem !important;
        width: auto;
    }

    .recurringPaymentText {
        color: rgb(51, 51, 51) !important;
        font-size: 20px !important;
        font-weight: bold !important;
        height: 18.39px;
        line-height: 18.4px !important;
        display: inline-block !important;
    }

    .recurringButton {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        width: 100%;
        margin-top: 3.4rem;
        margin-bottom: 2.6rem;
    }

    .ctaRecurring {
        margin-left: 0 !important;
    }
    
    .upcomingPayment > div {
        margin-bottom: .3125rem;
    }
    
    .tds-popup-tip__container {
        left: -90px !important;
    }

    .paymentMethodRecurring {
        width: 100%;
        border-radius: 0 0 6px 6px;
    }

    .paymentMethodHeader {
        margin-top: 1rem;
        order: 30;
        width: 100%;
    }

    .upcomingPayment {
        width: 40%;
        order: 10;
        
    }

    .paymentMethodRecurring {
        flex-wrap: wrap;
    }
    .dueDate {
        width: 44%;
        order: 20;
    }
    
    .dueVerticalLine {
        order: 15;  
    }

    .curPayVerticalLine {
        display: none;  
    }

    .uhc-question-icon > span {
        width: unset;
    }

    .upcomingPayment .tds-popup-tip {
        padding: 2px;
    }

    .payment {
        padding-bottom: 0;
    }

    .paymentgovt {
        padding-bottom: 0;
    }

    .tds-card__content > p {
        color: rgb(90, 90, 90) !important;
        margin-top: .875rem !important;
    }
    h3.monthlyPremiumAmt{
        margin:0px !important;
        padding:0px
    }
    .title-header .planTypeNames{
        color: rgb(90, 90, 90) !important;
        margin-top: .875rem !important;
        font-size:1rem !important;
    }
    .title-header .planName{
        margin: 1.3rem 0; 
    }
    .paymentMethod {
        display: grid;
        width: 100%;
        border-radius: 0 0 6px 6px;
    }
    .payment {
        display: grid;
        width: 100%;
        border-radius: 6px 6px 0 0;
        padding: 25px;
    }
    .paymentgovt {
        display: grid;
        width: 100%;
        border-radius: 6px 6px 0 0;
        padding: 25px;
    }
    .planDetails {
        display: inline-block;
        margin: 0px;
        width: 100%;
    }

    .make-onetime-btn-cont {
        display: flex;
        justify-content: center;
        margin: 2rem 0;
    }

    .makepaymentBtn {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .paymentLabel {
        width: 100%;
        order:2;
    }

    .ctaMakePayment {
        display: flex;
        width: 100%;
        margin-top: 1.5rem;
        margin-bottom: 2rem;
        justify-content:center;
        order: 1;
    }

    .ctaManageWalet {
        width: 100%;
        display: flex!important;
        justify-content:center;
        order: 1;
        margin-left: -15px;
    }

    .tds-button {
        padding: 8px 18px !important;
    }

    .pastDueBanner{
        display: flex;
        flex-wrap: wrap;
        order: 1;
        background-color: #fef9ea;
        padding: 23px;
        margin-bottom: 10px;
    }

    .pastDueBanner > span, .pastDueBanner > div {
        width: 80%;
        flex-grow: 2;
    }

    .pastDueBanner > a {
        width: 100%;
        margin: 0 auto;
        margin-left: 24px;
        margin-right: 0px !important;
        margin-top: .8rem;
        order: 4;
    }

    .tabs {
        margin: 1.5rem 0 !important;
    }
    .tabs > button{
        margin: 0px 10px !important;
    }
    .billingHistoryHeader{
        margin-left:0px;
        margin-right: 1.7rem;
    }
    .historyData{
        margin-left: 0px;
    }
    .wallet_fed_txt {
        width: 100%;
        margin-left: 0px;
        margin-top: 20px;
    }
    
  }
  @media (min-width: 769px) and (max-width: 990px){
     .paymentMethod .recurringButton{
        padding-bottom: 20px;
    }
    
  }
  .setup_SSARRB_links .ctaEditLink.tds-link.tds-link--medium {
      margin-left:10px;
  }
  .setup_SSARRB_links .cancelCtaEditLink.tds-link.tds-link--medium {
    margin-left:0px;
}
  
  @media (max-width: 429px){
    /* .mobile-only{
        display: flex;
    }
    .desktop-only{
        display: none;
    } */

    .link-text {
        margin-left: 0px;
    }
    .cmsCancel-mobile .tds-button{
        width: 100% !important;
        margin-bottom: 20px !important;
    }
    .upcomingPayment {
        font-family: Arial, sans-serif;
        font-weight: 500;
        line-height: 17.5px;
    }
    .payment > h3 {
        height: 20px;
        line-height: 20px;
    }

    .paymentgovt > h3 {
        height: 20px;
        line-height: 20px;
    }

    .paymentMethodHeader {
        margin-top: 1.8rem;
        width: 100%;
    }

    .recurringButton {
        margin-top: 2.5rem;
        margin-bottom: 1.5rem;
    }

    .payInfo > div > div > div > div > div:nth-of-type(1) {
        padding: 24px 60px 1rem 24px !important;
    }
    .payInfo.ship > div > div > div > div > div:nth-of-type(1) {
        padding:0px;
    }

    .recDiscountFlag {
        flex-wrap: wrap;
        align-items: unset;
        padding: 25px 10px;
    }
    .recDiscountFlag > img {
        width: 45px;
        height: 36px;
    }

    .recDiscountFlag > h4 {
        width: 70%;
        margin-left: 1.5rem;
        margin-bottom: .5rem;
        font-size: 1rem !important;
    }

    .recDiscountFlag > p {
        margin-left: 4.3rem;
        margin-bottom: .5rem !important;
        text-align: center;
    }

    .recDiscountFlag > div{
        margin-left: 4.3rem;
        text-align: center;
        padding-left: 0 !important;
    }
    .recDiscountFlag span.restEasy_txt {
        width: 80% !important
    }

    .recDiscountFlag .ctaEditLink .edit_txt_lbl {
        margin-left: 53px !important;
    }
    .recurringButton .wallet_ship_txt{
        margin-left: 0px!important;
        margin-top: 0px;
    }
    .paymentDueText .uhc-question-icon {
        display: inline-flex;
        margin-left: 15px;
    }
  }