@media (max-width: 590px) {
  .frame-area {
    width: 100% !important;
    height: 795px !important;
  }
  .frame-area.ship {
    height: 690px !important;
  }
  .border-bottom-xs {
    border-bottom: 1px solid #ccc !important;
    margin-right: 10%;
  }
}
a.tds-breadcrumbs__link:hover {
  text-decoration: none;
}
h3#paymentAmtRadios {
  margin-bottom: 0px;
}

.paymentDetails .uhc-margin-left-20 {
  margin-left: 20px;
}
.paymentDetails .pb-4 {
  padding-bottom: 1.5rem !important;
}

.paymentBtnGroup {
  margin-left: 1rem;
}
.fedpaymentBtnGroup{
  margin-left:0px !important;
  margin-top: 50px !important;
}

.superUserError {
  margin-top: 1.5rem;
}

.superUserError.eft {
  margin-top: -0.5rem;
  width: 103%;
}

.superUserError > div > button {
  display: none;
}

.tds-typography__paragraph small div p {
  font-size: 14px !important;
}
.frame-area {
  width: 598px;
  height: 660px;
  background: rgb(255, 255, 255) !important;
  border: 1px solid rgb(0, 109, 214) !important;
  box-shadow: 0px 2px 16px 0px rgba(161, 199, 211, 0.15) !important;
  border-radius: 5px !important;
}
.frame-area.ship {
  height: 575px;
}

.frame-area .btn {
  width: 206px !important;
}

.paymentSumSort {
  display: flex;
  width: 100%;
}
.tds-typography__paragraph p,
.tds-text__paragraph {
  max-width: 100%;
}
.paymentDetailsIpad {
  order: 1;
}
#paymentsummaryID {
  background: #fff;
  border: 1px solid #d8d8d8;
  box-shadow: 0 2px 8px 0 rgb(161 199 211 / 15%);
  border-radius: 8px;
  margin: 0px auto;
  height: max-content;
  max-width: 100%;
  margin-top: 60px;
  order: 2;
}
.payment-summary-cont .tds-header__h3.token-font-family-heading-three.pad5 {
  font-size: 34px !important;
  font-family: Arial,sans-serif!important;
  padding:0px !important
}
.payment-summary-cont {
  padding: 0px 50px 50px 50px;
}

.error-header {
  font-weight: bold;
}

.error-body {
  font-weight: 500;
  font-size: 15px;
}

.error-body > div > p {
  font-size: 15px;
}

.twoLineTxtLabel {
  width: 90% !important;
  display: block;
  line-height: 23px;
}

.payment-details-content {
  padding: 1rem;
}

.payment-summary-t {
  border-bottom: 2px solid rgb(0, 38, 119);
}

.payment-summary-t .tds-header__h3 {
  font-size: 1.25rem !important;
}

.payment-details-container {
  padding: 0rem 1rem 1rem 1rem !important;
}

#otherAmount[disabled] {
  background: none !important;
}

#reviewsubmit .tds-button {
  text-transform: none !important;
}

#reviewsubmit .tds-link__icon {
  fill: #fff !important;
}

/* .tds-link{
  text-transform: capitalize;
} */

.headingh3 {
  color: rgb(51, 51, 51) !important;
}

.formDiv .row {
  margin: 0 !important;
}

.title-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.payment-warning {
  order: 3;
}

.title-div {
  display: flex;
  align-items: center;
  padding-left: 0px;
}

.uhc-cc-button {
  background: none;
  padding: 0px;
  border: 0px;
  display: flex;
  cursor: pointer;
  margin-top: 8px;
  font-size: 17px;
  font-weight: 600;
  color: rgb(25, 110, 207);
  font-family: Arial, sans-serif;
}
.uhc-icon-scale {
  transform: scale(1.6);
}

.choose-mop-error {
  margin-top: 1rem;
}

.payment-error-box {
  margin-top: 1.6rem;
}

.payment-error-box > button {
  display: none;
}

.alert-body > div > p > span {
  font-weight: bold;
}

.nachaErr {
  width: 45%;
}

.nachaErr > div > span > div > p {
  font-weight: bold;
}

.nachaErr > p:first-child {
  font-weight: bold;
}

.nachaErr > div > span > div > span > p:nth-of-type(2) {
  padding-top: 1rem;
}

.num-errors-found {
  margin-bottom: 2.4rem !important;
  font-weight: bold;
}

.error-label {
  margin-bottom: 0.8rem !important;
  font-size:14px !important
}

h5#add_cc_title {
  font-family: Arial, sans-serif !important;
  font-size: 15.3px !important;
}

.error-list {
  margin: 0;
  padding-left: 1rem;
  font-weight: 400;
}

.serverdate {
  font-weight: bold;
  margin: 25px 0px 40px !important;
}

.shipCCMsg {
  margin-top: 1.75rem !important;
  margin-left: 0px !important;
}

.shipCCMsg.lastUsedEft {
  margin: 0 5px !important;
  margin-top: 0px !important;
}

@media screen and (max-width: 500px) {
  .title-row {
    justify-content: space-between;
  }
  .title-div {
    display: flex;
    align-items: center;
    padding-left: 0px;
    order: 2;
    margin-left: 1rem;
  }
  .link-div {
    order: 1;
  }
}
/* IE10+ CSS styles go here */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) and (min-width: 768px) {
  .formDiv .row {
    margin: 0 !important;
  }
}
@media (min-width: 768px) {
  .tab-gap {
    margin-bottom: 18px !important;
  }
}

#paymentsummaryID {
  display: block;
}
@media (max-width: 1024px) {
  #paymentsummaryID {
    display: none;
  }
}

@media (max-width: 768px) {
  #paymentsummaryID {
    display: none;
  }

  .redirectMessage {
    width: 80% !important;
    padding-left: 5% !important;
  }

  .logoutMessage {
    width: 100% !important;
  }
  .recentSaved .tds-form__radio-button {
    max-width: 100% !important;
  }
  .tds-date-picker__month-slider-inner {
    position: relative;
    width: 840px !important;
    display: flex;
  }
  .tds-date-picker__month > table td, .tds-date-picker__month > table th {
    word-break: initial !important;
  }
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.RadioDiv span {
  display: block !important;
  float: left !important;
  margin-right: 3px !important;
}
.RadioDiv p {
  display: block !important;
}

.payment-detail-radio-div span {
  display: block !important;
  float: left !important;
  margin-right: 3px !important;
}
.payment-detail-radio-div img {
  padding-left: 5px;
}
.Radiopayment-detail-radio-div p {
  display: inline-block !important;
}

.tds-modal .tds-typography__paragraph {
  font-size: 16px;
  line-height: 1.4;
}

.tds-modal .tds-typography__paragraph h3 {
  font-size: 17px !important;
  color: #002677;
  margin-bottom: 0px !important;
  line-height: 25px !important;
  font-family: Arial, sans-serif !important;
}

.routing-help-icon {
  margin-left: 40px !important;
  display: inline-block;
}
.acc-help-icon {
  margin-left: 30px !important;
  display: inline-block;
}
.uhc-content_height {
  max-height: 400px;
}
.fieldTooltip {
  display: inline-flex;
  border-width: 0 !important;
  background-color: #fcfcfc !important;
  cursor: pointer;
  color: #196ecf;
  font-size: 16px;
}

.redirectMessage {
  height: 25px;
  width: 601px;
}

.logoutMessage {
  color: rgb(51, 51, 51);
  font-family: UHC2020Sans-Medium;
  font-size: 16px;
  font-weight: 500;
  height: 22.4px;
  line-height: 22.4px;
  width: 570px;
}

.lockSvg {
  margin-top: -2px;
  position: absolute;
  margin-left: -20px;
}

.early-warning-header-title {
  flex: 1 1 !important;
  padding-right: 15px !important;
  margin: 0px !important;
  line-height: 24px !important;
  color: red !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
}

.early-warning-payment-buttons {
  height: 60px !important;
  max-height: 60px !important;
}
.tds-header__h3,
.tds-header__h3--light {
  font-family: UHCSerifHeadline-Semibold !important;
  font-size: 1.802rem;
  font-weight: 700 !important;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.3;
  margin: 5px 0px;
}

.flex-margin-bottom {
  margin-bottom: 0px !important;
}
.dueAmt {
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #002677 !important;
  line-height: 1.2 !important;
}
.dueDate {
  font-size: 14px;
  padding-left: 8px;
}
.enterAmt {
  margin-left: 0px !important;
  font-style: italic !important;
  font-size: 14px !important;
  padding-top: 5px;
}
.input-OtherAmt {
  display: flex !important;
  /* border: 1px solid #000!important; */
  border-radius: 3px !important;
  margin-top: 3px;
}

.input-OtherAmt .form-control.ibc {
  border: 1px solid #333333;
  border-radius: 4px !important;
  width: 100% !important;
  min-height: 24px !important;
}
fieldset.eft-PayMethod {
  padding: 0px;
  margin: 0px;
}
.m-agreebox {
  padding: 33px 30px 40px 20px !important;
  border-top: 1px solid #ddd;
}
.eft-form-padding {
  padding: 0px 15px;
  padding-bottom: 0px;
}

.sideH3 {
  font-family: Arial, sans-serif !important;
  font-size: 16px !important;
}
.rightLineHgt {
  line-height: 2.3;
}
#duetodaydate, #incomingduedate, #upcomingchargesduedate{
  font-size: 14px !important;
}
h3.tds-header__h3.payment-summary-title-txt {
  font-family: Arial, sans-serif !important;
  font-size: 13px !important;
  margin: 5px 0px;
}
.tds-notification.tds-notification--with-dismiss.tds-notification--error .tds-notification__body {
  font-family: Arial, sans-serif !important;
}
.totalLabel {
  font-size: 19px !important;
  font-family: Arial, sans-serif !important;
  font-weight: bold;
  padding-top: 18px !important;
  /* margin-top: 10px !important; */
  display: block;
  color: rgb(0, 38, 119) !important;
}
.labelPaddingTop {
  margin-top: 3px !important;
  font-weight: bold;
}
.paymentDetails {
  display: block;
}
.paymentDetails fieldset {
  padding: 0px;
  margin: 0px;
}

.manage-buttons {
  margin-top: 2rem;
}

.pay-amt-sec-pending-note {
  padding: 0 .5rem !important;
  margin: 0 !important;
}

.topheaderpadding.choosepay {
  padding: 20px 0px 20px 0px !important;
}
.topheaderpadding {
  padding: 20px 0px 0px 0px;
}
.uhc-padding-top-bottom.uhc-flex {
  padding: 20px 0px 20px 0px;
}
.uhc-padding-top-bottom {
  padding: 20px 0px 0px 0px;
}
.uhc-flex {
  display: flex !important;
}
.payAmtExp {
  padding-top: 9px;
}
.payMethodHeading {
  clear: both;
  padding-top: 40px;
}

@media (min-width: 992px) {
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.8rem !important;
  }
  .tds-form__checkbox-svg,
  .tds-form__radio-button-svg {
    width: 1.9em !important;
    height: 2em !important;
    vertical-align: middle;
    margin-bottom: -1px !important;
    color: #6f6f6f !important;
  }
  .tds-form__checkbox-label-text,
  .tds-form__radio-button-label-text {
    font-family: Arial, sans-serif;
    margin-left: 5px !important;
    font-weight: normal;
    position: relative;
    top: 1px;
    color: rgb(45, 45, 57) !important;
  }
  .tds-form__checkbox,
  .tds-form__radio-button {
    position: relative;
    font-size: 1rem;
    margin: 0 6px 5px 0 !important;
    height: auto !important;
  }
  label.tds-form__radio-button {
    width: max-content;
    margin: 0px !important;
  }
}

.paymentMethods.recentSaved > .walletPaymentMethod:last-child {
  border-bottom: 2px solid rgb(218, 219, 220);
  margin-bottom: .9rem;
}
.noSavedPayments {
  border-top: 1px solid rgb(216, 216, 216);
  border-bottom: 1px solid rgb(216, 216, 216);
  padding: 35px 0px;
}
.noSavedPayments p{
  color: rgb(90, 90, 90) !important;
  font-size: 16px;
  font-weight: 500;
}
.paymentMethods.moreMethods > .walletPaymentMethod:last-child {
  border-bottom: 2px solid rgb(218, 219, 220);
}

.color-black {
  color: #333 !important;
  margin-bottom: 4px !important;
}
.helpText {
  padding-right: 5px;
  color: rgb(25, 110, 207);
  font-size: 14px !important;
}

button.uhc-question-icon {
  background: none;
  border: 0px;
  padding: 0px;
  cursor: pointer;
  color: #196ecf;
  display: flex;
  font-weight: 600;
  font-size: 16px;
}
button.uhc-question-icon img {
  margin-left: 5px;
  width: 18px;
  height: 18px;
}
.eftTextLink {
  text-decoration: underline;
}
.tds-modal.right,
.tds-modal.right {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  border-radius: 0px;
  right: 0;
  animation: slidein 0.3s ease-out;
}
.uhc-font-14 {
  font-size: 14px;
}
.uhc-font-16 {
  font-size: 16px;
}
@keyframes slidein {
  0% {
    opacity: 0;
    transform: translateX(120%);
  }
  20% {
    opacity: 1;
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.tds-modal.right,
.tds-modal.right {
  height: 100%;
  overflow-y: auto;
  text-align: left;
}

.tds-modal.right .modal-body,
.tds-modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Right*/
.tds-modal.right.fade,
.tds-modal.right.fade {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.tds-modal .right.fade.in,
.tds-modal .right.fade.in {
  right: 0;
}
.tds-modal__outer,
.tds-modal__outer {
  padding-top: 0px !important;
}
.payMethodSubHeading h4 {
  background: rgb(251, 251, 251);
  color: rgb(0, 38, 119);
  font-size: 14px !important;
  font-family: Arial, sans-serif !important;
  padding: 10px;
  margin-top: 10px;
}

.payment-summary-cont ul {
  margin: 0px;
  padding: 0px;
}
.payment-summary-cont ul li {
  display: block;
  padding-left: 0px;
  font-size: 16px;
  margin-bottom: 0px !important;
}
.tds-modal__content .tds-typography__paragraph .uhc-content_height h4{
  margin-bottom:5px;
}
.tds-typography__paragraph p:not(:first-child) {
  padding-top: 15px;
}
.payExpContent div:not(:first-child) {
  padding-top: 40px;
}
.tds-form__form-group.tds-form__form-group--radio-vertical {
  padding-left: 0px !important;
  margin-left: 0px !important;
}
.payMethodSubHeading {
  padding: 0px !important;
}
.enroll_Banner {
  background: rgb(251, 251, 251);
  padding: 12px 12px 12px 45px;
  margin: 15px 0px !important;
  display: flex;
}
.enroll_Banner span {
  color: rgb(0, 112, 0);
  font-size: 21px;
  font-weight: 600;
}
.enroll_Banner span img {
  width: 35px;
}
.myoverlay {
  position: fixed !important;
  z-index: 999 !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: rgba(0, 0, 0, 0.65) !important;
}
.modal-content-container p div p:not(:first-child) {
  margin-top: 20px !important;
}
.ReactModal__Content.ReactModal__Content--after-open.mymodal.mymodal-min.early-warning-zero {
  padding: 0px !important;
}
.modal-footer-container {
  box-sizing: border-box;
  padding: 12px 20px 20px 20px;
  display: flex;
  margin: auto auto 0;
  flex: 1 1;
  justify-content: flex-start;
}
.review-requestq br {
  display: none;
}
.tds-card__content ul li {
  padding-bottom: 20px;
  line-height: 25px;
}
.tds-card__content ul li:last-child {
  padding-bottom: 0px;
}
.tds-modal__footer .uhc-button-center {
  display: flex;
  justify-content: center;
}
.uhc-button-center.mt-4 {
  margin-top: 4rem !important;
}
.uhc-button-center.mt-3 {
  margin-top: 2rem !important;
  display: flex;
  justify-content: left;
}
.uhc-lineheight-22 {
  line-height: 22px;
}
.tds-typography__paragraph h3 {
  font-size: 19px !important;
  color: #002677;
  margin-bottom: 0px !important;
  line-height: 25px !important;
  font-family: Arial, sans-serif !important;
}
.paymentDetails .uhc-medium-bold {
  font-size: 17px !important;
  font-weight: bold;
  font-family: Arial, sans-serif !important;
  color: #2d2d39 !important;
}
.eft-label-desc {
  font-family: Arial,sans-serif!important;
  font-size: 13.22px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.3;
}
.tds-modal__content .tds-text__paragraph {
  font-family: Arial, sans-serif;
  font-size: 1rem;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 1.4;
  color: #5a5a5a;
}
button.shipPremBrkDwn + div .tds-modal__content{
  max-width: 100% !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.payExpContent h3:first-child {
  margin-top: 0px !important;
}
.payExpContent h3 b {
  font-weight: bold !important;
}
.payExpContent p {
  font-size: 15px !important;
}
.mbt-med {
  margin-bottom: 30px !important;
}
.mbt-lg {
  margin-bottom: 45px !important;
}
.btm100 {
  margin-bottom: 100px !important;
}
.btm70 {
  margin-bottom: 70px !important;
}
.printcontent {
  font-size: 14px;
  color: rgb(90, 90, 90);
}
.uhc-bold-capitalize {
  font-weight: bold;
  text-transform: capitalize;
}
.recSchLabel {
  font-weight: bold;
  font-size:14.22px !important;
  margin-left:20px;
  margin-bottom: 0px !important;
}
@media (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {
  .recSchLabel {
    margin-left:30px;
    margin-bottom: 0px !important;
  }
}
.uhc-capitalize {
  text-transform: capitalize;
}
h2#modal-100 {
  font-size: 28.83px !important;
}
.uhc-label-help-text {
  display: flex;
  width: 48%;
}
.uhx-flex-grow {
  flex-grow: 1;
}
#ssb_link {
  border: 0px;
  background: none;
  color: #196ecf;
  font-weight: bold;
  padding: 0px !important;
  text-transform: lowercase;
  margin: 0px !important;
  width: auto !important;
}
#ssb_link:hover {
  text-decoration: underline;
}
#ssb_link:focus {
  box-shadow: 0 0 0 0px currentColor, 0 0 0 0px #fff, 0 0 0 5px #fff !important;
  border: 0px !important;
  border-radius: 0px;
}
@media (min-width: 768px) {
  .uhc-breadcrumb-mobile {
    display: none !important;
  }
  .col-md-4 {
    flex: 0 0 37.333333%;
    max-width: 33.333333%;
  }

  .uhc-lineheight-22 {
    display: flex !important;
  }
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 2px;
}
.uhc-margin-left-30 {
  margin-left: 30px;
}
@media (max-width: 767px) {
  .ssb-mobile button {
    width: 100% !important;
    margin-bottom: 20px;
    min-height: 55px;
  }
  div#cancelpay button:first-child {
    margin-bottom: 20px;
  }
  .mobile-container .row {
    margin: 0px;
  }
  .mobile-container .paymentDetailsIpad.col-md-7 {
    padding: 0px;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 5px;
  }
  .mobile-container .tds-form__form-group {
    padding: 0px;
  }
  .uhc-content_height {
    max-height: 270px;
  }
  .uhc-breadcrumb-desktop {
    display: none !important;
  }
  .uhc-breadcrumb-mobile {
    display: block !important;
  }
  .arrow_reverse {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: 'FlipH';
  }
  .tds-breadcrumbs__chevron {
    margin: 0px 5px 0px 0px !important;
  }
  .page-header {
    padding: 20px 0px !important;
  }
  .paymentDetailsIpad .uhc-flex {
    flex-direction: column-reverse;
    margin: 0 15px 0px 15px !important;
  }
  .paymentDetailsIpad.fedForm .uhc-flex {
    margin: 0px !important;
  }

  .paymentSchedule h3.tds-header__h3 {
    padding-top: 20px;
  }
  .paymentDetails .accountIcon {
    margin-top: -9px;
    margin-left: 1.8rem !important;
  }
  .choosePayMethod .walletAccount > div > div {
    display: inline !important;
    line-height: 2rem;
  }
  /* #reviewpage.container svg:not(:root) {
    margin-top: -30px;
    margin-left: -15px;
  } */
  button.uhc-question-icon {
    float: right;
    margin-bottom: 20px;
  }
  .mobile-container {
    padding: 0px !important;
  }
  .border-bottom-xs {
    border-bottom: 1px solid #ccc;
    margin-right: 0% !important;
    padding: 15px 25px !important;
  }
  .uhc-border-none {
    border: 0px !important;
  }
  fieldset .border-bottom-xs p {
    margin-left: 28px !important;
  }
  .tds-form__radio-button-svg {
    width: 1.3em !important;
    height: 1.3em !important;
    color: #6f6f6f;
  }
  .tds-form__checkbox-label-text,
  .tds-form__radio-button-label-text {
    font-family: Arial, sans-serif;
    margin-left: 8px;
    font-size: 16px !important;
    color: rgb(45, 45, 57) !important;
    position: relative;
    top: -4px;
    font-weight: 500;
  }
  .oa-int-wrp {
    width: 255px !important;
    margin-left: 28px !important;
  }
  .input-OtherAmt {
    width: 50%;
    /* border: 1px solid #333!important; */
  }
  .uhc-note-padding {
    margin: 0px !important;
    padding: 0px 20px;
  }
  .uhc-margin-left-20 {
    margin-left: 20px !important;
  }
  .uhc-margin-left-30 {
    margin-left: 50px;
  }
  .d-flex {
    flex-direction: column-reverse;
  }
  .hmfm-id {
    margin-bottom: 20px;
  }
  .form-control.ibc,
  .uhc-label-help-text {
    width: 100% !important;
  }
  #reviewsubmit button {
    width: 100%;
  }
  #cancelBtn {
    /* margin-top: 35px !important; */
    margin-left: 5px !important;
  }
  .m-agreebox {
    padding: 50px 15% !important;
  }
  .text-R {
    font-size: 14px !important;
  }
  .uhc-margin-left-right {
    margin: 0px 20px;
  }
  .manage-buttons button {
    width: 100% !important;
    margin: 0px 60px;
  }
  .manage-buttons button:first-child {
    margin-bottom: 30px;
  }
  .manage-buttons #cancelBtn {
    margin-left: 60px !important;
  }
  .paymentDetailsIpad {
    padding-right: 0px !important;
    margin: 0px !important;
  }
  .paymentDetailsIpad.fedForm{
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin: 0px !important;
  }
  .contact-us .container {
    padding-top: 0px !important;
  }
  .topheaderpadding {
    padding-left: 15px !important;
    border-top: 1px solid #ccc !important;
  }
  .uhc-item-right-align {
    display: none !important;
  }
  .uhc-plan-mobile-row {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .change-pay-mobile {
    display: flex !important;
    justify-content: flex-end;
    margin: 10px 0px;
  }
  .d-block {
    margin: 20px !important;
  }
  .page-header .container {
    padding-top: 54px;
    margin-top: -70px;
  }
  .col-md-6.pad0-mobile {
    padding: 0px !important;
  }
  .page-header .container div h1, .page-header .container div h2 {
    margin-left: 20px !important;
  }
  .printcontent .col-md-12 p {
    padding-left: 20px;
}
}

@media (max-width: 768px) {
  .input-OtherAmt .form-control.ibc {
    border: 0px solid #333333;
    border-radius: 4px !important;
    width: 100% !important;
    min-height: 24px !important;
  }
  button#submitBtn {
    margin-bottom: 30px;
  }
  .early-warning-header-title {
    padding-left: 10px;
  }

  .ReactModal__Content.ReactModal__Content--after-open.mymodal {
    width: 90% !important;
  }
  .mymodal {
    max-height: auto;
    width: 41% !important;
  }
  #earlyWarningBacktoOverviewBtn {
    height: auto;
  }
  .fieldTooltip .badge,
  .uhc-question-icon .badge {
    padding: 1px 4px !important;
  }
}

.amntSelectAlert {
  border: 2px red solid;
  margin: 0px 1px !important;
}

.amntSelectAlert::before {
  content: 'Select a payment amount';
  color: red;
}
.amntSelectAlert svg {
  color: red;
}

.tds-form__radio-button input[type='radio']:focus + svg,
.tds-form__checkbox input[type='checkbox']:focus + svg {
  outline: 5px solid #6C992B !important;
}
iframe .panel-body .accepted-cards {
  display: none !important;
}
#holderName {
  background-color: #196ecf !important;
}
.tds-modal__close,
.tds-modal__close {
  width: 44px;
  justify-content: center;
  height: 44px;
  text-indent: -99999px;
  cursor: pointer;
  outline: none;
  align-items: center;
  display: flex;
  top: 10px;
  overflow: hidden;
  background: none;
  position: absolute !important;
  right: 10px;
  box-sizing: border-box;
  border: 0;
  border-radius: 2px;
}
.border-bottom-xs .ml-4 p.invalid-feedback {
  margin-left: 5px !important;
}

.fieldTooltip .badge {
  display: inline-block;
  min-width: 10px;
  padding: 1px 3.5px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: #196ecf;
  border-radius: 10px;
}

#question-icon .pr-1 {
  font-family: Arial,sans-serif;
}

.uhc-question-icon .badge, #question-icon .badge {
  display: inline-block;
  min-width: 10px;
  padding: 1px 3.5px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: #196ecf;
  border-radius: 10px;
}
.l2MenuContainer {
  display: none;
}
@media only screen and (min-width: 500px) and (max-width: 1025px) {
  .needhelp.col-md-4.border-left {
    border-left: 0;
    padding: 0;
    width: 100% !important;
  }
  .manage-buttons button {
    width: auto !important;
    margin: 0px;
    margin-right: 15px !important;
  }
  .manage-buttons button.tds-modal__close,
  button.tds-modal__close {
    width: 44px !important;
    margin-right: 0px !important;
  }
}

@media print {
  .deskHeaderContainer {
    display: none;
  }
}
.row.mt-2.norefnum{
  padding-top: 10px;
  padding-bottom: 10px;
}
@media print{
  .row.mt-2.norefnum{
    display:none;
  }
  ul.row.mt-2.pt-2 {
    padding-top: 0px !important;
    margin-top: 0px !important;
  }
  :host( .iHdaTT ){
    display:none !important;
  }
  :host( .iLiJLH ) {
    display:none !important;
  }
  #global-header, #global-footer{
    display:none !important;
  }
  #payment-receipt-box-mt {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    border-radius: 2px !important;
    margin: 0px 25px !important;
  }
  a[href]:after {
    display: none !important;
    visibility: hidden;
    content: none !important;
  }
  header.navbar.navbar-static-top.navbar-inverse.deskHeader.ng-scope.l2BodyExpansionClass {
    display: none !important;
  }
  .uhc-print-link {
    display: none;
  }
  .uhc-margin-bottom-40,
  .uhc-tile-brd-wdgt.uhc-margin-bottom-40 {
    margin-bottom: 0px!important;
    padding-bottom: 0px!important;
    padding-top: 0px !important;
    font-family: UHC2020 Sans Semibold, Arial, sans-serif !important;
    border: none!important;
  }
  p#seeMoreWaysAtdd {
    margin-top: 25px;
  }
  #confirmationpage {
    display: block !important;
    margin-top: 0px !important;
  }
  body {
    margin-top: 0mm !important;
    margin-bottom: 0mm !important;
    margin-left: 0mm !important;
    margin-right: 0mm !important;
    background: #000;
  }
  .col-12.uhc-print-link {
    display: none;
  }
  .payment-summary-cont-confirm .uhc-receipt-label {
    margin-top: 0px !important;
    padding-top: 20px !important;
    padding-bottom: 0px !important;
    padding-left: 65px !important;
    border-top: 1px solid rgb(219, 219, 219);
    font-size: 12px !important;
  }
  br {
    display: none !important;
  }
  #payment-receipt-box-mt .payment-summary-cont-confirm {
    padding: 0px 0px 0px 0px !important;
  }
  #payment-receipt-box-mt {
    margin-top: 0px !important;
  }
  .payment-receipt-bottom-box-top-line {
    border: 0px !important;
  }
  #confirmationpage .pt-3,
  #confirmationpage .py-3 {
    padding-top: 0px !important;
  }
  #confirmationpage .tds-header__h1 {
    font-size: 1rem !important;
  }
  #confirmationpage .mb-2,
  #confirmationpage .my-2 {
    margin-bottom: 0rem !important;
  }
  #confirmationpage .print-col.mt-4 {
    margin-top: 0px !important;
  }
  #confirmationpage .payment-confirm-due-box-padding {
    padding: 0px !important;
  }
  p.tds-header__h3.token-font-family-heading-three.pad5 {
    padding-left: 76px;
    width: 100%;
  }
  .uhc-receipt--total-label ul li,
  .uhc-receipt-label ul li,
  ul.row.mt-4 li {
    font-size: 12px !important;
    line-height: 1 !important;
  }
  p.tds-typography__paragraph.pt-2.mw-100,
  p.tds-typography__paragraph p,
  p.tds-text__paragraph,
  .tds-typography__paragraph a,
  #confirmationpage a {
    font-size: 6.5px;
    margin: 0px 30px;
    color: #333 !important;
    font-family: Arial, sans-serif !important;
  }
  p.tds-typography__paragraph.pt-2.mw-100 br {
    display: block !important;
  }
  .paymentTotalAmt.pad5 {
    font-size: 16px !important;
    font-family: Arial, sans-serif !important;
    color: #002677 !important;
  }
  .m-0.pad5.totalLabel {
    padding-top: -5px !important;
    margin-top: -2px !important;
    font-size: 16px;
  }
  span.token-font-family-heading-three.totalLabel{
    margin-top: 0px !important;
    margin-left: 10px !important;
  }
  span.token-font-family-heading-three.mt-2.totalLabel{
    margin-top: -3px!important;
  }
  p.paymentTotalAmt.token-font-family-heading-three.pad5{
    font-size: 21px !important;
    margin-left: 0px !important;
  }

  .payment-summary-t {
    border: none !important;
  }
  .payment-summary-t .tds-header__h3 {
    color: rgb(51, 51, 51);
    font-size: 24px;
    font-weight: normal !important;
    padding-top: 5px !important;
  }
  .payment-confirm-esign-box {
    border: none !important;
    box-shadow: none !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .payment-confirm-esign-box p,
  p.tds-text__paragraph.uhc-capitalize {
    font-family: Arial, sans-serif !important;
    font-size: 9px !important;
  }
  .row.payment-summary-t {
    padding: 0px 65px 0px 65px !important;
    margin-top: 5px !important;
    margin-bottom: 7px !important ;
  }
  ul.row.mt-4 {
    margin-left: 55px !important;
  }
  ul.row.mt-4 .col-5 {
    flex: 0 0 41.666667%;
    max-width: 39.666667%;
  }
  .print_terms_check {
    display: block !important;
    font-size: 9px;
    padding-top: 5px;
  }
  .uhc-receipt-label p {
    padding-left: 0px !important;
    font-size: 12px !important;
  }
  p.tds-typography__paragraph.pt-2.mw-100,p.tds-typography__paragraph p,p.tds-text__paragraph,.tds-typography__paragraph a, #confirmationpage a {
    font-size: 6.5px !important;
    margin: 0px 30px;
    color: #333 !important;
    font-family: Arial, sans-serif !important;
  } 
  .payment-summary-cont-confirm .uhc-receipt-label .uhc-sans-medium {
    font-size: 12px !important;
    font-family: UHC2020 Sans Bold, Arial, sans-serif !important;
    color: #333!important;
    font-weight: 700;
  }
  div#confirmationpage {
    margin-left: 30px !important;
    margin-right: 30px !important;
    margin-bottom: 10px !important;
  }
  #confirmationpage ul li {
    margin-bottom: 0px !important;
  }
  a img {
    height: 0.5rem !important;
  }
  p.uhc-tempo-header__h3.token-font-family-heading-three.pad5 {
    padding-left: 76px;
    width: 100%;
  }
  .row.mt-4 .col-7 p {
    padding-left: 0px !important;
    text-align: left !important;
  }
  .LPMcontainer,
  .LPMoverlay,
  #needhelpsectioncontactus,
  .contact-us {
    display: none !important;
  }
  #artEXPOiFrame {
    display: none !important;
  }
  #payment-receipt-box .payment-summary-cont-confirm,
  .payment-summary-cont-confirm {
    padding: 0px !important;
    margin-bottom: 10px !important;
  }
 .uhc-receipt--total-label{
		margin-left: 65px;
    }
 .uhc-receipt--total-label li.col-5 {
    flex: 0 0 27.666667%;
    max-width: 27.666667%;
    margin-top: 10px;
	}
 .uhc-receipt--total-label li.col-7 {
		margin-left:0px !important;
}
 .payment-confirm-due-box-padding p, .payment-confirm-due-box-padding {
    display: none;
}
}

@media print {
  h2.uhc-tempo-header__h2.text-center div {
    max-width: 100% !important;
  }
  p.uhc-tempo-header__h3.token-font-family-heading-three.pad5 {
    padding-left: 0px !important;
  }

  ul.row.mt-4 {
    margin-left: 55px !important;
  }

  ul.row.mt-4 .col-5 {
    flex: 0 0 41.666667%;
    max-width: 39.666667%;
  }
  #payment-receipt-box .payment-summary-cont-confirm {
    padding: 0px !important;
    margin-bottom: 10px !important;
  }
 
  #confirmationpage .payment-summary-cont-confirm .uhc-receipt--total-label p.tds-header__h3.token-font-family-heading-three.pad5{
    font-size: 16px !important;
  }
}
@media print {
  .footerParsys,
  .back-to-top,
  .footerContainer {
    display: none;
    border-top: 0px !important;
  }
}

@media print{
  /* Ipad Air 2 fixes start */
  #payment-receipt-box, #payment-receipt-box-mt{
    width:100% !important;
  }
.col-md-5 {
    flex: 0 0 100% !important;
    max-width: 100%;
  }
.payment-summary-cont-confirm .uhc-receipt-label .row.mt-2 .col-7 {
    flex: 0 0 52% !important;
    max-width: 52% !important;
  } 
/* Ipad Air 2 fixes end */
  p.uhc-sans-medium.token-color-gray-dark-base.m-0.pad5.labelPaddingTop span {
        color: #333!important;
        font-family: Arial,sans-serif!important;
	}

  .container {
    	padding-top: 0px !important;
      margin:0px !important;
      padding:0px !important; 

    }
  h3.tds-header__h3.ssatextalgn{
    font-family: UHC2020 Sans Semibold, Arial, sans-serif !important;
    text-align: center;
    font-size: 1.1rem !important;
    width: 640px;
    margin: 0px !important;
    padding: 0px;
    margin-left: -35px !important;
  }
  
	h3.tds-header__h3.text-center{
		font-family: UHC2020 Sans Semibold, Arial, sans-serif !important;
    font-size:21px !important;
	}	
	.NeedHelpiParsys, .NeedHelpDisclaimerParsys, .footerContainer, .footerloading {
  		display:none !important;
	}
}

p.superUserErrMsg {
  margin: 20px 10px 10px 10px !important;
  color: #d0021b !important;
}

.sel-amt-row {
  display: flex;
  flex: 1 0 100%;
  flex-wrap: wrap;
  flex-flow: column;
}
.sel-amt-row .dueAmt{
  font-family: Arial,sans-serif;
    font-size: 19px !important;
    font-weight: 700 !important;
    font-stretch: normal;
    letter-spacing: normal;
    line-height: 1.1851;

}
.border-bottom-xs {
  border-bottom: 1px solid #ccc !important;
  min-width: 100% !important;
  padding: 10px 0px;
}
.border-none-updamt {
  min-width: 100% !important;
}

.oa-int-wrp-updt + .enterAmt + .ml-4 {
  margin-left: 19px!important;
}
@media (min-width: 781px) {
  .border-top-xs {
    border-top: 1px solid #ccc !important;
    padding-top: 16px;
  }
}

.errorBanner {
  margin-top: 1.25rem;
  width: 100%;
  display: flex;
}

.errorBanner p {
  max-width: 100% !important;
  flex-wrap: wrap;
}

.errorBanner > div {
  flex-grow: 1;
}

.errorBanner > div > p {
  padding: 2px 8px;
  display: inline-block;
}

.errorBanner > img {
  width: 23px;
  height: 23px;
}
#TopAlertError p {
  max-width: 100% !important;
  display: initial;
}
.serviceErrorPageHeader {
  margin: 3rem 0rem 0 0rem;
  padding: 10px;
}
.serviceErrorSubContainer {
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 1200px;
}
div.container.mt-5 {
  margin-top: 0.5rem !important;
}

.content-center h2 {
  padding-top: 10px;
}
#service_Error {
  margin-top: 1.5rem;
}
.content-center svg {
  padding-top: 10px;
  margin: 0px auto;
  font-size: 24px !important;
  font-weight: 600 !important;
}
.content-center + p {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: rgb(0, 38, 119) !important;
  margin: 15px 0px !important;
}
@media (max-width: 767px) {
  #service_Error {
    margin-top: 0rem;
  }
  .oa-int-wrp-updt + .enterAmt + .ml-4 {
    margin-left: 54px!important;
  }
}

@media (max-width: 429px) {
  .mobile {
    /* padding: 0.833rem 0 0.833rem 0 !important; */
  }

  .tds-notification {
    padding: 24px 19px;
  }

  .border-bottom-xs {
    padding-left: 1.6rem !important;
  }

  .radioBtnGroup {
    margin: 0 25px;
  }
  .eftPaymentMethod .radioBtnGroup{
    margin:0px !important;
  }

  .radioBtnGroup > fieldset > label:last-child {
    margin-top:1.5rem;
  }

  .shipCCMsg {
    width: 90%;
    margin: 0 1.9rem !important;
  }

  .shipCCMsg.lastUsedEft {
    margin: 0 7rem 0 1.5rem !important;
  }

  .topheaderpadding.choosepay {
    padding: 20px !important;
  }

  .paymentDetails fieldSet {
    /* margin: 0 3.25rem 0 5px !important; */
    width:100%;
  }

  .paymentDetails fieldSet > label{
    margin: 0!important;
    height: auto !important;
  }

  .paymentDetails fieldSet > label:first-of-type {
    margin-right: 4.25rem !important;
  }
  /* .tds-notification > div > div > div > p {
    font-weight: bold;
  } */

  /* .tds-notification > div > div > div {
    font-weight: 500;
  } */

  .error-header {
    font-weight: bold;
    margin-bottom: 0.5rem !important;
  }

  .error-body {
    font-weight: 500;
  }
  #confirmationpage .tds-button{
     width: 100% !important
  }

  .accountIcon {
    margin-left: 0 !important;
  }
}

@media (max-width: 376px) {

  .shipCCMsg {
    width: 76%;
  }

  .shipCCMsg.lastUsedEft {
   margin: 0 5.5rem 0 1.5rem !important;
  }

  .paymentDetails fieldSet > label:first-of-type {
    margin-right: 2rem !important;
  }
}

@media (max-width: 321px) {
  .tds-notification {
    padding: 24px 10px;
  }

  .radioBtnGroup {
    margin: 0 20px 0 30px;
  }
  .radioBtnGroup > fieldset > label:last-child {
    margin-top:1rem;
  }

  .shipCCMsg {
    width: 85%;
    margin: 0 1.5rem !important;
  }

  .shipCCMsg.lastUsedEft {
    margin: 0 2rem 0 1.5rem !important;
  }

  .topheaderpadding.choosepay {
    padding: 20px !important;
  }

  .paymentDetails fieldSet{
    margin: 0 -6px !important;
  }

  .paymentDetails fieldSet > label:first-of-type {
    margin-right: -5px !important;
  }
}

/* updated nacha zero attempts popup css */
.modal-header-container .modal-header {
  border-bottom: 0px !important;
  padding: 0px 10px !important;
}
.modal-content-container p div p:not(:first-child) {
  margin-top: 10px !important;
}
.modal-content-container a img {
  margin-right: 1px !important;
}
.nachaErr-modal .col-md-12.col-lg-12 {
  padding-left: 6px !important;
  padding-top: 5px !important;
}
.tds-text__paragraph.dueAmt {
  color: #002677 !important;
}

@media (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {
  .early-warning-header-title {
    padding-left: 3px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .early-warning-header-title {
    padding-left: 3px !important;
  }
  .sel-amt-row > div > label {
    margin: 0 12px 4px 1rem;
  }

  label, .paymentDetails label{
    display: flex !important;
  }

  .scheduleRadiosUpdt label.tds-form__radio-button {
    padding-bottom: 15px !important;
    margin-left: 15px !important;
}

  .radioBtnGroup {
    margin-left: 1rem !important;
  }
  .eftPaymentMethod .radioBtnGroup{
    margin:0px !important;
  }

  .uhc-lineheight-22 > div > svg > span {
    top: 0 !important;
  }

  .uhc-lineheight-22 > input {
    width: 2rem !important;
  }
}

.uhc-receipt--total-label ul.row {
  margin-left: -15px !important;
  margin-right: -15px !important;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .tds-date-picker__month > table td, .tds-date-picker__month > table th {
    word-break: initial !important;
  }
  .tds-date-picker__month-slider-inner {
    position: relative;
    width: 840px !important;
    display: flex;
  }
	.uhc-receipt-label+div[aria-live=polite] .row.mt-4 .col-7 {
  		flex: 0 0 52% !important;
    	max-width: 52% !important;
	}
	ul.row.mt-4 li.col-5{
		max-width: 39.666667%;
	}
}

.uhc-margin-left-30 p.tds-text__paragraph {
  margin-left: 0px !important;
}


@media (max-width: 767px) {
  .radioBtnGroup .uhc-margin-left-30 {
      margin-left: 30px;
  }
  .uhc-margin-left-30 p.tds-text__paragraph {
      margin-left: 0px !important;
  }
}