.MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem !important;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.tds-utility-icon .MuiSvgIcon-fontSizeSmall {
    font-size: 1rem;
}

.tds-breadcrumbs__chevron {
    margin: 0 12px;
}

.tds-breadcrumbs__active {
    font-family: UHC Sans Medium;
    color: #5a5a5a;
    font-weight: 300;
}
