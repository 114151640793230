a {
    text-transform: none !important;
}

body {
    opacity: 1 !important;
}

.pageOverviewHeader {
    height: 20.45rem;
    width: 100%;
    padding-top: 2.5rem;
    padding-left: 1rem;
    background-color: #fbfcfe;
    border-bottom: 2px solid #ccc;
    position: absolute;
}

.pageOverviewHeader.walletPromoBannerActive, .pageOverviewHeader.shipBannerActive {
    height: 30.45rem;
}

a.tds-breadcrumbs__link:hover {
    text-decoration: none;
}

.tds-modal__header  .tds-header__h2.tds-modal__title{
    font-family: "UHC Serif Headline SemiBold",Georgia Bold,serif;
}

.modal-header .early-warning-header-title h2 {
    font-size: 1.5rem;
}

.paymentDetails p.tds-text__paragraph {
    margin: 0px 20px;
}

.paymentDetails.fedEFTPage .tds-modal__focus p.tds-text__paragraph {
    margin: 0px 0px;
}

.tds-flex{
    display:flex;
}

.alert-body.nachaErr p.tds-text__paragraph {
    margin: 0px !important;
}

.tds-modal__header  .tds-header__h2.tds-modal__title{
    font-family: "UHC Serif Headline SemiBold",Georgia Bold,serif;
}

#TopAlertError button.tds-notification__dismiss-button, #alertbody_error button.tds-notification__dismiss-button{
    display: none !important;
}

.modal-header .early-warning-header-title h2 {
    font-size: 1.5rem;
}

.alert-body.nachaErr p.tds-text__paragraph {
    margin: 0px !important;
}

.gototop{
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid rgb(218, 219, 220);
    padding-bottom: 25px;
}

.gototop button{
    display: flex;
    margin-top: 50px;
}

.gototop button svg{
    transform: rotate(-90deg);
}

.ssbBtn{
    text-decoration: none;
    display:inline-block;
    border-left: 2px solid #002677 !important;
}

.align-items-center.p-0  p{  
    font-weight: 500 ;  
    font-size: 16px;
    line-height: 1.4;
}

.quickLinksContainer {
    z-index: 0;
    top: 10.5rem;
    margin: 0 0rem;
    position: relative;
    margin-bottom: 14rem;
}

.content {
    background-color: rgb(251, 251, 251);
    border: 1px solid rgb(216, 216, 216);
    z-index: 5;
    position: absolute;
    top: 17.25rem;
    width: 100%;

}

.planContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(218, 219, 220);
    border-radius: 12px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
    height: auto;
    margin: 3rem 0rem 0 0rem;
    padding: 10px;
}

.tds-popup-tip__close, .tds-popup-tip__close {
    position: static !important;
    float: right;
    margin-top: -36px;
    margin-right: -10px
}
.paymentAmount .tds-popup-tip__close,  .paymentAmount .tds-popup-tip__close {
    margin-top: -30px !important;
}

#paymentOverview .tds-modal h3 img {
    width: 24px;
    height: 24px;
}

.tds-breadcrumbs__listitem svg{
    color: #196ecf;
}

.paymentMethodGrid h3.recurringH3{
    color: rgb(51, 51, 51);
    font-family: Arial,sans-serif !important;
    font-size: 15px !important;
}

.paymentMethodGrid h3.recurringH3 span{
    padding-right: 5px;
}

.paymentMethodGrid > h3 > span:last-of-type, .paymentMethodGrid > p > span:last-of-type {
    padding-right: 17px;
}

.cancelPendingMsg a {
    text-transform: initial;
}

.paymentMethodGrid .recurringPaymentText span.recTxtBold {
    font-weight: bold;
}

.quickLinks {
    display: grid;
    height: 18rem;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: 25% 30% 25%;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 12px;
    background-color: #fff;
}

.quickLinks.threecol {
    display: grid;
    height: 18rem;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: 25% 26% 23% 25%;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 12px;
    background-color: #fff;
}

.quickLinks.threecol-oneplan {
    display: grid;
    height: 18rem;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: 25% 18% 21% 33%;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 12px;
    background-color: #fff;
}

.quickLinksHistory > .quickLinksHistoryMob > button{
    padding-left:6px;
}
.quickLinksHeader > h1{
    font-size: 1.9rem !important;
}

.quickLinksHistoryMob > button {
    padding-top: .4rem;
    margin:auto;
}


.quickLinksHeader h2 {
    font-family: "UHC Serif Semibold",Georgia Bold,serif;
    color: rgb(0, 38, 119);
    font-size: 28.83px !important;
    font-weight: 600;
    padding:10px 0px;
    margin-top: 20px !important;
    margin-bottom: 0px !important;
    text-transform: initial !important;
}

.recurringStatus .ml-2.mt-1.recurringStatusSetupMob {
    min-width:100% !important
}

.subHeader-text {
    grid-row: 2/3;
    grid-column: 2/5;
    width: 74%;
}

.subHeader-text > p {
    font-size: 16px !important;
    color: rgb(90, 90, 90) !important;
}

.quickLinksWallet {
    grid-row: 1/4;
    grid-column: 1/2;
    padding-top: .25rem;
    padding-left: .25rem;
}

.quickLinksHeader {
    grid-row: 1/2;
    grid-column: 2/6;
    align-self: center;
}

.quickLinksJump_0 > div > button, .quickLinksJump_1 > div > button, .quickLinksJump_2 > div > button {
    margin-top: .5rem;
    padding-right: 10px !important
}

.quickLinksJump_0 > div > button > span:hover, .quickLinksJump_1 > div > button > span:hover, .quickLinksJump_2 > div > button > span:hover {
    text-decoration: underline;
}

.quickLinksMakePayment > button > span:hover, .recurringStatusSetupMob > button > span:hover, .quickLinksHistoryMob > button > span:hover{
    text-decoration: underline;
}

.quickLinksJump_0 {
    grid-column: 2/4;
    height: 30px;
}

.quickLinksJump_0 > div, .quickLinksJump_1 > div, .quickLinksJump_2 > div {
    display: flex;
    height: 2.4rem;
    width: 100%;
    margin-top: 10px;
    align-items: flex-start;
}

.quickLinksJump_0.supPlan{ 
    width: 50% !important;
}
.quickLinksJump_0 > div > img {
    margin-right: 1rem;
}

.quickLinksJump_1 {
    grid-column: 3/5;
    height:30px;
    margin-left: 1rem;
}

.quickLinksJump_1 > div > img, .quickLinksJump_2 > div > img {
    margin-right: 1rem;
}
.quickLinksJump_0.threecol {
    grid-column: 2/3;
}

.quickLinksJump_1.threecol {
    grid-column: 3/4;
}

.quickLinksJump_2.threecol {
    grid-column: 4/5;
}

.quickLinksJump_0.threecol, .quickLinksJump_1.threecol, .quickLinksJump_2.threecol {
    height: 30px;
    width: 90%;
    margin-left: unset;
}
.quickLinksJump_0.threecol > div, .quickLinksJump_1.threecol > div, .quickLinksJump_2.threecol > div {
    display: flex;
    height: 2.4rem;
    width: 90%;

}

.quickLinksJump_0.threecol > div > button {
    width: unset;
}
.quickLinksMakePayment {
    grid-column: 2/3;
    height: 30px;
}

ul.quickLinksList{
    grid-column: 2 span/4;
}

ul.quickLinksList li{
   display: inline !important;
}

.quickLinksList ul li{
    display: inline;
}

.quickLinksHistory {
    grid-column: 3/4;
    height:30px;
}

.quickLinksHistory > div {
    display: flex;
    height: 2.4rem;
    width: 73% !important;
}
.quickLinksRecurring {
    grid-column: 4/5;
    height:7.19rem;
    background-color: rgb(244, 244, 244);
    padding-bottom: 1.75rem;
    width: 80%;
}

.quickLinksContainer .quickLinksRecurring {
    width: 85% !important;
    margin-left: -4.8%;
    padding-bottom: 1rem;
    height: auto !important;
}

.quickLinksRecurring > p {
    margin-left: 4rem !important;
    margin-right: 2rem !important;
    align-self: flex-start;
}

.quickLinksRecurring > a, .quickLinksRecurring > button {
    margin-bottom: 0.25rem;
    margin-left: 4rem;
    width: 100%;
}

button.smallTxt:hover {
    text-decoration: underline !important;
}

button.smallTxt {
    max-width: max-content;
}
.planTypeNames{
    font-family: Arial,sans-serif!important;
    font-weight: normal !important;
    font-size: 14.5px !important;
}
h3.monthlyPremiumAmt{
    margin:0px;
}
.recurringButton ul{
    padding:0px;
}
ul.paymentInfo.pdue.mt-4{
    padding:0px;
}
ul.paymentInfoBtn{
    margin: 0px;
    padding:0px;
}
.quickLinksJump_2.threecol img:first-child {
    width: 36px;
    height: 36px;
    margin-top: 10px;
}
.quickLinksJump_0.threecol img:first-child, .quickLinksJump_1.threecol img:first-child {
    width: 36px;
    height: 36px;
    margin-top:10px;
}
.quickLinksJump_2.threecol button{
	height:fit-content;
}
.quickLinksJump_1 > div > button{
    padding-right:10px !important;
}
.recurringStatus > img {
    height: 2.0rem;
}
.recurringStatus > h4 {
    margin: 0;
    margin-left: .5rem;
    margin-top: .3rem;
    color: rgb(0, 38, 119) !important;
    height: 1rem;
    align-self: flex-start;
    
}

.recurringStatus > p {
    font-family: Arial,sans-serif;
    min-width:100%;
    margin: 0;
    margin-left: .5rem;
    margin-top: .3rem !important;
    color: rgb(0, 38, 119) !important;
    height: 1rem;
    align-self: flex-start;  
}

.recurringStatus > h3 {
    font-family: Arial,sans-serif !important;
    min-width:100%;
    margin: 0;
    margin-left: 0.5rem;
    margin-top: .3rem !important;
    color: rgb(0, 38, 119) !important;
    height: 1rem;
    align-self: flex-start;  
    font-size: 15px !important;
    font-weight:bold !important;
}

.recurringStatus {
    display: flex;
    height: 3rem;
    padding:15px;
 }

 .shipDiscountModal > div > div > button{
    text-transform: none;
}

.shipDiscountModal {
    padding-bottom: 1rem;
}

.shipDiscountModalContent > p {
    width: 95%;
}

 .getDiscountMessage {
     margin-left: 4rem;
     display:block !important
 }

 .getDiscountMessage a:hover {
    text-decoration: none;
    cursor: pointer;
}

.shipDiscount .getDiscountMessage{
    margin-top:15px;
}

 .quickLinksMakePayment, .quickLinksHistory, .quickLinksRecurring {
    display:flex;
    flex-wrap: wrap;
    grid-row: 3/4;
    margin-bottom: 2rem;
 }

 .quickLinksJump_0, .quickLinksJump_1, .quickLinksJump_2{
    display:flex;
    flex-wrap: wrap;
    grid-row: 3/4;
    margin-bottom: 6.5rem;
}

.quickLinksMakePayment > button, .quickLinksHistory > button, .quickLinksMethods > button {
    padding: 5px !important;
    text-transform:initial !important;
    display:flex;
}
.quickLinksMakePayment > img, .quickLinksHistory > img, .quickLinksJump_0 > img, .quickLinksJump_1 > img, .quickLinksJump_2 > img, .quickLinksHistory .quickLinksHistoryMob > img {
    width:36px;
    height:36px;
}

ul.quickLinksMakePayment{
    margin:0px;
    padding:0px;
}
ul.quickLinksMakePayment li{
    display: flex;
}
ul.quickLinksMakePayment li img, ul.quickLinksHistory li img{
    width: 36px;
    height: 36px;
}
ul.quickLinksHistory{
    margin:0px;
    padding:0px;
}
img.historyImgMob{
    width: 36px;
    height: 36px;
}
ul.quickLinksHistory li{
    display: flex;
    width:74%;
}
ul.quickLinksRecurring{
    margin:0px;
    padding:0px;
}
ul.quickLinksRecurring li{
    display: flex;
}
ul.quickLinksRecurring li:last-child{
    margin-left: 4rem;
    width: 75%;
}
ul.quickLinksRecurring li:first-child{
    width: 75%;
}
.quickLinksMethods >img{
    width:40px;
    height:36px;
}
.tds-link .link-text {
    text-transform: initial !important;
}
a.tds-link.tds-link--medium.tds-typography__link.pdfLink .link-text{
    display: flex;
}
.downarrow{
    width:18px !important;
    height:18px !important;
    position: relative;
    top: 0px;
    left: 5px;
}
.editExpDateGroup >div:nth-child(1){
    margin-top:22px;
    margin-left: 10px;
}

.editExpDateGroup label.expSelYear + .tds-form__select{
    margin-top:22px;
    margin-left: 10px;
}

.editExpDateGroup {
    max-width: 105px;
}

.editExpDateGroup > label {
    width: 100%;
}

.editExpItem {
    width: 94px;
}

.manageWallet h5{
    font-weight: bold !important;
    font-size: 16px !important;
    
}
.manageWallet-body h5{
    font-size: 19.25px !important;
    font-family: Arial, sans-serif !important;
}

.walletPromoBanner, .shipBanner {
    display: flex;
    position: relative;
    top: 10rem;
    z-index: 0;
    background-color: #E5F8FB;
    padding: 1.25rem 2rem 1.25rem 3rem;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    border-radius: 1.25rem;
}

.shipBanner > div > p {
    width: 75%;
}

.shipBanner.linkDisable > div > p {
    width: 60%;
}

.walletPromoBanner > :nth-child(2), .shipbanner > :nth-child(2) {
    width: 46.63rem;
    margin-left: 1.6rem;
    margin-right: 5.66rem;
}

.walletPromoBanner > :nth-child(2) > p, .shipbanner > :nth-child(2) > p {
    font-size: 18px;
}

.pbCloseIcon {
    align-self: center;
    display: flex;
    justify-self: center;
    margin-left: 1rem;
}

.pbCloseIcon.linkActive{
    align-self: start;
    margin-left: 3rem;
}

.pbCloseIcon.linkDisabled{
    align-self: start;
    margin-left: 14.5rem;
}

.pbLink {
    align-self: center;
}

.walletPromoBanner > div > h2, .shipbanner > div > h2 {
    color: #3A3A3A !important;
    font-family: Arial,sans-serif !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    font-stretch: normal !important;
    letter-spacing: normal !important;
    line-height: 1.2485 !important;
    margin-top: 0 !important;
    margin-bottom: 1rem !important;

}

.shipBanner > a {
    width: 25%;
}

.walletPromoBanner > a > span > svg, .shipbanner > a > span > svg {
    transform: rotate(90deg);
}
.promoBannerClose {
    cursor: pointer;
}

#superUserErr_Overview > button {
    display: none;
}
.ssaAlignItems{
    display:flex;
}
.ssaAlignItems .recurringH3{
    margin-bottom:8px;
}
.ssaAlignItems a{
    display:block;
    margin-top:25px;
}
.ssaAlignItems.recCancelLink a{
    display:block;
    margin-top:29px;
}
.ssaTwoRowsItems{
    display:block;
}
.ssaTwoRowsItems h3{
    margin-bottom:0px;
}
.ssaTwoRowsItems .twoBtn{
    margin-left: 30px;
    display: flex;
    padding-left: 2px;
}
.ssaTwoRowsItems .twoBtn li{
    display:inline-flex;
}
.ssaTwoRowsItems .twoBtn li:first-child{
    margin-right:25px;
}
.recurringStatusSetup{
    margin-top:20px;
}

.lepMessage {
    display: flex;
    width: 99%;
    color: #6E7072;
}

.lepBanner > button {
    display: none;
}

.lepBanner > div > div:last-of-type > p {
    font-size: 1rem;
}

.lepBanner.tds-notification--with-dismiss {
    padding-right: 0;
}

.lepBanner.tds-notification {
    padding: 24px 10px 20px 24px;
}
.lepBannerBody {
    display: flex;
}

.lepBannerBody > p {
    width: 88%;
}

.lepHeading > div > p, .lepHeading > span > p {
    margin-right: 1rem !important;
    font-size: 1rem !important;
    max-width: unset;
}

.lepBannerLink {
    display: flex;
    margin-left: 1rem;
}
@media (max-width: 768px) {
    ul.quickLinksRecurring li:first-child{
        width: 100% !important;
    }
    .ssaAlignItems{
        display:block;
    }
    .ssaAlignItems a{
        display:block;
        margin-top:0px;
        padding-left:30px;
    }
    .ssaAlignItems.recCancelLink a{
        display:block;
        margin-top:2px;
    }
    .ssaTwoRowsItems .twoBtn{
        margin-left:30px;
        display: block;
    }
    .ssaTwoRowsItems .twoBtn li{
        display:block;
    }
}

@media (min-width: 1200px) {
    .pageOverviewHeader .container, .overviewcontainer {
        width: 1224px !important;
        margin:0px auto;
    }
}

@media (max-width: 1024px) {
    .shipDiscount .getDiscountMessage{
        margin-top:0px;
    }
}

@media (min-width: 1025px) {
    .quickLinksRecurring {
        width: 80% !important;
        margin-bottom: 0 !important;
    }

    .quickLinksHistory > div {
        width: 90%;
    }

    .quickLinksHeader {
        margin-top: -1rem !important;
    }

    .subHeader-text {
        width: 74% !important;
    }

    .quickLinks.threecol-oneplan {
        grid-template-columns: 25% 20% 20% 33% !important;
    }
}

@media (max-width: 1024px) {
    .quickLinks.threecol-oneplan {
        grid-template-columns: 25% 19% 21% 37% !important;
    }

    .quickLinksJump_0.threecol, .quickLinksJump_1.threecol, .quickLinksJump_2.threecol {
        width: 99%;
    }
    
    .quickLinksHistory > div {
        width: 85%;
    }

    .quickLinksRecurring {
        padding-bottom: 0;
        height: 6.25rem;
    }

    .quickLinksRecurring, .quickLinksMakePayment, .quickLinksHistory {
        margin-bottom: 0;
    }

    .subHeader-text {
        margin-top: 0;
    }
}

@media (max-width: 1020px) {

    .quickLinksWallet{
        display: none;
    }

    .quickLinksJump_0, .quickLinksJump_1, .quickLinksJump_2 {
        margin-bottom: 1.5rem !important;
    }
    .quickLinksJump_0.threecol {
        margin-bottom: 1rem !important;
    }
    .quickLinksJump_1 {
        margin-left: 0 !important;
    }

    .quickLinksJump_0, .quickLinksJump_1, .quickLinksJump_2, .quickLinks{
        display: block !important;
        height: auto !important;
        padding: 10px 15px;
    }
    .quickLinksJump_0 > div {
        width:100% !important;
        margin-bottom: 0px !important;
    }
    .quickLinksMakePayment > img, .historyImgMob {
        width: 36px !important;
        height: 36px !important;
    }
    .quickLinksHistory > div {
        margin:0px !important;
    }
    .quickLinksMakePayment, .quickLinksHistory{
        height: 80px !important;
        align-items: center;
    }
    .quickLinksHistoryMob{
        align-items: center;
    }
    .quickLinksMethods {
        width: auto !important;
        margin: 10px 0px;
    }

    .subHeader-text p{display: none;}
    .quickLinksHeading{
        text-align: center;
        padding: 10px 15% !important;
    }
    .quickLinksContainer .quickLinksMakePayment > button, .quickLinksContainer .quickLinksHistoryMob > button, .quickLinksContainer .quickLinksMethods > button{
        padding-left: 20px;
        width:65% !important;
        text-align: left;
    }
    
    .quickLinksContainer .quickLinksRecurring > p {
        margin-left: 5.3rem;
        margin-right: 2rem;
    }
    .recurringStatus > h4 {
        margin-left: 1.5rem !important;
    }
    .quickLinksMakePayment, .quickLinksHistory {
        margin: 10px 15px !important;
    }
    .recurringStatus {
        padding-left: 20px !important;
        width:100% !important;
    }
    .quickLinksHistory > .quickLinksHistoryMob button{
        margin: 0px !important;
        align-items: center;
    }
    .recurringStatus > p{
        margin-left: 1.0rem !important;
    }
}

@media (min-width: 781px) {
    .quickLinksRecurring.graybox {
        position:relative;
        top:-15px;
    }

    .quickLinksRecurring.graybox.shipDiscount {
        width: 85% !important;
    }
}
.recOnSsaReq{
    margin-left:50px;
    margin-top:0px;
}
.recEftSsaCombine{
    color:#5A5A5A;
    font-weight: bold;
    padding-top:10px !important;
    margin-bottom:0px !important;
}
.recSeperator{
    font-weight: normal;
}
@media (max-width: 768px) {
    .manageWallet-body {
        padding: 20px 1rem 20px 1rem !important;
        margin: 60px 3% 25px 3%!important;
    }
    .processedContent{
        margin-left: 2.2rem !important;
    }
    .amtTooltipContent{
        margin-left:2.2rem !important;
        margin-bottom: 20px;
    }
    .totalAmtDueTooltipContent{
        margin-top:20px;
        margin-left:1.5rem !important;
        margin-bottom: 5px;
    }
    .billinghistorycontent{
        margin-top:40px;
        margin-left:1.5rem !important;
        margin-bottom: 5px;
    }
    .recurringH3 img {
        position: relative;
        top: 3px;
    }
    .tds-text__paragraph.recurringPaymentText img{
        padding-top:0px;
    }
    .setup_SSARRB_links .ctaEditLink.tds-link.tds-link--medium{
        margin-left: 0px;
    }
    .recSeperator{
        font-weight: normal;
        padding: 0px 5px;
    }
    .recurringStatus > p{
        margin-left:10px !important;
    }
    .recOnSsaReq{
        margin-left:30px;
        margin-top:1px;
    }
    .paymentMethodGrid h3.recurringH3.cms_Gap_recurring span{
        padding-right: 5px;
    }
    .paymentMethodGrid h3.recurringH3.span{
        padding-right: 0px;
    }
    .makepaymentBtn > small > a {
        text-decoration: none;
        text-transform: initial;
    }
    .makepaymentBtn > small {
        width: 100% !important;
    }
    html{
        scroll-padding-top: 70px;
    }

    .planContainer {
        margin-top: 3.75rem;
        padding: 0px;
        display: inline-block;
        width: 100%;
    }

    .paymentOverview {
        width: 100%;
        padding: 0 1rem;
        margin: 0 auto;
        background-color: #fbfcfe;
        position: relative;
        overflow-x:hidden;
    }
    .overviewcontainer {
        width: 100%;
    }

    .container > h1 {
        margin: 0;
    }

    .pageOverviewHeader {
        height: 9.75rem;
        padding: 1.75rem 0;
        border-bottom: unset;
    }

    .tds-breadcrumbs {
        display: flex;
        margin-bottom: 1rem;
    }

    .container > h1 {
        margin-bottom: 3.75rem;
    }

    .quickLinksRecurring {
        width: 100%;
        height: 6.25rem;
        margin-bottom: 3rem;
        padding-bottom: 1rem;
    }

    .quickLinksRecurring.graybox {
        width: unset !important;
    }

    .quickLinksContainer .quickLinksRecurring {
        margin-left:0;
    }
    .quickLinksRecurring > p {
        margin-left: 4.6rem !important;
        margin-bottom: 0 !important;
        width: 100%;
    }

    .quickLinksRecurring > button {
        margin-left: 5.5rem;
        width: 100%;
    }

    .quickLinksMakePayment{
        border-bottom: 1px solid rgb(216, 216, 216);
    }

    .recurringStatus {
        height: auto;
        padding: 15px 15px 5px 15px !important;
    }
    .recurringStatus > h3 {
        margin-left: 0.6rem !important;
        min-width: auto !important;
        height: auto;
    }

    .recurringStatusSetupMob {
        margin-left: 0rem;
        margin-top: 15px;
    }

    .quickLinksRecurring .noMarginLeft.recurringStatusSetupMob {
        margin-left: 0rem !important;
    }
    .quickLinksContainer .quickLinksRecurring {
        width: 100% !important;
    }

    .getDiscountMessage {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-left: 4.5rem;
    }

    .getDiscountMessage > p {
        margin-right: .5rem;
    }

    .quickLinksHistoryMob > button, .quickLinksMakePayment > button {
        padding-left: 10px !important
    }
}

@media (max-width: 429px) {
    .tds-breadcrumbs {
        display: none !important;
    }

    .overviewcontainer {
        margin-top: 0;
    }

    .container > h1 {
        margin-bottom: 0;
    }

    .pageOverviewHeader {
        padding: 0;
        height: 2.5rem;
    }

    .container > h1 {
        margin: 1.75rem 0;
    }

    .planContainer {
        margin-top: 2.5rem;
    }

    .pageOverviewHeader > .container {
        padding-right: 0;
        padding-left: 0;
        margin-top: -13px !important;
        padding-top: 10px !important;
    }
    .quickLinksHistory > div {
        width:100% !important;
    }
    .quickLinksContainer .quickLinksRecurring {
        width: 109.6% !important;
        margin-left: -4.8%;
        padding-bottom: 1rem;
        height: auto !important;
    }
    .quickLinksContainer {
        top: 5.5rem !important;
        margin-bottom: 10rem !important;
    }
    .pageOverviewHeader{
        width: auto !important;
    }
    .quickLinksHeading{
        text-align: center;
        padding: 10px 1% !important;
    }
    .quickLinksContainer .quickLinksRecurring > p {
        margin-left: 4.5rem !important;
        margin-right: 2rem;
    }

    .quickLinksRecurring > button {
        margin-left: 6.3rem;
    }

    .quickLinksMakePayment, .quickLinksHistory, .quickLinksRecurring{
        margin-bottom: 1rem !important;
    }
    .quickLinksContainer .recurringStatus {
        padding-left: 13px !important;
    }
    /* .recurringStatus > h3 {
        margin-left: 1.2rem !important;
    } */

    .recurringStatus > p {
        min-width: 80%;
    }

    .shipDiscountModal {
        text-align: left;
    }

    .shipDiscountModalHeader > h2{
        font-size: 1.43rem;
    }

    .shipDiscountModalContent > button {
        width:100%;
    }
    
    .shipDiscountModalContent > button:first-of-type{
        margin-bottom: 1rem;
    }

    .shipDiscountModalContent > p {
        width: 100%;
    }
    
    .getDiscountMessage {
        margin-left: 4.4rem;
    }

    .getDiscountMessage > p, .getDiscountMessage > a {
        width: 100%;
    }

    .getDiscountMessage > p {
        margin-bottom: .75rem !important;
    }

    .quickLinksMakePayment, .quickLinksHistory {
        margin: 10px 0px !important;
    }

    .paymentMethodGrid > h3 > span:last-of-type, .paymentMethodGrid > p > span:last-child {
        padding-right: 5px !important;
    }

    .paymentMethodGrid > p > span:last-of-type {
        padding-right: 0px !important;
    }
    
    .manageWallet-body {
        padding: 20px 1rem 20px 1rem !important;
        margin: 60px 3% 25px 3%!important;
    }
    .manageWallet h2{
        height: auto !important;
        line-height: 27.35px !important;
    }
    .walletHeader{
        border: none !important;
    }
    .expiredCardLbl{
        border-top: 2px solid #dadbdc !important;
        padding-top: 20px !important;
    }
    .manageWallet .tds-modal__footer, .manageWallet .tds-modal__header{
        display: block !important;
    }
    .manageWallet .tds-button, .paymentDetails .tds-button, .accordionContent .tds-button{
        width: 100% !important;
        margin-bottom: 20px !important;
    }
    .fedpaymentBtnGroup{
        padding-right: 10px;
        max-width: 75%;
        margin: 0px auto !important;
        margin-top: 40px !important;
    }
    .tds-card.manage-buttons{
        max-width: 75% !important;
        margin: 0 auto !important;
    }
    .eftForm fieldset {
        padding: 0px !important;
        width: 100% !important;
    }
    .manageWallet .form-control.ibc {
        width: 100% !important;
    }
    .editWalet {
        width: 100% !important;
    }
    .walletPlanInfo {
        width: 100%;
        display: grid !important;
    }
    .walletPlanName {
        width: 100% !important;
        display: grid;
        order: 2;
        margin-top: 30px;
    }
    .learnWallet {
        width: 100% !important;
        text-align: right;
        display: grid;
        order: 1;
    }
    .walletAccount {
        display: flex !important;
        flex-wrap: wrap;
        width: 100% !important;
        position: relative;
    }

    .walletAccount > .accountIcon {
        width: unset;
    }

    .walletAccount > p {
        width: 90%;
    }
    .editRemoveLinks.noIcon {
        margin: 0;
        right: 85px !important;
    }
    .editRemoveLinks {
        top: 44px !important;
        position: absolute;
        right: 50px;
    }
    .manageWallet .tds-notification__icon{
        height: 1.5rem !important;
    }
    .manageWallet .walletNotificationMsg{
        margin: 2rem auto 3rem !important;
    }
    .recurringLabel {
        width: 100% !important;
    }
    .learnWallet button.tds-link.tds-link--medium{
        text-align: right !important;
        margin-left: 32%;
    }
    .walletPlanName > div > div > h2 {
        line-height: 32px !important;
    }
    /* .accountIcon{
        margin-top: 10px !important;
    } */
    .manageWallet .walletNotificationMsg .tds-text__paragraph {
        font-size: 1.2rem !important;
        width: 80% !important;
    }
    .manageWallet #reviewsubmit .ml-4 {
        margin-left: 0px !important;
    }
    .manageWallet .radioBtnGroup > fieldset > label:last-child {
        margin-top: 0px !important;
    }
    .walletPaymentMethodHeader {
        margin: 1.5rem 0 !important;
    }

    .walletPaymentMethod {
        flex-wrap: wrap !important;
        height: auto !important;
        padding:5px 0;
        padding-left: 2.2rem !important;
    }
    .walletPaymentMethods > div:nth-child(even){
        background: rgb(244, 244, 244) !important;
    }
    .walletPaymentMethods > div{
        width:110% !important;
        margin-left: -5% !important;
    }
    .manageWallet #containerid .input-row{
        margin-right: 5px !important;
    }
    .manageWallet #containerid .input-row input {
        width: 100% !important;
    }
    .manageWallet #containerid .btn{
        margin-bottom: 20px !important;
    }
    .manageWallet .tds-margin-xsm-horizontal {
        margin: 0 0px;
    }
    .manageWallet .walletPaymentMethod > .walletAccount > p{
        padding-bottom: 20px !important;
        color: rgb(51, 51, 51) !important;
        font-weight: bold;
    }
    .recurringLabel .tds-text__paragraph{
        padding-top: 20px !important;
    }
    .manageWallet .walletPaymentsOverviewBtn .tds-button{
        margin: 0 10% !important;
        width: 80% !important;
    }
    .manageWallet .tds-header__h6 {
        padding-bottom: 1rem !important;
        border-bottom: 2px solid rgb(218, 219, 220) !important;
        margin-bottom: 0px !important;
    }
    .walletPaymentMethod, .walletPaymentMethods > div:last-of-type {
        border:none !important;
    }
    .manageWallet .ml-3, .manageWallet .mx-3{
        margin-left: 0px !important;
    }
    .manageWallet .walletPaymentMethods h4{
        padding-left: 15px !important;
    }
    .manageWallet h1#globalContentIdForSkipLink{
        margin-top: 25px !important;
    }
    .manageWallet .walletHeader{
        padding-top: 1rem !important;
    }

    .walletPromoBanner, .shipBanner {
        flex-wrap: wrap;
        top: 3rem;
        height: 17rem;
        padding: 1.25rem 1rem;
        margin-bottom: 0;
        margin-top: 3rem;

    }

    .shipBanner.linkDisable {
        height: 13rem;
    }

    .shipBanner.linkDisable > div > p {
        width: 75%;
    }

    .walletPromoBanner > :nth-child(2) {
        margin-left: .5rem;
    }

    .walletPromoBanner > div:nth-child(2), .shipBanner > div:nth-child(2) {
        margin-right: 0rem;
    }

    .walletPromoBanner > div, .shipBanner > div {
        display: flex;
        width: 100%;
    }
    .walletPromoBanner > div > :nth-child(1) {
        margin-left: 1rem;
    }

    .shipBanner > div > :nth-child(1) {
        margin-left: 1rem;
        width: 75%;
    }

    .walletPromoBanner > div > :nth-child(2) {
        width: 80%;
    }

    .shipBanner > div > :nth-child(2) {
        width: 20%;
        display:flex;
        justify-content: flex-end;
    }

    .walletPromoBanner > div:last-child {
        margin-left:.5rem;
        margin-top: 1rem;
        width: 100%;
    }

    .shipBanner > div:last-child {
        width: 100%;
    }

    .walletPromoBanner > div > a > span:last-child > svg, .shipBanner > div > a > span:last-child > svg {
        transform: rotate(90deg);
    }
}

@media (max-width: 384px) {
    .walletPromoBanner, .shipBanner {
        height: 21rem;
    }
}

@media (max-width: 375px) {
    .bannerOverview .tds-notification__body p{
        width:92%;
    }
}

@media (max-width: 321px) {
        .quickLinksContainer .quickLinksMakePayment > button, .quickLinksContainer .quickLinksHistoryMob > button, .quickLinksContainer .quickLinksMethods > button{
            width:78% !important;
        }

        .quickLinksRecurring {
            height: 9.5rem;
            padding-bottom: 0;
        }

        .quickLinksRecurring > p:first-of-type {
            margin-top: 1rem;
            margin-left: 4.3rem !important;
        }
        .recurringStatus {
            margin-bottom: 1rem;
        }
        .recurringStatus .ml-2.mt-1.recurringStatusSetupMob {
            min-width: auto !important
        }

        .shipDiscountModalHeader {
            padding: 24px 50px 0px 24px !important;
        }
        .editRemoveLinks {
            width: 22% !important;
        }
        .walletPaymentMethod{
            padding-left: 0.75rem !important;
        }
        .manageWallet h2{
            height: 45px !important;
            line-height: 27.35px !important;
        }
        .manageWallet .tds-modal h2{
            height: auto !important;
        }
        .manageWallet h1#globalContentIdForSkipLink{
            line-height: 31px !important;
        }
        .ssbBtn{
            text-align: center;
            margin-left: 0px !important;
        }
        .choosePayMethod.mt-5{
            margin-top: 1rem!important;
        }
}

/* Tempo 2.3.3 css upgrade */
.tds-modal__content a {
    text-transform: capitalize;
}
.tds-breadcrumbs__listitem {
    font-size: 1rem;
    align-items: center;
}

h1#globalContentIdForSkipLink{
    margin: .67em 0;
}

.payment .tds-header__h3{
    margin-bottom:0px;
    margin: 5px 0;
}

.paymentgovt .tds-header__h3{
    margin-bottom:0px;
    margin: 5px 0;
}

.tds-modal .tds-text__paragraph h3{
    font-size: 19px !important;
    color: #002677;
    margin-bottom: 0px !important;
    line-height: 25px !important;
    font-family: Arial, sans-serif !important;
  }

  .tds-modal .tds-text__paragraph h3{
      margin-top: auto;
      margin-bottom:auto;
  }

  .tds-text__paragraph p:not(:first-child){
    padding-top: 15px;
  }

  .tds-text__paragraph p, .tds-text__paragraph, .tds-text__paragraph--light{
    font-family: Arial,sans-serif;
    font-size: 15px;
    font-stretch: normal;
    letter-spacing: normal;
    line-height: 1.4;
    color: #333 !important;
  }

  .tds-text__paragraph p b{
      font-weight: 700!important;
  }

.isbBanner{
    margin-bottom:10px;
    width:100%
}
.isbBanner .tds-notification__icon{
    display:none;
}

.pastDueBanner .tds-notification__body, .bannerOverview .tds-notification__body{
    display: flex;
    width:100%;
}
.pastDueBanner .tds-notification__body .makePaymentLink, .bannerOverview .tds-notification__body .overviewLink, .isbBanner .tds-notification__body .makePaymentLink {
    flex-grow: 1;
    text-align: right;
    margin-right: 65px;
}
.paidInFullBanner .tds-notification__body .creditBalanceLink, .isbBanner .tds-notification__body .creditBalanceLink{
    margin-left:auto;
}
.paidInFullBanner .tds-notification__body{
    display: flex;
    width: 100%;
}
.isbBanner .tds-notification__body{
    width: 100%;
    margin-left: 15px;
}
.IsbBannerModal h2{
    font-family: "UHC Serif Headline SemiBold",Georgia Bold,serif;
    font-size: 24.83px;
}
.IsbBannerModal .tds-typography__paragraph h3{
    font-size: 20px !important;
}
.IsbBannerModal ul{
    margin: 10px 10px !important;
}
.IsbBannerModal p:last-child{
    padding-top:0px !important
}
.isbBannerContent{
    width:60% !important;
    display: block;
}
.processedContent p {
    max-width: 100%;
}
.IsbBannerModal{
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 45px;
    margin-right: 112px;
}
.makePaymentLink a, .overviewLink a{
    text-transform: initial;
}
.pastDueBanner .tds-notification__body p, .bannerOverview .tds-notification__body p{
    font-size: 17px;
}
.pastDueBanner .tds-notification__body p b, .bannerOverview .tds-notification__body p b{
    font-weight: bolder;
}

.recurringStatus > h3 {
    margin-left: .5rem;
}
div[role=tabpanel]:focus {
    outline: 0;
}
div.planContainer:focus-visible,  div.planContainer:focus{
    outline: 5px solid #6c992b!important;
}
div[role=tabpanel]:focus-visible{
    outline: 5px solid #6c992b!important;
    outline: -moz-focus-ring-color auto 1px !important;
    outline: -ms-focus-ring-color auto 1px !important;
} 
@media (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2){
    .IsbBannerModal{
        position: initial;
        margin-top: 20px;
    }
    .isbBannerContent {
        width: 100% !important;
        display: block;
    }
    .isbBanner h3 {
        font-weight: bold !important;
        font-size: 24.63px !important;
        font-family: Arial, sans-serif !important;
        color: #002677;
        margin-bottom: 15px;
        line-height: 29px;
    }
    .paidInFullBanner .tds-notification__body .creditBalanceLink {
        margin-top:15px;
    }
    .paidInFullBanner .tds-notification__body{
        display: block;
        width: 100%;
    }
    .tds-pagination {
        align-items: flex-start;
        justify-content: center;
        position: relative;
    }
     #pagination button.tds-pagination__navigation-button {
        width: initial !important;
        padding: 15px 0px !important;
    }
    .mobileDateSelector{
        margin-top:15px;
    }
    
    .pagination > p {
        font-weight: 500;
        margin: 0px auto;
    }
    .quickLinksHeader h2 {
        text-align: center;
    }
    p.tds-text__paragraph.paymentDueText {
        color: #002677 !important;
    }
    .paymentDetails .recSchPayDP p.tds-text__paragraph {
        margin: 0px 30px;
    }
     #srp_note{
        margin-left: 35px !important;
        margin-top: 0px !important;
    }
    .recItemNextLine{
        display:initial !important;
        margin-left:3px !important;
    }
    .ssaAlignItems.recCancelLink.noSpanWidth a{
        margin-top:0px !important;
        font-style: normal !important;
     }
     .ssaAlignItems.recCancelLink.anchorMarginTop a{
        margin-top:0px !important;
    }

    .lepBanner > button {
        display: none;
    }

    .lepBannerBody {
        flex-wrap: wrap;
    }

    .lepBannerLink {
        margin-left: 0;
    }

    .lepMessage {
        width: 95%;
        margin-bottom: 1rem;
    }

    .lepHeading > span {
        width: 100%;
        display: flex;
    }
}
.reqEditSSA .ctaEditLink {
    margin-left: 2px;
}
.recItemNextLine{
    display:block;
    margin-left:20px;
    margin-top:3px;
}
.recSeperator{
    font-weight: normal;
}
.ssaAlignItems.recCancelLink.noSpanWidth span{
    padding:0px 1.5px;
}
.ssaAlignItems.recCancelLink.noSpanWidth a{
   margin-top:30px;
   font-style: normal !important;
}
.ssaAlignItems.recCancelLink.anchorMarginTop a{
    margin-top:25px;
}
.ssaAlignItems.recCancelLink.anchorMarginTop a span{
    font-style: normal !important;
}
.ssaAlignItems.recCancelLink.anchorMarginTop span{
    font-style: italic !important;
}
.ssaAlignItems.recCancelLink.noSpanWidth a span{
    font-style: normal !important;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .IsbBannerModal{
        position: initial;
        margin-top: 20px;
    }
    .isbBannerContent {
        width: 100% !important;
        display: block;
    }
    .isbBanner h3 {
        font-weight: bold !important;
        font-size: 24.63px !important;
        font-family: Arial, sans-serif !important;
        color: #002677;
        margin-bottom: 15px;
        line-height: 29px;
    }
    .paidInFullBanner .tds-notification__body .creditBalanceLink {
        margin-top:15px;
    }
    .paidInFullBanner .tds-notification__body {
        display: block;
        width: 100%;
    }
    .ssaTwoRowsItems .twoBtn{
        margin-left:30px;
    }
    .ssaTwoRowsItems .twoBtn li{
        display:flex;
    }
    .ssaTwoRowsItems .twoBtn li:first-child{
        margin-right:25px;
    }
    .ssaAlignItems.recCancelLink.anchorMarginTop a{
        margin-top:0px;
    }
    .ssaAlignItems.recCancelLink.noSpanWidth a{
        margin-top:0px !important;
        font-style: normal !important;
     }
    .reqEditSSA span.setup_SSARRB_links{
        margin-left:25px;
        margin-right:0px;
        margin-top:0px;
    }
    .reqSSABtn.iPadAlignLink{
        position: initial;
        margin-left:25px;
    }
    .reqEditSSA .ctaEditLink {
        margin-top: 3px;
    }
    .ssaAlignItems{
        display: block;
    }
    .ssaAlignItems .recurringH3{
        margin-bottom:0px;
    }
    .ssaAlignItems.recCancelLink a{
        margin-top:0px;
        margin-left:30px;
    }
    .ssaAlignItems.recCancelLink.recFlexLink{
        display:flex;
    }
    .ssaAlignItems.recCancelLink.recFlexLink a{
        margin-top:30px;
        margin-left:10px;
    }
    .ssaAlignItems.recCancelLink.recFlexLink h3{
        padding-right:0px !important;
    }
    .recurringStatusSetup{
        margin-top:1px;
    }
    .setupCmsLinks{
        margin-left:50px;
    }
    .restEasy_txt{
        margin-left:5px !important;
    }
    .setupCmsLinks span.setup_SSARRB_links{
        padding-left:20px;
    }
    .monthlyPremium .monthlyPremiumAmt{
        font-size: 1.15rem;
    }
    h3.recurringH3 {
        padding-right: 25px !important;
    }
    .setup_SSARRB_links .ctaEditLink.tds-link.tds-link--medium{
        margin-left: 0px;
    }
    .recOnSsaReq{
        margin-left:30px;
        margin-top:5px;
    }
    #pagination button.tds-pagination__navigation-button {
        width: initial !important;
        padding: 0px 0px !important;
    }
    .pagination > p {
        font-weight: 500;
        margin: 0px auto;
    }
    .quickLinksHeader h2 {
        text-align: center;
        padding: 10px 15%!important;
    }
    .pastDueBanner {
        background-color: #fef9ea;
        padding: 23px;
        margin-bottom: 10px;
    }
    .pastDueBanner .tds-notification__body{
        display:block;
    }
    .pastDueBanner .tds-notification__body .makePaymentLink{
        text-align:left;
    }
    ul.quickLinksRecurring li:last-child{
        margin-left: 68px;
        width: 75%;
    }
    p.tds-text__paragraph.paymentDueText {
        color: #002677 !important;
    }
    .manageWallet-body {
        padding: 20px 1rem 20px 1rem !important;
        margin: 50px 0% !important;
    }
    .learnWallet {
        width: 40% !important;
    }
    .walletAccount{
        width:80% !important;
    }
    .manageWallet .walletPlanName h2 {
        height: auto !important;
        line-height: 31px !important;
    }
    .quickLinksContainer .quickLinksRecurring {
        margin-left:0;
    }
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .radioBtnGroup .tds-form__checkbox-label-text, .radioBtnGroup .tds-form__radio-button-label-text {
        vertical-align: middle;
        }
}
@media (min-width: 770px){
    .quickLinksJump_0.newSupPlan button.tds-link.tds-link--medium {
    	max-width: 45%;
    }
    .getDiscountMessage button {
        margin-top: 7px;
        font-weight: normal;
    }
    .walletAccount{
        width:60% !important;
    }
}


.bckTxt p{
    margin:0px !important;
}
/* Cologo and Pink Banner css Fix */
@media (max-width: 469px) {
 
    .alert-message + .tabParsys .mobileHeadContainer {
       margin-top : 114px !important;
       position:relative;
    }
     
}
     
    @media (max-width: 979px ) {
    .alert-message + .tabParsys .mobileHeadContainer {
       margin-top : 50px ;
    }
     
    }
     
    .myuhcTopContainer .col-md-3 {
       max-width:100% !important;        
    }

/* Wallet page css */
.manageWallet{
    background: rgba(216, 216, 216, 0.16);
    padding-bottom: 60px;
}
.manageWallet h1#globalContentIdForSkipLink{
    display: block;
    background-image: url('../../tokens/icons/wallet.png');
    background-repeat: no-repeat;
    padding-left: 70px;
    margin-top: 40px;
    background-size: 50px 50px;
    margin-bottom: 15px;
    line-height: 50px;
}
.manageWallet h2{
    padding-left: 70px;
    color: rgb(0, 38, 119);
    font-family: Arial, sans-serif !important;
    font-size: 16.96px;
    font-weight: bold;
    height: 21px;
    line-height: 20.35px;
}
.manageWallet .page-header{
    padding-bottom:20px;
}
.manageWallet-body{
    background: rgb(255, 255, 255);
    border-radius: 12px;
    border: 1px solid rgb(216, 216, 216);
    padding:80px 6.69rem 50px 6.69rem;
    margin: 50px 0; 
}
.walletHeader{
    padding-bottom: 30px;
    width: 100%;
    padding-top: 2.5rem;
    padding-left: 1rem;
    background-color: #fbfcfe;
    border-bottom: 2px solid #ccc;
    
}

.walletNotificationMsg {
    margin: 0 auto;
    margin-top: 2rem;
    max-width: 1170px;

}

.editWalet label{
   display:block !important;
}
.editlabel_text {
    padding: 0px 0px 20px 0px;
}
.fl_R{
    float:right;
}
.editWalet {
    width: 70%;
    height: auto;
    display: inline-block;
    margin-bottom: 20px;
}

.accordionHide {
    height: 0;
    display: none !important;
}

.accordionHide.show {
    height: auto;
    /* transition: height 2s; */
    display: inline-block !important;
    margin-bottom: 20px;
}

.editWalet .formBorder{
    padding: 1.5rem;
}
.manageWallet .tds-modal h2 {
    padding: 0px 10px 10px 0px;
    font-size: 28.83px;
    height: auto;
    line-height: 34px !important;
    font-family: "UHC Serif Headline SemiBold",Georgia Bold,serif;
  }
.ccErrMsg {
    border-radius: 5px;
    color: #D80808;
    border: 1pt solid #D80808;
    background: #fff3f6;
    width: 100%;
    padding: 7pt 7pt;
    margin-bottom: 12pt
}
.creditcard_desc p{
    margin-bottom:20px;
    font-family: Arial,sans-serif;
    font-weight: 600;
    font-size: 13px;
    color: #333;
    display: block;
}

.walletSuperUserError {
    margin-bottom: 1rem;
    width: 71.5%;
}

.editCCWallet + .walletSuperUserError {
    margin-top: 0px !important;
}

.walletSuperUserError > div > button {
    display: none;
}

.walletSuperUserError.addMOP {
    margin-top: -1.5rem;
}

.walletPlanInfo {
    width: 100%;
    display: flex;
}

.walletPlanName {
    width: 70%;
}

.walletPlanName > p {
    margin-left: 0rem !important;
    font-weight: bold;
    font-size: .8rem;
}

.walletPlanName > div > div > h2 {
    padding-left: 0;
    font-size: 1.802rem;
    font-family: serif;
}

.walletPlanName > div > div > p, #wallet_planName p {
    color: rgb(90, 90, 90) !important;
    font-family: Arial,sans-serif;
    font-size: 15px;
    font-stretch: normal;
    letter-spacing: normal;
    line-height: 20.35px;
    font-weight: normal;
}

#wallet_planName h3 {
    margin-bottom: 5px;
}

.learnWallet {
    width: 30%;
    text-align: right;
}

.learnWallet > a {
    font-size: 1rem;
    height: 1.25rem;
    cursor: pointer;
}

.walletPlanName .tds-card.tds-card--primary .tds-card__content{
    padding:0px !important;
}

.walletPaymentMethodHeader {
    margin: 2.5rem 0;
}
.walletPaymentMethodHeader > h4 {
    font-size: 1.25rem !important;
    font-family: UHCSerifHeadline-Semibold !important;
    font-weight: 700 !important;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.3;
}

.walletPaymentMethodHeader > h5 {
    font-family: Arial,sans-serif !important;
    font-size: 19px !important; 
    font-weight: 700 !important;
    font-stretch: normal;
    letter-spacing: normal;
    line-height: 1.1851;
    color: #002677;
    margin-top: 0;
    margin-bottom: 1rem;
}

.walletPaymentMethods > div:last-of-type {
    border-bottom: 2px solid rgb(218, 219, 220);
    margin-bottom: 2.5rem;
}

.walletPaymentMethod {
    border-top: 2px solid rgb(218, 219, 220);
    display: flex;
    align-items: center;
    height: 6.25rem;
    width: 100%;
    padding-left: .75rem;
    position: relative;
}

.walletPaymentMethod > p {
    color:rgb(90, 90, 90) !important;
    margin-left: -0.75rem !important;
}

.noPaymentMethods {
    border-bottom: 2px solid rgb(218, 219, 220);
}

/* .walletPaymentMethod:last-of-type {
    border-bottom: 2px solid rgb(218, 219, 220);
} */

.walletAccount {
    display: flex;
    align-items: center;
    width: 55%;
}

.newIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(214, 230, 248);
    width: 3.25rem;
    height: 1.75rem;
}

.newIcon > p {
    font-weight: bold;
    font-size: 1rem;
    color: rgb(0, 32, 95) !important;
}

.updateIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(214, 230, 248);
    width: 5.5rem;
    height: 1.75rem;
}
.householdatacaption{
    color: #002677 !important;
    font-size: 17px !important;
    font-weight: bold !important;
    margin-bottom: 5px !important;
    text-align: left;
    margin-top: 15px;
    text-transform: capitalize;
}
.premBrkDwnBlueBgtable{
    background-color: #E5F8FB;
}
.updateIcon > p {
    font-weight: bold;
    font-size: 1rem;
    color: rgb(0, 32, 95) !important;
}

.preEffectiveBanner > p{
    max-width: 560px;
}

.walletPaymentMethod > .walletAccount > p {
    color: rgb(90, 90, 90) !important;
    margin-right: 1rem;
}

.checkingSavingMethod > p {
    width: 17%;
}

.accountIcon {
    width: 7%;
    display: flex;
    margin-right: 1.25rem;
}

.editRemoveLinks {
    width: 13%;
    display: flex;
    margin-right: 2rem;
    margin-left: 2rem;
}

.editLink {
    border-right: 1px solid rgb(90, 90, 90);
    padding-right:10px;
}

.removeLink {
    border-left: 1px solid rgb(90, 90, 90);
    padding-left:10px;
}

.recurringLabel {
    width: 25%;
}

.recurringLabel > p {
    color: rgb(51, 51, 0);
}

.expiredCardLbl {
    width: 100%;
    height: auto;
    color: rgb(90, 90, 90);
    font-size: 15px !important;
    font-family: Arial,sans-serif !important;
    font-weight: 500;
    text-align: left;
    line-height: 20px;
    margin-top: -1.5rem !important;
    margin-bottom: 2.6rem !important;
}

.editExpDateGroup > div {
    width: 100% !important
}

.expSlash {
    padding-top: 2rem;
    margin-left: 2rem; 
    margin-right: 2rem;  
    height: 30; 
    font-size: 1.5rem
}
.eftPaymentMethod{
    margin-top: 30px;
}
.eftPaymentMethod h4#new_method {
    margin: 33px 0px 18px 0px;
}
.eftPaymentMethod h4{
    color: rgb(0, 38, 119);
    font-family: Arial,sans-serif !important;
    font-size: 13.5px;
    font-weight: bold;
}
.radioBtnGroup label.tds-form__radio-button {
    padding-bottom: 10px;
}
.scheduleRadios label.tds-form__radio-button{
    padding-bottom:15px;
}
.scheduleRadiosUpdt label.tds-form__radio-button{
    padding-bottom:15px;
    margin-left: 15px;
}
.addPaymentMethod .eftForm h5.tds-header__h5{
    font-size: 15px!important;
    font-weight: 700;
    font-family: Arial,sans-serif!important;
    color: rgb(51, 51, 51);
}
.eftForm h6.tds-header__h6{
    font-size: 15px!important;
    font-weight: 700;
    font-family: Arial,sans-serif!important;   
}
.cc-frame h5{
    font-size: 16px !important;
}
.addPaymentMethod .eftForm fieldset{
    padding: 0px;
    width: 70%;
}
.manageWallet .eftForm fieldset{
    padding: 0px;
    width: 70%;
}
.manageWallet .eftForm {
    margin-bottom: 20px;
    background: #fff !important;
    padding-top: 15px;
}

.nachaErr {
    width: 85%;
}

.addPaymentMethod .eftForm .rowd-block {
    margin-bottom: 40px !important;
}
.addPaymentMethod .eftForm .mbt-med:not(:last-child) {
    margin-bottom: 40px !important;
}
.addPaymentMethod .eftForm .mbt-lg {
    margin-bottom: 30px !important;
}
p#eftNotes {
    max-width: 90%;
}

.addPaymentMethod .eftForm .formBorder{
    margin-bottom:20px;
}

.addPaymentMethod .eftForm .formBorder.eftFormWidth{
    max-width:85%
}

.addPaymentMethod .tds-accordion__header  .tds-accordion__header-title, .addPaymentMethod .tds-accordion__header button{
    font-family: Arial,sans-serif !important;
    background: none;
    border: 0px;
    padding: 0px;
    margin: 0px;
    font-size: 17px !important;
    font-weight: bold;
    color: rgb(25, 110, 207);
    
}

.bannerOverview .tds-notification__body{
    justify-content: space-between;
}

.tds-accordion__header--primary button {
    max-width: 300px;
}

.addPaymentMethod button span.expand{
    background-image: url('../../tokens/icons/acc_expand_icon.png');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    padding-left: 25px;
}
.addPaymentMethod button span.collapse{
    background-image: url('../../tokens/icons/acc_collapse_icon.png');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    padding-left: 25px;
}
.addPaymentMethod img{
    width:18px;
    height:18px;
    
}
.addPaymentMethod .tds-accordion__header-heading-wrapper{
    border-top: 0px;
    width: 45%;
}
.addPaymentMethod .tds-accordion__container:last-child{
    border-bottom: 0px
}
.addPaymentMethod .tds-accordion__header-icon{
    display: none;
}
.addPaymentMethod .tds-accordion__group {
    padding: 0px 0px !important;
}
.addPaymentMethod .tds-accordion__container--closed:last-child:hover{
    border-bottom :0px !important;
}
.addPaymentMethod .tds-accordion__header-heading-wrapper:hover {
    border-top: 0px !important;
}
.addPaymentMethod .tds-accordion__header:focus, .tds-accordion__header:hover{
       width: calc(100% + -32px); 
     padding-left: 25px; 
     padding-right: 16px; 
    margin: 0 0 0 0px !important;
    border-radius: 0px;
}
.addPaymentMethod .tds-accordion__header:before{
    background-color: #fff !important;
    background-image: url('../../tokens/icons/acc_collapse_icon.png');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    padding-left: 25px;
    transition: transform .0s !important;
}
.addPaymentMethod .tds-accordion__header--open{
    background-image: url('../../tokens/icons/acc_collapse_icon.png');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    padding-left: 25px;
}
.addPaymentMethod .tds-accordion__header--open:hover{
    background-image: url('../../tokens/icons/acc_collapse_icon.png');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    padding-left: 25px;
}
.addPaymentMethod .tds-accordion__header--closed{
    background-image: url('../../tokens/icons/acc_expand_icon.png');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    padding-left: 25px;
}
.addPaymentMethod .tds-accordion__header--closed:hover{
    background-image: url('../../tokens/icons/acc_expand_icon.png');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    padding-left: 25px;
}
.addPaymentMethod .tds-accordion__header:hover:before {
    transform: scaleX(0);
}
.addPaymentMethod .tds-accordion__header-title{
    margin: 0px !important;
}
.addPaymentMethod .tds-accordion__header{
    min-height: auto;
    margin: 0px 0px;
    padding: 0px 0px 0px 25px;
    transition: .0s;
    max-width: 100%;
    scroll-margin-top: 80px;
}
.addPaymentMethod .tds-accordion__header:focus,.addPaymentMethod .tds-accordion__header:hover{
    min-height: auto;
    margin: 0px 0px !important;
    width: 100%;
    padding: 0 0 0 25px;
    transition: 0s;
}
.addPaymentMethod .tds-accordion-content__container--open {
    animation-duration: .3s;
    animation-timing-function: ease-in-out;
    animation-name: auto;
}
.addPaymentMethod .tds-accordion-content__container{
    max-width: 100%;
}
.nachaErr.nachaCls br{
    display: none;
}
#reviewsubmit button.tds-modal__close {
    display: none;
}
.paymentAmountRadios h3, .choosePayMethod h3, .paymentSchedule h3{
    font-size:20px;
    font-family: "UHC Serif Headline SemiBold",Georgia Bold,serif !important;
}
.cancelRecSec h3{
    font-size:28.83px;
    font-family: "UHC Serif Headline SemiBold",Georgia Bold,serif !important;
}
.payment-error-box .error-list p.error-label {
    max-width: 100%;
}
/*Safari browser fix */
ol > li a.tds-breadcrumbs__link:focus {
    outline: 5px solid #6C992B !important;
    outline-offset: 4px !important;
    position:relative;
    z-index: 9999;
}

span.token-font-family-heading-three.token-color-gray-dark-base.m-0.pad5.noLineHgt {
    line-height: 20px;
    margin-top: 3px !important;
    display: block;
}

.scheduleRadiosUpdt .tds-form__input-combo .tds-form__input{
    width: 300px ;
}

@media (max-width: 767px){
    .bannerOverview .tds-notification__body{
        display: flex;
        width:100%;
        flex-direction: column;
    }
    .bannerOverview .tds-notification__body .makePaymentLink{
        margin-top:20px;
    }
    #oneTimeFed .tds-header__h3{
		margin-left: 15px; 
    }
    .paymentDetailsIpad.fedForm .tds-header__h3{
		margin-left: 2.1rem; 
    }
    .paymentDetailsIpad.fedForm .tds-header__h4{
		margin-left: 32px 
    }
    .paymentDetailsIpad.fedForm .payAmtExp{
        margin-right:10px
    }
    .paymentMethods.moreMethods button{
        margin:0px !important;
        margin-left:18px !important;
        text-align:left !important;
        width:auto !important;
    }
    .choosePayMethod{
        margin-bottom: 1.5rem;
    }
    .addPaymentMethod .tds-accordion__header-heading-wrapper{
        margin-left: 30px;
    }
    .addPaymentMethod.fedEftForm .eftPaymentMethod{
        margin-left: 30px;
    }
    .addPaymentMethod.fedEftForm h5{
        margin-left:10px;
    }
    .paymentDetails.fedEFTPage .row{ 
        display: flex;
        flex: 1 0 100%;
        flex-wrap: wrap;
        margin: 0px !important;
    }
    .paymentDetails.fedEFTPage .container{
        margin-top: 0px !important;
    }
    .paymentSchedule {
        margin-bottom: 0px !important;
    }
    .paymentAmountRadios{
        margin-top: 0px
    }
    #alertbody_desktop{
        display: block !important;
        margin: 20px;
        top: 2rem;
    }
    .scheduleRadios fieldset {
        margin-left: 35px;
    }
    .scheduleRadiosUpdt fieldset {
        margin-left: 0px;
    }
    .tds-form__input-combo .tds-form__input{
        width: 80% !important;
    }
    .datePicker > div{
        width:100%
    }
    .cancelRecSec{
        padding-top: 20px;
    }
    .fedRecNote, .cancelRecSec label{
        margin-left: 2.2rem;
        margin-right: 25px;
    }
    .scheduleRadios > form > div > fieldset > label:last-child {
        margin-bottom: 0rem !important;
      }
    .scheduleRadiosUpdt > form > div > fieldset > label:last-child {
        margin-bottom: 0rem !important;
      }
      #choose_payID h3{
          margin-top: 20px;
      }
      .addPaymentMethod .tds-accordion__header-heading-wrapper{
        width: 85%;
    }
}

@media (max-width: 979px){
    .mobileHeadContainer {
        left: 0 !important;
    }
}
    
@media only screen and (min-device-width: 768px) and (max-device-width: 1180px) and (-webkit-min-device-pixel-ratio: 2) {
	.paymentDetailsIpad.fedForm fieldset .border-bottom-xs p {
    	margin-left: 44px !important;
  }
    .paymentDetailsIpad.fedForm .oa-int-wrp {
        margin-left: 44px!important;

    }
    .paymentDetailsIpad.fedForm .tds-form__checkbox-label-text, .tds-form__radio-button-label-text {
		top : 0px !important;
    }
    .paymentDetails.fedEFTPage  .payment-error-box {
        margin: 0.6rem 0;
    }
    .sel-amt-row.oldFedForm .border-bottom-xs p, .sel-amt-row.oldFedForm .border-bottom-xs .oa-int-wrp {
        padding-left: 16px !important;
    }
}
#global-header{
    position: relative;
    z-index: 99;
}
.headingparsys + .page-header {
    display: none;
}
.bannerOverview {
    padding: 20px 0px;    
}
#successPayCancel p {
    max-width: 650px !important;
}
.payment-error-box .tds-notification__body p {
    margin-left: 0px !important;
}
.payment-error-box .tds-notification__body div.error-list{
    padding-left:0rem;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1180px) and (-webkit-min-device-pixel-ratio: 2) {
    .paymentterm button span{
        width: 55%;
    }
    .paymentterm button svg{
        position: relative;
        bottom:-45px;
    }
    .historyHeading.flexitems{
        width: 45%;
    }
    .printDownload.rmhpUi{
        width: 55%
    }
    .processedTab{
        min-width: 7rem !important;
    }
    .pendingTab{
        margin-right: 1.5rem !important;
        min-width: 7rem !important;
    }
    .exportLinks{
        margin-right: 0rem;
    }

    .noDataRmhpLink>div .rmhpLink>div {
        margin-top: 11px;
        text-align: right;
    }
    .rmhpLink>div {
        text-align: right;
    }
    .dateSelector.rmhpDateSelector{
        margin-right: 0rem;
        margin-top: 1.5rem;
    }
    .rmhpLink .rmhpNameLink{
        width: 58%;
        margin-left: auto;
        text-align: center !important;
        display: block;
    }
    .noDataRmhpLink .rmhpNameLink{
        width: 60%;
        margin-left: auto;
        text-align: center !important;
        display: block;
    }
    .noDataRmhpLink .tds-popup-tip, .rmhpLink .tds-popup-tip{
        top: -2px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .dateSelectorLabel{
        margin-left: 5.7rem;
        margin-bottom: 0px !important;
    }
    .noHistoryHeader>.noDataDateSelector>label {
        margin-left: 5.2rem;
        margin-bottom: 0px !important;
    }
  }

.paymentterm .tds-modal__content .tds-text__paragraph div h3:not(:first-child) {
    margin-top: 20px !important;
}
.paymentterm .tds-modal__content .tds-text__paragraph div h3:first-child {
    margin-top: 0px !important;
}   
.processedContent{
    margin-left: 2.5rem;
    margin-bottom:2rem;
}

.isbContainer {
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.mwError p {
    max-width: max-content;
}
.mwError button.tds-notification__dismiss-button {
    display: none;
}