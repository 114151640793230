.App {
    font-family: sans-serif !important;
    text-align: center !important;
  }

  .mymodal {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 35% !important;
    border: 1px solid #ccc !important;
    background: #fff !important;
    border-radius: 8px !important;
    outline: none !important;
    padding: 20px !important;
    max-height: 80vh !important;
  }
  
  .mymodal-min {
    height: auto  !important;
    width: 420px !important;
  }
    
  .nachaErr-modal {
    width: 34% !important;
  }
  
  .myoverlay {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    background-color: rgba(0, 0, 0, 0.65) !important;
  }

  .ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden !important;
}
  
  .ReactModal__Overlay {
    opacity: 0 !important;
    transition: opacity 500ms ease-in-out !important;
    height: 100vh !important;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1 !important;
  }
  
  .ReactModal__Overlay--before-close {
    opacity: 0 !important;
  }
  
  .mymodalsession {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 30% !important;
    background: #fff !important;
    border-radius: 8px !important;
    outline: none !important;
    padding: 20px !important;
    max-height: 80vh !important;
  }
  .mymodalsession h2{
    font-family: "UHC Serif Headline SemiBold",Georgia Bold,serif;
    font-weight: bold;
    font-size: 28.83px;
    margin-top:0px !important;
    margin-bottom:10px !important;
    color:#002677;
  }
  .mymodalsession p{
    font-family: Arial,sans-serif;
    font-size: 15px;
    font-stretch: normal;
    letter-spacing: normal;
    line-height: 1.4;
    color: #333 !important;
  }
  .mymodalsession-min {
    height: auto  !important;
    width: 551px !important;
  }
  @media (max-width: 767px) {
    .mymodalsession-min {
      width: 90% !important;
    }
  }