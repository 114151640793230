/*#region help me find my Id css section */


.modal-header-container
{
  width: 100% !important;
  /* border-bottom: 1px solid rgb(224, 224, 224) !important; */
}

.header-close-circle-btn {
  align-self: flex-start !important;
    cursor: pointer !important;
    position: relative !important;
    outline: none !important;
    border: 0px none !important;
    display: inline-block !important;
    overflow: hidden !important;
    background: none !important;
    border-radius: 50% !important;
}

.model-content-p {
  font-family: Arial,sans-serif  !important;
  color: #000000 !important;
}

.header-close-x-btn {
  align-self: flex-start !important;
    cursor: pointer !important;
    position: relative !important;
    outline: none !important;
    border: 0px none !important;
    display: inline-block !important;
    overflow: hidden !important;
    background: none !important;
}

.modal-header {
  width: 100% !important;
   padding: 20px !important;
    margin: auto !important;
    display: flex !important;
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: center !important;
    align-items: center !important;
    position: relative !important;
}

.header-title {
  flex: 1 1 0% !important;
    padding-right: 15px !important;
    margin: 0px !important;
    line-height: 24px !important;
    color: rgb(0, 0, 0) !important;
  font-family: Arial,sans-serif !important;
  font-size:27px !important;
  font-weight: 600 !important;
}

.modal-content-container {
  width: 100% !important;
    margin: 0px auto !important;
    /* padding: 20px !important; */
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
    overflow-y: auto !important;
    max-height: 50vh !important;
    line-height: 24px
}

.modal-content-container > p > div > p {
  font-family: Arial,sans-serif;
}

.modal-content-label {
  font-family: Arial,sans-serif !important;
  display: block !important;
  font-weight: 600 !important;
  margin: 0px 0px 0.5rem !important;
  line-height: 1 !important;
  color: rgb(45, 45, 57) !important;
}

.modal-footer-container{
  margin: 30px 0 20px!important;
  padding: 0 6px!important;
}

/**********************************************/

.hmfi-main-header{
  background: rgb(255, 255, 255) !important;
  border-radius: 0px !important;
  border-bottom: 1px solid rgb(225, 225, 225) !important;
  height: 100% !important;
  width: 100% !important;
  display: flex !important;
  margin: auto !important;
  padding: 20px !important;
  justify-content: space-between  !important;
  align-items: center  !important;
}
.hmfi-header{
  color: rgb(0, 0, 0) !important;
  font-family: Arial,sans-serif !important;
  font-size:27px !important;
  font-weight: 600 !important;
  /* margin-right: 52px !important; */
}
.hmfi-close{
  background: #fff !important;
  /* height: 19.56px !important;
  width: 19.56px !important; */
  text-align: center !important;
  font-weight: bold !important;
  color: blue !important;
  border-color: white !important;
  border-width: 0 !important;
  
}
.hmfi-body{
  background: rgb(255, 255, 255) !important;
  border-radius: 0px 0px 5px 5px !important;
 /* border: 1px solid rgb(219, 219, 219) !important;*/
  /* height: 435px !important;
  width: 449px !important; */
  margin: auto !important;
}
.hmfi-img-fluid{
  padding: 10px 0px 0px 14px !important;
  width: 95% !important;
}
.hmfi-p{
  padding: 16px !important;
  padding-bottom: 0px  !important;
  margin-bottom: 0px  !important;
}

/*#endregion help me find my Id css section */

@media (max-width:768px) {
  /*#region help me find my Id css section */
  .hmfi-main-header{
    padding: 30px 10px 30px 30px !important;
  }
  .hmfi-header{
    margin-right: 52% !important;
  }

  .hmfi-p{
    padding: 35px !important;
  }
  .hmfi-close{
    margin-top: 0px !important;
  }
  /*#endregion */
}

@media only screen and (max-width: 500px){
   /*#region help me find my Id css section */
  .hmfi-p{
    padding: 28px !important;
  }
  .hmfi-header{
    margin-right: 10% !important;
  }
  .hmfi-main-header{
    padding: 30px 10px 30px 10px !important;
  }
  .hmfi-close{
    margin-top: 0px !important;
  }
  /*#endregion */
}