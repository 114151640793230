.page-header {
    width: 100%;
    background: rgb(251, 252, 254);
    border: 1px solid rgb(216, 216, 216);
    border-radius: 0px;
    padding: 40px 0px;
    overflow: auto;
}

.tds-breadcrumbs__link {
    font-family: Arial, sans-serif !important;
    font-weight: bold;
    color: #196ecf;
    text-decoration: none;
    position: relative;
    text-transform: initial !important;
    font-size: 15px !important;
}

.memberId {
    color: rgb(0, 38, 119);
    font-size: 14px;
    /*font-family: UHC2020Sans-Bold;*/
    font-weight: bold;
    text-align: center;
}

.memberIdCont {
    color: rgb(90, 90, 90);
    font-size: 14px;
    /*font-family: UHC2020Sans-SemiBold;*/
    font-weight: 400;
}

.memberIdContNum {
    color: #333;
    font-size: 14px;
    /*font-family: UHC2020Sans-Bold;*/
    font-weight: bold;
}
.page-header-title {
    font-size: 36px;
    font-family: UHCSerifHeadline-Semibold;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom:30px;
    line-height: 30px;
    color: #002677;
}

.page-header-plan {
    color: #002677;
    font-size: 15.96px !important;
    font-family: Arial,sans-serif !important;
    font-weight: bold !important;
    margin-top: 10px;
    line-height: 20.35px !important;
}
