.banner-back-btn {
    width: 40px  !important;
    height: 30px  !important;
}
.change-pay-mobile{
    display: none;
}
#TopAlertError button.tds-notification__dismiss-button {
    display: none !important;
}
.errorBanner.system-error > button {
    display: flex !important;
}
.errorBanner.system-error br {
    display: none;
}
.uhc-review-labels ul,  ul.row.mt-5 {
    margin: 0px;
    padding: 0px;
    padding-left: 0px !important;
}
.total-padding span.tds-header__h3 {
    font-family: Arial,sans-serif !important;
}
span#f-group_color-012_error-message{
    font-size: 14px !important
}
#electronicText h4{
    font-family: Arial,sans-serif !important;
    font-size: 23px !important;
}
.uhc-review-labels ul li, ul.row.mt-5 li {
    display: block;
    padding-left: 0px;
    font-size: 16px;
    padding-bottom: 10px !important;
}
#reviewpage .tds-typography__link {
    text-decoration : underline  !important;
}

#reviewpage .tds-header__h3, .tds-header__h2 {
    color: rgb(0, 38, 119) !important;
    margin: 0px 0px 8px 0px !important;
    text-transform: initial;
}

.tds-typography__paragraph{
    color: rgb(51, 51, 51) !important;
}

.tds-typography__paragraph.dueAmt, .tds-text__paragraph.dueAmt{
    color: rgb(0, 38, 119) !important;
}

.paydetailsContent span{
    display: block;

}

.linkBtn img{
    width:21px;
    height:21px;
    margin-right:5px;
}
.tds-card__content .row.mt-4 {
    margin-top: 0.5rem !important;
    font-size:1rem !important;
}
#reviewpage .tds-button {
    text-transform: none !important;
}

#headerReview {
    display: flex !important;
    justify-content: space-between !important;
}

.payment-detail-card-header{
    align-items:baseline !important;
}
.payment-details-card [class*=col-] h3, p {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.payment-details-card {
    margin-top: 30px;
}
.tempoGrey {
    background-color: #f4f4f4 !important;
}

.confirm-pay-button[disabled] {
    opacity: 0.65 !important;
    cursor: not-allowed !important;
}

.tds-margin-xsm-bottom-only{
  padding-bottom: 15px;
}

#reviewpage .tds-card__content ul{
  padding-left: 22px;
}

/* #reviewpage .tds-card__content ul li {
    text-indent: -5px;
} */

.justify-content-between {
  justify-content: initial !important;
}

#reviewpage .tds-card__content {
    padding: 15px !important;
}

.tds-card.tds-card--primary > .tds-card__content {
    padding: 20px 20px !important;
}

#reviewpage .uhc-margin-top-bottom {
    margin-top: 60px;
    margin-bottom: 35px;
}

h4.tds-header__h4.mt-3 + div ul{
    margin-top:0px !important
}

#reviewpage .uhc-item-right-align{
    display:flex;
    justify-content: center;
}

.uhc-review-labels .row:not(:only-of-type)>[class*=col-] {
    margin-bottom: 0px !important;
}

#reviewpage .tds-modal__footer button:last-child{
    margin-left:10px !important;
}

.uhc-margin-bottom-40{
    margin-bottom:40px;
}

#reviewpage ul li::marker {
    font-size: 120%;
 }

 .pt-4.token-color-gray-dark-text-bold p{
     font-weight: bold;
 }

#reviewpage .row {
    display: flex;
    flex: 1 0 100%;
    flex-wrap: wrap;
    margin-left: 0px !important;
    margin-right: 0px !important;
    font-size: 16px;
}
.tds-bradius-bbtmnone{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom:0px !important
}
.tds-tradius-topbordernone{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-top:0px !important;
    margin-bottom:70px;
}
#reviewpage p a{
    text-transform: capitalize;
    font-weight: 400 !important;
}
#reviewpage .tds-modal__content .tds-typography__paragraph a {
    text-transform: lowercase;
}

.fedSavePayment {
    margin-left: 25%;
}

.superUserError.cc {
    margin-top: -3rem;
  }

.paymentTotalAmt.fedtotal{
    font-size:36px !important;
}

.paymentTotalAmt.reviewTtl {
    font-size: 36px !important;
}

@media (min-width: 768px){
    .total-padding {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }

    .paydetailsContent .mt2 {
        margin-top: 1rem;
    }

    .sel-amt-row > div > label > span {
        top: 2px;
      }

    .uhc-flex > svg {
        width: 2rem;
    }
  }

  @media only screen and (min-device-width: 321px) and (max-device-width: 375px) {
    .uhc-content_height {
        max-height: 180px;
      }
  }
  @media only screen and (max-device-width: 320px) {
    .uhc-content_height {
        max-height: 160px;
      }
      h2#modal-100 {
        font-size: 20px !important;
      }
  }

  @media only screen and (min-device-width: 992px) {
    #reviewpage .tds-form__checkbox span.tds-form__checkbox-label-text {
        top: -3px;
    }
  }

@media only screen and (max-width: 768px) {
    div#TopAlertError{
        margin: 20px 20px 0px;
    }
    h2#modal-100 {
        font-size: 24px !important;
    }
    .uhc-tile-brd-wdgt-payment{
        margin-top :0px !important;
        background-color: #fff !important;
    }
    .payment-details-card {
        margin-top: 0px !important;
    }
    .manage-buttons button{
     width: 100%;
     margin: 0px;
   }
   .manage-buttons #cancelBtn{
     margin-left:0px !important;
   }
   .uhc-review-labels .row .col-3, .uhc-review-labels .row .col-9, .uhc-review-labels .row .uhc-plan-mobile-row, .uhc-review-labels + .row.mt-5 .col-3, .uhc-review-labels + .row.mt-5 .col-9{
        flex: 0 0 50% !important;
        max-width: 50% !important;
   }
   .uhc-tile-brd-wdgt-payment .mt-6{
       margin-top: 10px!important;
   }
   .uhc-confirm-btn{
       margin-bottom:30px !important;
   }
   #reviewpage .tds-modal__footer button:last-child {
    margin-left: 0px!important;
    }

    #reviewpage .row.mt-5 li.col-3, .uhc-review-labels .row .col-3, .uhc-review-labels .row .uhc-plan-mobile-row {
        flex: 0 0 33% !important;
        max-width: 33% !important;
    }

    #reviewpage .row.mt-5 li.col-9, .uhc-review-labels .row .col-9 {
        flex: 0 0 66% !important;
        max-width: 66% !important;
    }
}

  @media only screen and (min-device-width: 321px) and (max-device-width: 411px){
    .uhc-content_height {
        max-height: 300px;
      }
  }

  @media screen and (min-device-width: 385px) {
    .fedSavePayment > fieldset > label > svg {
        margin-top: -6px !important;
      }
  }

  @media screen and (max-width: 819px) {
    span.paymentTotalAmt.fedtotal {
        margin-left: 0px !important;
    }
    p.paymentTotalAmt.fedtotal {
        margin-left: 0px !important;
    }
  }

  

  @media (max-width: 469px){
    /* .fedSavePayment {
        margin-left: 40% !important;
    } */
    #reviewpage .row.mt-5 li.col-3 {
        flex: 0 0 40%;
        max-width: 40%;
    }
    #reviewpage .row.mt-5 li.col-9 {
        flex: 0 0 55%;
        max-width: 55%;
        text-align: left !important;
    }
    .totalLabel {
        margin-top: 0 !important;
    }

    .fedSavePayment > fieldset > label > span {
        padding-top: 0 !important;
    }

    .fedSavePayment {
        margin-left: 0 !important;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 819px) and (-webkit-min-device-pixel-ratio: 2) {
    .fedSavePayment {
        margin-left: 24% !important;
    }

    .fedSavePayment > fieldSet > label {
        margin-left: 8rem !important;
    }

    .fedSavePayment > fieldSet > label > svg {
        margin-top: -22px !important
    }

    #reviewpage .row.mt-5 li.col-3 {
        flex: 0 0 30% !important;
        max-width: 30% !important;
    }
    #reviewpage .row.mt-5 li.col-9 {
        flex: 0 0 60%;
        max-width: 60%;
        text-align: left !important;
    }
    span.paymentTotalAmt.fedtotal {
        margin-left: 0px !important;
    }
    p.paymentTotalAmt.fedtotal {
        margin-left: 0px !important;
    }

   
  }

  @media only screen and (min-device-width: 769px) and (max-device-width: 819px) and (-webkit-min-device-pixel-ratio: 2) {
    .paymentTotalAmt {
        margin-left: 7.5rem !important;
    }
  }

  @media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (-webkit-min-device-pixel-ratio: 2) {
    .fedSavePayment {
        margin-left: 24% !important;
    }

    .fedSavePayment > fieldSet > label {
        margin-left: 0 !important;
    }

    .fedSavePayment > fieldSet > label > svg {
        margin-top: -10px !important;
        margin-left:20px;
    }

    #reviewpage .row.mt-5 li.col-3 {
        flex: 0 0 25%;
        max-width: 30%;
    }
    #reviewpage .row.mt-5 li.col-9 {
        flex: 0 0 60%;
        max-width: 60%;
        text-align: left !important;
    }
  }

  @media (max-width: 767px){
    button.tds-modal__close, button.tds-modal__close{
        width: 44px !important;
        margin-top: 10px;
      }
      #reviewpage button.tds-modal__close, #reviewpage button.tds-modal__close{
        padding-top: 26px;
        padding-left: 18px;
      }

      .fedSavePayment > fieldset > label > svg {
        margin-left: 0px !important;
      }

      span.paymentTotalAmt.fedtotal {
        margin-left: 2rem !important;
      }
  }

  @media (max-width: 469px){
    .uhc-review-labels > .row.mt-4 > li:first-child, .uhc-review-labels > .row > li:first-child,  .row.mt-5 > li.col-3  {
        flex: 0 0 45% !important;
        max-width: 45% !important;
    }

    .uhc-review-labels > .row.mt-4 > li:last-child, .uhc-review-labels > .row > li:last-child, .row.mt-5 > li.col-9, .uhc-review-labels > .row > .uhc-plan-mobile-row {
        flex: 0 0 55% !important;
        max-width: 55% !important;
    }

    .totalLabel {
        margin-top: 0 !important;
    }

    .fedSavePayment > fieldset > label > span {
        padding-top: 0 !important;
    }

    .fedSavePayment {
        margin-left: 0 !important;
    }
  }
 
  @media (min-width: 391px) and (max-width: 414px) { 
    #reviewpage .row.mt-5 li.col-3, ul.row > li.col-9, .row.mt-5 > li.col-3, .uhc-review-labels > .row.mt-4 > li:first-child, .uhc-review-labels > .row > li:first-child {
        flex: 0 0 40% !important;
        max-width: 40% !important;
    }

    #reviewpage .row.mt-5 li.col-9, .row.mt-5 > li.col-9, .uhc-review-labels > .row.mt-4 > li:last-child {
        flex: 0 0 55% !important;
        max-width: 55% !important;
    }

    .uhc-review-labels > .row > .uhc-plan-mobile-row {
        flex: 0 0 60% !important;
        max-width: 60% !important;
    }

    ul.row.mt-5 li.col-9 span {
        margin-left: 0 !important;
    }
}
  @media (max-width: 390px) {
    ul.row.mt-5 li.col-9  span {
        margin-left: 0 !important;
    }

    #reviewpage .row.mt-5 li.col-3, ul.row > li.col-9, .row.mt-5 > li.col-3  {
        flex: 0 0 40% !important;
        max-width: 40% !important;
    }

    #reviewpage .row.mt-5 li.col-9, .row.mt-5 > li.col-9, .uhc-review-labels > .row > .uhc-plan-mobile-row {
        flex: 0 0 55% !important;
        max-width: 55% !important;
    }
  }

  @media (max-width: 400px) and (-webkit-min-device-pixel-ratio: 2) {
    .uhc-review-labels + div[aria-live="polite"] .row.mt-5 .col-9{
      flex: 0 0 60% !important;
      max-width: 60% !important;
    }

    span.paymentTotalAmt.fedtotal {
        margin-left: 1rem !important;
    }
  }

 
