.title-header {
    display: flex;
    /* grid-template-columns: 70% 30%;
    grid-template-rows: 1fr; */
    flex-wrap: wrap;
    margin: 0 1.4rem;
}

.planName > p {
    margin-left: 0rem !important;
    font-weight: bold;
    font-size: .8rem;
}

.tds-card.tds-card--primary > .tds-card__content{
    padding: 20px 0px !important;
}

.planName {
    width: 70%;
    justify-items: center;
    margin-top: 1.3rem;
}

.payInfo {
    display: flex;
    align-items:flex-end;
    flex-direction: column;
    margin-top: 2.3rem;
    width: 30%;
    flex-wrap: wrap;
}

.pastDue {
    /* visibility: hidden; */
    grid-row: 1/3;
    align-items: center;
    background-color: rgb(254, 249, 234);
    height: 4.5rem;
    width: 100%;
}

.pastNotice {
    display: none;
    align-items: center;
    justify-items: flex-start;
    grid-column: 1/2;
    grid-row: 2/3;
    width: 100%;
    background-color: rgb(254, 249, 234);
    height: 4.5rem;
}

.pastDueBanner {
    padding:0px;
    width: 100%;
    /* grid-column: 1/ span 2; */
    display: flex;
}

.dueBanner {
    background-color: rgb(254, 249, 234);
    padding: 6px;
    width: 100%;
    /* grid-column: 1/ span 2; */
    flex-wrap: wrap;
    order: 1;
}

.tds-notification__dismiss-button {
  justify-content: center;
  text-indent: -99999px;
  align-items: center;
  display: flex;
  overflow: hidden;
  background: none;
  position: absolute !important;
  box-sizing: border-box;
}

.pastDueBanner p {
    max-width: 100% !important;
}

.pastDueBanner > p{
    padding:2px 8px;
    flex-grow: 1;
}

.pastDueBanner > a{
    margin-right: 80px;
    text-transform: initial;
}

.pastDueBanner > img {
    width:23px;
    height: 23px;
}

.pastDueBanner > button{
    background:none;
    border:0px;
    padding:0px;
    cursor: pointer;
}
.makePayment {
    display: none;
    justify-content:center;
    align-items: center;
    grid-row: 2/3;
    grid-column: 2/3;
    width: 100%;
    background-color: rgb(254, 249, 234);
    /* height: 4.5rem; */
}

.tds-link {
    line-height: 1.25rem;
}

.tds-popup-tip_button {
    background-color: rgb(0, 38, 119);
    border: 1px solid rgb(0, 38, 119);
}

.monthlyPremium{
    display: flex;
}

.monthlyPremium .monthlyPremiumAmt{
    font-family: Arial,sans-serif !important;
    font-size: 1.25rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.3;
    color: #002677;
}

.monthlyPremium > span{
    font-size:20px;
}
.monthlyPremium > button{
    background: none;
    border:0px;
    margin:0px;
    padding: 0px;
    
}
.uhc-question-icon > button{
    background: none;
    border:0px;
    margin:0px;
    padding: 0px;
    position: relative;
    top: -2px;
    left: -5px;
}
.monthlyPremAmt{
    font-family: "UHC Serif Semibold",Georgia Bold,serif;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    
}
.monthlyPremium .badge, .uhc-question-icon .badge{
    display: inline-block;
    min-width: 10px;
    height: 16px;
    padding: 1px 3.5px;
    font-size: 12px;
    font-weight: bold;
    color: #196ecf;
    line-height: 1;
    vertical-align: middle;
    white-space: nowrap;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #196ecf !important;
    margin-left: 5px;
    margin-top: 2px;
    cursor: pointer;
  }

.m3pBanner > div > div > .tds-notification__icon {
    margin-top: .5rem;
}

.m3p-banner-content {
    display: flex;
    flex-wrap: wrap;
    width: 72%;
    align-items: center;
    margin-bottom: 1rem;
  }

  .m3p-banner-content > h1 {
    font-size: 1.601rem;
    line-height: 2rem;
  }

  @media (max-width: 430px){
    .m3p-banner-content {
        width: 90%;
    }

    .m3pBanner > div > div > .tds-notification__body > button {
        margin-left: 3rem;
    }
  }
  @media (max-width: 390px){
    .m3pBanner > div > div > .tds-notification__body > button {
        margin-left: 1.5rem;
    }
}