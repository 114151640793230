.ssa-rrb-container {
    width: 54%;
  }
 
  .ssa-rrb-sum {
    display: flex;
    width: 100%;
  }

  .ssa-rrb-header {
      margin-top: 3.6rem;
      margin-bottom: 3rem;
      width: 100%;
  }

  .ssa-rrb-content {
      margin-left: .5rem;
  }

  .ssa-rrb-check {
      margin-top: 1.8rem;
  }

  .paymentExplanation {
      margin-top: 2.25rem;
  }

  .ssa-rrb-btnGrp {
      display: flex;
      margin-top: 3.6rem;
  }

  .ssa-rrb-btnGrp > a:last-of-type {
    border-left: 2px solid rgb(0, 38, 119) !important;
  }

  .superUserErr {
    margin-top: 1.5rem;
  }

  .superUserErr > button {
    display: none;
  }

  @media (min-width: 1200px) {
    .container {
      width: 1224px !important;
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
    
  }

  @media (max-width: 767px) {
    .ssa-rrb-container {
      width: 100%;
      padding-left: 1.75rem !important;
      padding-right: 2.6rem !important;
    }

    .container {
      padding-left: 1.75rem;
      padding-right: 2.5rem;
    }

    .paymentExplanation {
      margin-top: 1.75rem;
    }

    .ssa-rrb-header {
      margin-bottom: 1.75rem;
      margin-top: 1.75rem !important
    }
    
    .ssa-rrb-content > div:first-of-type {
      width: 100%;
    }

    .ssa-rrb-btnGrp {
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom:20px;
    }

    .ssa-rrb-btnGrp > button:first-of-type {
      margin-bottom: 1.5rem;
    }

    .ssa-rrb-btnGrp > button {
      width: 90%;
    }

    .ssa-rrb-check > form > div > fieldset > label {
      height: max-content !important;
    }
  }

  @media (max-width: 429px) {
    .ssa-cancel-btn_grp {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }

    .ssa-cancel-btn_grp > button {
      width: 100%;
    }

    .ssa-cancel-btn_grp > button:first-of-type {
      margin-bottom: 1.25rem;
    }
  }