.primary-btn {
    color: #ffffff  !important;
}
.uhc-receipt--total-label p.tds-header__h3.token-font-family-heading-three.pad5 {
  font-family: Arial,sans-serif !important;
  font-size: 22px !important;
}
.receipt-header-container{
  margin-bottom: 1rem!important;
}
.receipt-title {
  margin-bottom: 0px!important;
}
.receipt-row-container [class*=col-] {
  margin-bottom: 0px!important;
}
.hr-separator-solid {
  border-top: 1px solid rgb(219, 219, 219);
  width: 104px;
}
.external-link-button svg {
    margin-left: 6px;
    padding: 0px !important;
}
.uhc-receipt--total-label .row.mt-2 .col-5 {
  padding-left: 0px;
}

@media only screen and (max-width: 500px){
  .full-width-btn {
    width: 100%
  }
}

@media only screen and (min-width: 1024px){
  .electronic-signature-div {
    margin-right: 5%
  }
}

#printBtn {
  background-color: white;
  border-width: 0;
}
.padding-0 {
  padding-right:0 !important;
  padding-left:0 !important;
}

.border-none {
  border: none !important;
}

.markup-margin {
  margin: 10px 0px !important;
}

.receipt-heading {
  font-size: 15px !important;
  font-family: Arial,sans-serif  !important;
  font-weight: 500 !important;
}

.payment-summary-t h3{
  margin-bottom: .3rem;
}

.payment-receipt-card [class*=col-] h3,p {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  font-size:16px;
  font-weight: normal;
}

.payment-confirm-due-box{
  width: 100%;
  height: max-content;
  background: rgb(229, 248, 251);
  border-radius: 0px;
  }

.payment-confirm-due-box-text-header{
width: 498px;
height: 86.4px;
color: rgb(9, 53, 147);
font-size: 36px;
font-family: UHCSerifHeadline-Semibold;
font-weight: 600;
line-height: 43.2px;
}

.payment-confirm-due-box-padding {
padding-top: 5px;
padding-bottom: 25px;
padding-left: 25px;
padding-right: 25px;
}

.payment-confirm-esign-box{
width: 100%;
height: max-content;
background: rgb(251, 251, 251);
box-shadow: 0px 1px 0px 0px rgb(216, 216, 216);
border-radius: 0px;
padding-top: 20px;
padding-bottom: 20px;
overflow: hidden;
}

.tds-typography__paragraph_bold{
  color: rgb(51, 51, 51) !important;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
}
button.tds-link-btn{
    text-decoration: none !important;
    line-height: 1.4;
    font-family: Arial,sans-serif;
    color: #196ecf;
    display: inline-block;
    background: 0px;
    border: 0px;
    font-size: 15px;
    padding: 0px;
    font-weight: 400 !important;
    cursor: pointer;
}
.payment-summary-cont-confirm {
  padding: 0px 65px 34px 65px;
}
.uhc-receipt-label .row:not(:only-of-type)>[class*=col-] {
  margin-bottom: 0px !important;
}
.uhc-receipt-label ul, ul.row.mt-4, .uhc-receipt--total-label ul{
  margin: 0px;
  padding: 0px;
  padding-left: 0px !important;
}
.uhc-receipt-label ul li, ul.row.mt-4 li, .uhc-receipt--total-label ul li{
  display: block;
  padding-left: 0px;
  font-size: 16px;
}
button.tds-link-btn:hover{
  text-decoration: underline !important;
}

#confirmationpage .tds-button{
  text-transform: none !important;
}
.uhc-hide{
  display:none !important;
}
p.uhc-sans-medium.token-color-gray-dark-base.m-0.pad5.labelPaddingTop p{
	font-weight: 600 !important;
} 
@media (min-width: 1200px){
  .uhc-container-width{
    max-width: 1244px !important;
  }
}
@media (max-width: 768px){
  #confirmationpage .tds-button{
    height:auto;
  }
  .payment-summary-cont-confirm{
    padding:0px 30px 34px 30px !important;
  }
  .uhc-receipt-label {
    margin-top:0px !important;
  }
  .uhc-btn-hide{
    display: none !important;
  }
  .uhc-tile-brd-wdgt.uhc-margin-bottom-40 {
		padding: 20px;
  }
  .print-col{
    padding:0px !important;
  }
}

/*iPad Air Media Queries (In terms of Tablet only)*/
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .col-md-5{
    flex:auto !important;
  }
 }

 .savedText{
  color: rgb(51, 51, 51);
  font-size: 16px;
  font-weight: 500;
  font-style: italic !important;
}
.ssatextalgn{
  text-align: center;
}

.d-print-none.row.mt-3 button, #seeMoreWaysAtdd a {
  font-weight: 900 !important;
}
