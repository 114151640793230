.payment-receipt-title-txt{
    color: rgb(0, 38, 119);
    font-family: UHC2020Sans-Bold;
    font-size: 20px;
    font-weight: bold;
    height: 26px;
    line-height: 26px;
    width: 152px;

}

.payment-receipt-bottom-box{
height: max-content;
max-width: 100%;
background: rgb(250, 252, 255);
border-radius: 0px 0px 8px 8px;
padding: 25px 25px;
}

.payment-receipt-bottom-box-top-line{
    width: 100%;
    height: 1px;
    border: 1px solid rgb(216, 216, 216);
    }

#payment-receipt-box {
    background: #fff;
    border: 1px solid #d8d8d8;
    box-shadow: 0 2px 8px 0 rgb(161 199 211 / 15%);
    border-radius: 8px;
    margin: 0px auto;
    height: max-content;
    max-width: 100%; 
} 
#payment-receipt-box-mt {
    background: #fff;
    border: 1px solid #d8d8d8;
    box-shadow: 0 2px 8px 0 rgb(161 199 211 / 15%);
    border-radius: 8px;
    margin: 0px auto;
    height: max-content;
    max-width: 100%;
    margin-top: 40px;
} 
.uhc-print-link{
    display:flex;
    justify-content: flex-end;
}

.payment-receipt-viewprint-pdf-text{
vertical-align: top;
}
.uhc-receipt-label.noMarginTop {
    margin-top: 10px;
}
.uhc-receipt-label{
    margin-top:50px;
    font-size:16px;
}
.uhc-receipt--total-label{
    margin-top:0px;
    font-size:16px;
}

@media (min-width: 768px) {
    .uhc-receipt-label > div > ul > li:first-child() {
        width: 40%;
    }

    .uhc-receipt-label > div > ul {
        width: 100%;
        display: inline-block;
    }
    .uhc-receipt-label > div > ul > li {
        display: inline-block;
    }
}