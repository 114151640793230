.tabs {
    margin: 1.5rem 1.5rem;
    border-bottom: 2px solid rgb(218, 219, 220);
}

.tds-tab__tab-button > span {
    font-weight: bold;
} 

/* .tabs > button {
    font-family: "UHC Sans",Arial,sans-serif;
    margin: 0px 20px;
    font-size: 18px;
    padding:0px;
    border:0px;
    background:none;
    cursor:pointer;
    text-transform: capitalize;
} */

.tabs > button.active {
    color: black;
    border-bottom: 3px solid #000;
    padding-bottom: 7px;
    font-weight:bold;
}

.paymentterm button{
    display: flex;
    justify-content: center;
}
.paymentterm.rmhplanlink{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.paymentterm button > span:first-of-type:hover {
    text-decoration: underline;
}

.tds-link--disabled{
    font-size: 15px !important;
    cursor:not-allowed !important;
    color:rgb(111, 111, 111) !important;
    font-weight: 400 !important;
    text-decoration: none;
    line-height: 1.4;
    font-family: Arial,sans-serif !important;
    display: inline-block;
}

.paymentterm button > img{
    width:24px;
    height:24px;
    margin-right:10px;
}

.accordionBtn {
    cursor: pointer;
    display:flex;
}

.accordionBtn span{
    vertical-align: top;
}

.rotateAccordionArrow {
    transform: rotate(180deg);
    position: relative;
}

.accordionContent {
    max-height: initial;
    overflow: hidden;
    transition: max-height .5s;
}

.hideAccordionContent {
    overflow: hidden;
    max-height: 0px;
    transition: max-height .5s;
    scroll-behavior: smooth;
}
.billingHistoryHeader {
    display: flex;
    flex-wrap: wrap;
    margin-left: 2.5rem;
    margin-right: .7rem;
    margin-top: 3rem;
}

.billinghistorycontent{
    margin-top: 10px;
    width: 92%;
}

.billinghistorycontent p{
    max-width:100%
}

.paymentHistoryHeader {
    display: flex;
    flex-wrap: wrap;
    margin-left: 2.5rem;
    margin-right: .7rem;
    margin-top: 3rem;
    margin-bottom: 0;
}

.paymentHistoryHeader.pendingProcessed {
    margin-bottom: 0rem;
}

.printDownload {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 10rem;
    width: 40%;
}
.printDownload.rmhpUi{
    width: 45%;
}
.mobilePrintDownload {
    display: none;
}

.printDownload > a {
    margin: 0 0.5rem !important;
    height:25px; 
    display:flex;
}

.printDownload > a > img{
    width: 24px !important;
    height: 24px !important;
    margin: 0px 4px;
}


.printDownload > a:first-child {
   margin-right:10px;
}

.printDownload > img{
    width: 24px;
    height: 24px;
}
.paymentDesc > a{
    display: flex;
    margin: 25px 0px;
}
.paymentDesc img{
    width:24px;
    height:24px;
    margin-right:5px;
}

.exportLinks {
    display: flex;
    margin-right: 2.3rem;
    justify-content: flex-end;
}

.exportLabel.downloa_CSV{
    margin-left: 40px;
}

.exportLabel {
    display: flex;
    justify-content: flex-end;
    margin-left: .25rem;
}

.exportLabel a {
    display: flex;
}

.exportLabel button{
    padding:0px;
    border:0px;
    background:none;
    display: flex;
    cursor: pointer;
}

.exportLabel button:hover{
    text-decoration: underline;
}

.printIcon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/* .tds-form__select-control {
    padding-left: .5rem !important;
} */

.dateSelector {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: flex-end;
    margin-right: 2.4rem;
}
.dateSelector.rmhpDateSelector{
    margin-top: 1rem;
}

.rmhpLink{
    margin-right: 1.5rem;
    margin-top: 3rem;

}
.noDataRmhpLink{
    padding-left: 1.5rem;
    margin-top: 5rem;  
}
.rmhpLink > div, .noDataRmhpLink > div{
    margin-top: 79px;
    text-align: right;
}
.rmhpLink .tippy-content, .noDataRmhpLink .tippy-content{
    text-align: initial;
}
.rmhpLink .tds-margin-sm-bottom, .noDataRmhpLink .tds-margin-sm-bottom{
    margin-bottom: 26px 
}
.rmhpLink a, .noDataRmhpLink a{
    font-weight: 700 !important;
    margin-right:30px;
}
.rmhpLink a img, .noDataRmhpLink a img{
    margin-right: -21px;
    margin-left: 4px;
}
.rmhpLink .tds-popup-tip__close, .noDataRmhpLink .tds-popup-tip__close{
    margin-top: -54px;
    margin-right: -14px;
}
.rmhpLink .tds-popup-tip, .noDataRmhpLink .tds-popup-tip{
    top:-2px;
    margin-left: -2px;
    margin-right: 11px;
}
.rmhpLink >div >span, .noDataRmhpLink >div >span{
    margin-right: 7px;
}

#php_link_id {
    font-size: 1rem;
}
.noDateSelector {
    display: none;
}

.dateSelectorLabel {
    display: flex;
    align-self: flex-start;
    margin-left: 6.5rem;
    font-weight: 600;
    font-size:14px !important;
    color: #4e4e53 !important
}

.historyHeading {
    width: 60%;
}

.historyHeading.flexitems{
    width: 55%;
}

.mobileHistoryHeading {
    display: none;
}

.mobilePaymentterm { 
    display: none;
}

.historyHeading > div > p {
    max-width: 100%;
}
.paymentHistoryHeader .historyHeading h2{
    margin-bottom: 1rem !important;
}
.billingHistoryHeader .historyHeading h2{
    margin-bottom: 1rem !important;
}
.billingHistoryHeader .historyHeading .paymentterm{
    margin-top: 1.8rem !important;
}
.paymentTimeframe {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80%;
}

.tds-form__select {
    width: 66% !important;
}

.historyHeading > p {
    color: #5a5a5a !important;
    font-family: UHC2020Sans-Medium;
    font-size: 16px;
    font-weight: 500;
    height: 44.8px;
    line-height: 22.4px;
    width: 443px;
}

.historyAccordion {
    display: flex;
    justify-content: center;
    max-height: 7.56rem;
    margin: 20px 0px 40px 0;
}

.historyAccordion button:hover, .historyAccordion--Collapse button:hover{
    text-decoration: underline;
}

.historyAccordion--Collapse {
    display: flex;
    justify-content: center;
    max-height: 7.56rem;
    margin: 2px 0px 42px 0;
}

.historyDateRange {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 16%;
    /* margin-right: 1.5rem; */
}

.historyDateRange > div {
    width: 11rem;
}

.historyData {
    margin-left: 2rem;
    margin-right: 2.5rem;
    margin-top: 1rem;
    width: 93%;
}

td, th {
    text-align: left;
    padding-left: 20px;
    font-family: Arial, sans-serif !important;
}

.amtTooltip span.ml-0.tds-popup-tip{
    top:-1px !important;
    display: inline-block;
    position: relative;
}

.historyData > thead > tr > th:last-child {
    border-right: none !important;
}

.historyData > tbody > tr > td:last-child {
    border-right: none !important;
}

.historyData.fivecol > thead > tr > th:not(:last-child){
    width: 19%;
}
.historyData.fivecol > thead > tr > th:nth-of-type(4) {
    width: 21%;
}

.historyData > thead > tr > th {
    border-right: 1px solid #9d9d9d !important;
    border-bottom: 2px solid #9d9d9d !important;
    color: rgb(0, 38, 119);
    font-size: 16px;
    width: 9.75rem;
    padding-bottom: 15px !important;
    border-top: none;
    border-left: none;
    text-align: left !important;
    padding-left: 15px !important;
    padding-top: 10px !important;
}

.historyData > tbody > tr > td {
    border-right: 1px solid #9d9d9d !important;
    border-bottom: 1px solid #9d9d9d !important;
    height: 2.5rem;
    border-left: none;
    text-align: left!important;
    padding-left: 15px !important;
}

.historyAccordion button{
    background:none;
    border:0px;
}

.paginate-contact-row {
    display: flex;
    justify-content: center;
    margin: 0rem 2.8rem 0 2rem;
    /* border-bottom: 1px solid #ccc; */
    padding: 35px 0px 35px;

}


.paginate-contact-row-empty{
    display: flex;
    justify-content: space-between;
    margin: 0rem 2.1rem;
    /* border-bottom: 1px solid #ccc; */
    padding: 30px 0px 30px;
    text-align: center;
}

.mobile-paginate-contact-row {
    display: none;
}

.mobileHistoryData {
    display: none;
}

.paginate-contact-row .contactUs a {
    text-transform: initial !important;
}


.paginationLink {
    height: 1.6rem;
}

.pagination {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    /* position: absolute; */
}

.cancelPendingMsg {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 2rem 3rem 0 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #ccc;

}

.cancelPendingMsg > div > p {
    /* font-size: .875rem; */
    color: rgb(51, 51, 51);
    font-weight: 500;
    height: 18.39px;
    line-height: 18.4px;
    text-align: left;
    font-size: 16px;
}

.cancelPendingMsg div:first-child {
    flex-grow: 1;
}

.cancelPendingMsg > p > a {
    text-decoration: none;
    display: inline-block;
}

.pagination > p {
    font-weight: bold;
}

.paginate-contact-row .pagination {
    margin: 0px !important;
}

.paginate-contact-row-empty .pagination {
    margin: 0px !important;
    text-align: left;
}

.paginationPageSelect {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-weight: bold;
    width: 31%;
}

.contactUs {
    display: flex;
    align-items: flex-start;
    justify-self: flex-end;
    justify-content: flex-end;
    font-style: italic;
    font-size: 14px;
    width: 40%;
    padding: 5px 15px;
}

.contactUs > a {
    text-decoration: underline;
    line-height: 1.5rem;
}

.tds-pagination {
    display: flex;
    align-items: flex-start;
}

.tds-margin-md-vertical {
    margin: 12px 0;
}

.tds-pagination__navigation-button {
    display: flex;
    align-items: center;
    background-color: unset;
    border: none;
    cursor: pointer;
}

.tds-link {
    text-decoration: none;
    line-height: 1.4;
    font-family: Arial, sans-serif;
    color: #196ecf;
    cursor: pointer;
}

.tds-link--medium {
    font-size: 1rem;
}

.tds-sr-only {
    clip: rect(0,0,0,0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.tds-pagination__section-select {
    border: none;
    background-color: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.tds-pagination__section-select-text {
    position: relative;
    font-family: Arial, sans-serif;
    font-size: 15px;
    color: #333;
}

.tds-pagination__section-select-text::after {
    content: "";
    width: 0;
    height: 1px;
    position: absolute;
    bottom: -2px;
    left: 50%;
    background-color: #333;
    transition: all 0.2s;
    text-decoration-color: #fff;
    text-decoration: none;
}

.tds-pagination__section-select-text:hover::after {
    text-decoration-color: #000;
    text-decoration: underline;
    height: 2px;
}

/* .tds-form__select {
    display: none;
    width: 240px;
    position: relative;
} */

/* .tds-form__select-control {
    box-sizing: border-box;
    font-family: "UHC Sans Medium", Arial, sans-serif;
    font-size: 1rem;
    color: #333;
    height: 2.25rem;
    width: 100%;
    background-color: #fff;
    border: 1px solid #333;
    border-radius: 4px;
    margin: 8px 0;
    padding: 4px 0;
    text-indent: 5px;
    appearance: none;
    padding-right: 2rem;
} */

.tds-form__select-expand {
    position: absolute;
    right: .4rem;
    top: .9rem;
    pointer-events: none;
}

select:not(:-internal-list-box) {
    overflow: visible !important;
}

.tds-pagination__section-select:focus:not(:focus-visible) {
    outline: none;
}

.tds-pagination__section-select:focus {
    outline-style: solid;
    outline-width: 2px;
    outline-offset: 2px;
    outline-color: #196ecf;
}

.paginationPageNum {
    z-index: 9999;
    border: 1px solid #dadbdc;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 8px #dadbdc;
    position: relative;
    display: none;
    inset: 0px auto auto 0px;
    margin: 0px;
}

.tippy-box {
    position: relative;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.4;
    outline: 0;
    transition-property: transform, visibility, opacity;
    max-width: 350px;
    transition-duration: 300ms;
}

.tippy-content {
    position: relative;
    padding: 5px 9px;
    z-index: 1;
    transition-duration: 300ms;
}

.tds-pagination__dropdown {
    text-align: center;
    max-height: 260px;
    overflow: auto;
    list-style-type: none;
    padding: 8px 24px;
    margin: 0;
}

.tds-pagination__dropdown-list-option {
    font-family: Arial, sans-serif;
    color: #5a5a5a;
    font-size: 15px;
    padding: 12px 0;
    border-bottom: 1px solid #dadbdc;
    cursor: pointer;
}

li.tds-pagination__dropdown-list-option:focus-visible {
    outline-style: solid !important;
    outline-width: 2px !important;
    outline-offset: 2px !important;
    outline-color: #196ecf !important;
}

ul#pagination-dropdown li.tds-pagination__dropdown-list-option:focus {
    outline-style: solid !important;
    outline-width: 2px !important;
    outline-offset: 2px !important;
    outline-color: #196ecf !important;
}

.tds-pagination__dropdown-list-option--active:hover {
    color:#196ecf;
    text-decoration:underline;
}

.tds-pagination__dropdown-list-option--active:focus {
    outline-style: solid !important;
    outline-width: 2px !important;
    outline-offset: 2px !important;
    outline-color: #196ecf !important;
}

.tds-pagination__dropdown-list-option--active {
    font-family: Arial,sans-serif;
    font-weight: bold;
}



.noDataPrintDownload {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin: 0 1.5rem;
    height: 21.7rem;
    width: 96%;
}

.noDataPrintDownload.pending {
    height: 27.875rem;
}

.noDataPrintDownload.processed {
    height: 21.275rem;
}

.noDataDateSelector {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: flex-end;
    width: 40%;
    margin-right: 1.5rem;
}

.noDataDateSelector.billing {
    width: 28%;
}

.noDataDateSelectorLabel {
    display: flex;
    align-self: flex-start;
    margin-left: 6.8rem;
    font-weight: 600;
}

.noHistory {
    display: flex;
    width: 100%;
    margin-top: 1rem;
    padding-left: 25px;
}

.noHistory.pending {
    margin-top: 3.75rem;
    font-weight: bold;
}

.noHistory.processed {
    margin-top: 3.75rem;
}
.isb.NoHistory.processed{
    margin-top: 0px;
    margin: 0px auto;
}
.isb.NoHistory.pending{
    margin-top: 0px;
    margin: 0px auto;
}
.noHistory > p {
    color: rgb(51, 51, 51);
    font-size: 18px;
    font-weight: bold;
    height: 1.5rem;
    line-height: 1.5rem;
    text-align: left;
    width: 37.19rem;
}

.noHistoryHeader {
    display: flex;
    align-self: flex-start;
    margin-left: 1.25rem;
    justify-content: space-between;
}

.noHistoryHeader > p {
    color: rgb(0, 38, 119) !important;
    font-size: 22.87px;
    font-weight: bold !important;
    height: 32px;
    line-height: 32px;
    text-align: center;
}

.noHistoryHeader > .noDataDateSelector > label{
    margin-left: 7rem;
}

.noHistoryHeader_subHeader {
    display: flex;
    align-self: flex-start;
    margin-top: 1.1rem;
    margin-left: 1.5rem;
}

.noHistoryHeader_subHeader > p {
    color: rgb(51, 51, 51) !important;
    font-size: 1.1rem;
    font-weight: 500;
    margin-top: 0;
}

.noHistoryHeader.processed, .noHistoryHeader.pending {
    width: 100%;
}

.processedHeader.noData {
    margin-left: 0;
    margin-top: 0;
    align-self: flex-start;
    justify-self: flex-start;
}

.pendingHeader, .processedHeader {
    display: flex;
    margin-left: 2.5rem;
    width:100%;
}

.pendingHeader.mobile {
    width: 100%;
}

.pendingHeader.noData {
    margin-left: 0;
    margin-top: 0;
}

.pendingHeader > p {
    color: rgb(0, 38, 119) !important;
    font-size: 22.87px;
    font-weight: bold;
    height: 32px;
    text-align: center;
}

.pendingHeader > h3 {
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 1.266rem !important;
    font-family: Arial,sans-serif !important;
}

.pendingHeader.subHeader {
    display: flex;
    align-self: flex-start;
    margin-top: 1.1rem;
}

.pendingHeader.subHeader > p {
    color: rgb(51, 51, 51) !important;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 0;
}

.processedHeader > p {
    color: rgb(0, 38, 119) !important;
    font-size: 22.87px;
    font-weight: bold;
    height: 32px;
    line-height: 32px;
    
}

.processedHeader > h3, .pendingHeader > h3 {
    color: rgb(0, 38, 119) !important;
    font-size: 1.266rem !important;
    font-weight: bold;
    height: 32px;
    line-height: 32px;
    font-family: Arial,sans-serif !important;
}

.processedHeader > .noDataDateSelector {
    width: 28%;
}

.historyTable.loading {
    height: 5.63rem;
}

.pendingTableHead.mop {
    width: 25% !important;
}

.pendingTableHead.action {
    border-right: unset !important;
}

.pendingTable.action {
    border-right: unset !important;
}

.editCancelDivider {
    border-right: 1px solid black;
    height: .75rem;
}

.pendingTable.action > div:first-of-type {
    display: flex;
    align-items: center;
}

.pendingTable.action > div > div:first-of-type {
    padding-right: 1rem;
    /* border-right: 1px solid black; */
}

.pendingTable.action > div > div:last-of-type {
    padding-left: 1rem;
}

.pendingTable.action.cancelOnly > div > div:last-of-type {
    padding-left: 0;
}

.printHistoryTable {
    display: none;
    margin-top: 2rem;
    height: auto;
}

.printHistoryTable > thead > tr > th {
    border-right: 1px solid #9d9d9d;
    border-bottom: 2px solid #9d9d9d;
    color: rgb(0, 38, 119);
    font-size: 16px;
    width: 8rem;
    padding-bottom: 12px;
    border-top: none;
    border-left: none;
}

.printHistoryTable > tbody > tr > td {
    border-right: 1px solid #9d9d9d;
    border-bottom: 1px solid #9d9d9d;
    height: 2.5rem;
    border-left: none;
}

.statement-unavailable {
    color: #333333 !important;
    font-weight: 400 !important;
    font-family: Arial,sans-serif !important;
    font-size: 15px !important;
    padding: 0 0 0 1.25rem !important;
}

.exportLabel a img {
    height: 24px;
    width: 24px;
    margin-right: 6px;
}

.dropdown{
    position: relative;
}

.dropdown-toggle {
    white-space: nowrap;
}

.dropdown-menu {
    position: absolute !important;
    z-index: 1000;
    border: 1px solid #dadbdc;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 0 8px #dadbdc;
    display: none;
    margin: 0;
    padding: 10px 30px;
    transform: translate3d(-15px, 6px, 0px) !important;
    inset: auto auto auto auto !important;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 15px;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: #fff;
    border-bottom: 1px solid #dadbdc;
}

a.dropdown-item:hover{
    text-decoration: underline;
    color: #196ecf;
}

.dropdown-item-active {
    font-weight:bold;
}

.dropdown-item-active:hover {
    font-weight:bold;
    text-decoration: underline !important;
    color: #196ecf !important;
}

.dropdown-button button[aria-expanded="true"] {
    background-image: url('../../assets/images/arrow-up.svg');
    background-repeat: no-repeat;
    background-position: right;
}

.dropdown-button button {
    border: 0px;
    background: none;
    padding: 5px;
    font-weight: bold;
    font-family: Arial,sans-serif;
    font-size: 15px;
    color: #333;
    cursor: pointer;
    background-image: url('../../assets/images/arrow-down.svg');
    background-repeat: no-repeat;
    background-position: right;
    width: 110px;
    text-align: left;
}

.pending_processed {
    display: flex;
    margin: 3.1rem 0 2.375rem 0;
}

.tds-tab__tab-button-wrapper button span {
    font-size:18px !important;
}


.pendingProcessed_tabs > .tds-tab > .tds-tab__tab-list-underline > .tds-tab__tab-list > .tds-tab__tab-button-wrapper > .tds-tab__tab-list-underline {
    border-bottom: unset !important;
}

.pendingProcessed_tabs > .tds-tab > .tds-tab__tab-list-underline > .tds-tab__tab-list > .tds-tab__tab-button-wrapper > .tds-tab__tab-button-underline--active {
    border-top: unset !important;
    background-color: unset;
}

.pendingProcessed_tabs > div > span {
    border-bottom: unset;
}

.tds-tab__tab-panel {
    margin: 0;
}

.pendingProcessed_tabs {
    display: flex;
    align-items: center;
}

.pending_processed > .paymentterm {
    align-self: center;    
}

.pendingProcessed_tabs > .tds-tab > .tds-tab__tab-list-underline > .tds-tab__tab-list > .tds-tab__tab-button-wrapper:first-of-type {
    margin: 0;
}

.pendingProcessed_tabs > .tds-tab > .tds-tab__tab-list-underline > .tds-tab__tab-list > .tds-tab__tab-button-wrapper:first-of-type > .tds-tab__tab-button {
    border: 2px solid rgb(0, 38, 119);
    border-radius: 21px 0 0 21px;
    height: 2.1875rem;
    min-width: 9rem;
    background-color: #fff;
    color: rgb(0, 38, 119);
    font-family: Arial,sans-serif !important;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    transition-property: unset;
    transition-duration: unset;
    transition-delay: unset;
    margin: 0;
}

.pendingProcessed_tabs > .tds-tab > .tds-tab__tab-list-underline > .tds-tab__tab-list > .tds-tab__tab-button-wrapper:first-of-type > .tds-tab__tab-button--active {
    background-color: #002677 !important;
    color: #fff !important;
}


.pendingProcessed_header_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.pendingTab {
    border: 2px solid rgb(0, 38, 119) !important;
    border-radius: 0 21px 21px 0 !important;
    margin-right: 2.5rem !important;
    min-width: 9rem !important;
    font-weight: 600 !important;
    padding-top: 5px !important;
}

.pendingTab:hover {
    background-color: unset !important;
    color: #002677 !important;
}

.pendingTab.active {
    background-color: #002677 !important;
    color: #fff !important;
    font-weight: bold;
}

.processedTab {
    border: 2px solid rgb(0, 38, 119) !important;
    border-radius: 21px 0 0 21px !important;
    min-width: 9rem !important;
    font-weight: 600 !important;
    padding-top: unset !important;
}

.processedTab:hover {
    background-color: unset !important;
    color: #002677 !important;
}

.processedTab.active {
    background-color: #002677 !important;
    color: #fff !important;
    font-weight: bold;
}

.pendingPayments {
    margin-bottom: 1.75rem;
}

.pendingProcessedHeader > h3 {
    font-size: 21pxm;
    font-family: Arial,sans-serif !important;
    line-height: 2rem;

}

.pendingSubHeader > p{
    color: rgb(51, 51, 51) !important;    
}

@media (min-width: 769px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .cancelPendingMsg {
        margin: 2rem 1rem 0 2rem;
    }

    .pagination {
        width: unset;
    }

    .billingHistoryHeader {
        height: 21.125rem !important;
        margin-left: .5rem;
        margin-top: 0;
    }
}
@media (max-width: 767px) and (min-width: 420px) {
    .historyHeading {
        display: block;
    }
    .printDownload {
        display: block;
    }
    .historyTable {
        display: block;
    }

    .historyData {
        display: block;
    }

    .historyData.fivecol {
        display: block;
    }
    .mobileHistoryHeading.billing h2{
        margin-bottom: 2rem !important;
    
    }
    .mobilePrintDownload{
        margin-top: 5px;
    }
}
@media (max-width: 768px) {
    .pendingProcessed_header_wrapper {
        align-items: center;
        flex-wrap: wrap;
    }
    .accordionContent {
        max-height: max-content;
    }

    .hideAccordionContent {
        max-height: 0px;
    }
    .tabs {
        margin: 1.5rem 0 1.5rem 1rem;
    }

    .tabs > button {
        margin: 0;
    }

    .tabs > button:nth-of-type(2) {
        margin-left:2rem !important;
    }

    .noHistoryHeader {
        margin-top: 1.75rem;
    }

    .historyTable .historyData.printHistoryTable {
        display: none;
    }

    .paymentHistoryHeader.isRMHplanMobile {
        height: 30.125rem !important;
    }
    .paymentHistoryHeader {
        height: 23.125rem;
        margin-left: .5rem;
    }

    .billingHistoryHeader {
        /* height: 19.125rem; */
        margin-left: .5rem;
        margin-top: 0;
    }

    .printHistoryTable {
        display: none;
    }

    .mobileDateSelector {
        margin-top: 3rem;
        margin-bottom: 1.81rem;
        margin: 0 auto;

    }
    .cancelPendingMsg div:first-child {
        width: 100%;
    }
    .cancelPendingMsg > div {
        text-align: center !important;
    } 
    .cancelPendingMsg > div > p {
        text-align: center !important;
    } 
    .cancelPendingMsg {
        justify-content: center;
    }
    .dateSelectorLabel {
        margin-left: 0;
    }

    .mobilePrintDownload {
        display: flex;
        flex-direction: row;
        max-height: 2rem;
        width: 100%;
    }

    .mobilePrintDownload > .exportLinks {
        justify-content: space-between;
        width: 100%;
    }

    .mobilePrintDownload > .exportLinks > .exportLabel {
        width: 50%;
    }
    
    .mobilePaymentterm {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    }

    .paymentterm.pending, .paymentterm.processed {
        margin-bottom: 0 !important;
    }

    .paymentterm.ship {
        margin-bottom: 0 !important;
    }

    .pending_processed {
        display: flex;
        justify-content: center;
        margin: 1.6rem 0 1.5rem 0;
        width:100%;
        order:1;
    }

    .paymentterm {
        order:3;
        margin-right: .5rem;
        margin-left: 1.6rem;
        margin-top: 0.8rem;
    }
    .paymentterm.isRMHPLinkMobile{
        margin-left: 1.6rem; 
        margin-bottom: -3rem !important; 
    }
    .rmhpLinkMobile{
        width: 231px;
        margin-top: 18px;
    }
    .rmhpLinkMobile > div{
        text-align: initial;
        margin-left: 2px;
        margin-top: 1rem;
    }
    .rmhpLinkMobile .tds-popup-tip, .noDataRmhpLinkMobile .tds-popup-tip{
        top:-2px
    }

    .rmhpLinkMobile .tds-margin-sm-bottom, .noDataRmhpLinkMobile .tds-margin-sm-bottom{
        margin-bottom: 26px 
    }
    .rmhpLinkMobile .tds-popup-tip__close, .noDataRmhpLinkMobile .tds-popup-tip__close{
        margin-top: -54px;
        margin-right: -14px;
    }
    .rmhpLinkMobile div a, .noDataRmhpLinkMobile div a{
        font-weight: 700 !important;
    }
    .rmhpLinkMobile a img , .noDataRmhpLinkMobile a img{
        margin-left: 2px;
        margin-right: -7px;
    }
    .noDataRmhpLinkMobile{
        margin-top: 6px;
        width: 227px;
        margin-bottom: 10px;
    }
    .noDataRmhpLinkMobile > div{
        margin-top: 15px;
    }
    .paymentterm > button {
        width: 100%;
        justify-content: flex-start;
    }

    .pendingPayments {
        order:2;
        margin-left: 1.5rem;
        margin-bottom: 1.6rem;
        width: 100%;
    }

    .processedHeader {
        margin-left: 1.5rem;
        margin-top: .8rem;
    }
    .pendingHeader {
        margin-left: 1.5rem;
        margin-top: 0rem;
    }

    .pendingHeader.subHeader {
        margin-left: 1.5rem;
        margin-top: 2rem;
    }

    .pendingHeader.subHeader > p{
        text-align: left;   
    }

    .pending_processed {
        flex-wrap: wrap;
    }

    .pending_processed.processed {
        margin: .5rem 0 1.5rem 0;
    }

    .pendingProcessed_tabs_wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .pendingProcessed_tabs {
        display: flex;
        justify-content: center;
        width: 45%;
    }

    .processedTab {
        width: 50%;
    }

    .mobileHistoryTable.loading {
        height: 7rem;
    }

    .mobileBillingterm {
        display: flex;
        height: 1.25rem;
        width: 100%;
    }

    .mobilePrintDownload.noData {
        display: none;
    }
    
    .mobileHistoryData {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-evenly;
        margin-top: 30px !important;
        border-top: 1px solid rgb(151, 151, 151);
    }

    .mobileHistoryData > tbody {
        width: 100%;
    }

    .mobileHistoryDataContainer {
        display:flex;
        flex-wrap: wrap;
        width: 100%;
        border-top: 1px solid black;
        padding: 1rem .375rem;
    }
    .mobileHistoryTable {
        display: -webkit-flex;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 92%;
        margin: 0 auto;
        margin-top: 2.625rem !important;
    }

    .mobileHistoryTable.pending {
        margin-top: 1.75rem !important;
    }

    .mobileHistoryTable.payments {
        margin-top: 0rem !important;
        padding: 0;
    }

    .mobileHistoryTable.payments > div:first-of-type {
        width: 100%;
    }
    
    .mobileHistoryTable.billing {
        margin-top: -2.0rem !important;
    }

    .mobileHistoryTable.ship {
        margin-top: -2.7rem !important;
        width: 100%;
    }

    .mobileHistoryDataCell {
        width: 50%;
    }

    .mobileHistoryDataCell > button {
        display: flex;
        padding: 0;
        /* background-color: #fff; */
        background-color: unset;
        border: 0;
    }

    .mobileHistoryDataCell > button > img {
        width: 24px;
        height: 24px;
        margin-right: 6px;
    }

    .mobileHistoryDataCell > p {
        width: 100%;
        display: flex;
        color: rgb(51, 51, 51);
        font-size: 16px;
        font-weight: 600 !important;
        margin-top: 2.3rem;
        font-weight: bold;
        margin-bottom: 2.25rem;
        height: 22px;
        line-height: 22px;
    }

    /* .mobileHistoryDataCell:last-child > p {
        font-size: 13px;
    } */

    .mobileHistoryDataHeaders {
        font-family: Arial, sans-serif;
        width: 50%;
        color: rgb(51, 51, 51);
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 2.25rem;
        height: 22px;
        line-height: 22px;
        align-self: flex-start
    }

    .mobileHistoryHeading {
        display: flex;
        flex-wrap: wrap;
        width: 29.125rem;
        margin-top: 2.406rem;
        margin-left: 1.5rem;
    }

    .mobileHistoryHeading.billing {
        margin-top: 0;
    }

    .mobileHistoryHeading.processed {
        margin-top: .5rem !important;
        margin-bottom: 1rem !important;
        width: 100%;
    }

    .mobileHistoryHeading > p {
        color: rgb(90,90,90);
        font-family: "UHC2020Sans-Medium";
        font-size: 16px;
        font-weight: 700;
        height: 44.8px;
        line-height: 22.4px;
        width: 100%;
        margin-right: 0 !important;
    }

    .mobileHistoryDataContainer {
        border-top:unset;
    }

    .mobileHistoryDataContainer:nth-child(even) {
        background-color: rgb(244, 244, 244);
    }

    .mobileHistoryDataContainer:last-child {
        border-bottom: 1px solid rgb(151, 151, 151);
     }

    .mobile-paginate-contact-row {
        display: flex;
        margin: 0rem 2.1rem;
        justify-content: center;
        padding: 30px 0px 60px;
    }

    .mobile-paginate-contact-row > .pagination {
        display: -webkit-flex;
        display: flex;
        width: 100%;
    }

    .tds-pagination {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        position: absolute;
    }

    .exportPrintLabel {
        justify-content: flex-start;
        margin-left: 1.3rem;
    }

    #exportDownloadLabel_0 {
        justify-content: flex-end;
    }

    #exportDownloadLabel_1 {
        justify-content: flex-end;
    }

    .noDataDateSelector {
        align-self: center;
        align-items: flex-start;
        justify-content: flex-end;
        width: 43%;
    }

    .noDataPrintDownload.processed {
        margin-top: 0;

    }

    .noHistory {
        margin-top: 1.5rem;
    }

    .noHistory > p {
        font-weight: bold;
    }

    .noDataDateSelectorLabel {
        margin-left: 0;
    }
    
    .tds-form__select {
        width: 12.75rem !important;
    } 

    #tabPayment {
        margin-right: 2rem;
    }

    #tabBilling {
        margin-left: 2rem;
    }
    .mobileHistoryHeading.billing h2 {
        height: 0rem;
    }

    .pendingTable.action {
        margin-left: 0;
        display: flex;
        align-items: center;
    }

    .editCancelDivider {
        padding-left: 1rem !important;
    }

    .pendingTable.action > div:last-of-type {
        padding-left: .5rem !important;
    }

    .pendingTable.action.cancelOnly > div:last-of-type {
        padding-left: 0 !important;
    }

    .pendingTable.action > div:last-of-type > a {
        margin-left: 5px !important;
    }

    .pendingTable.action.cancelOnly > div:last-of-type > a{
        margin-left: 0 !important;
    }
}

@media (max-width: 430px) {
    .mobileHistoryTable.billing {
        margin-top: 0rem !important;
        width: 100%;
    }
    .noDataPrintDownload {
        border-top: 1px solid rgb(151, 151, 151);
        border-bottom: none;
        margin: 0;
        height: unset;
        width: 100%;
        padding: 2.25rem 0;
    }
}

@media (max-width: 429px) {

    .tabs > button:nth-of-type(2) {
        margin-left:unset !important;
    }

    .tabs > span > div {
        margin-left: 1rem;
    }

    .mobileHistoryTable.pending {
        margin-top: 0rem !important;
        width: 100%;
    }

    .billingHistoryHeader {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    .mobileHistoryTable.billing {
        margin-top: 0rem !important;
        width: 100%;
    }

    .mobileHistoryTable.ship {
        width: 100%;
    }

    .mobileHistoryDataContainer {
        padding: 0 .375rem;
    }

    .mobileHistoryDataCell {
        width: 50%;
    }

    .mobileHistoryDataContainer {
        padding-top: 1rem;
    }

    .mobileHistoryDataCell:last-child {
        height: 1rem;
        padding-right: .4rem;
    }

    .mobileHistoryDataCell:last-child > button > span {
        font-size: .8125rem;
        align-self: center;
    }

    .mobile-paginate-contact-row {
        margin: 0;
        text-align: center;
    }

    .mobileHistoryHeading {
        margin-top: 0;
    }

    .mobileHistoryHeading > div > p {
        font-size: .9rem;
    }

    .cancelPendingMsg {
        margin-left: 0rem;
        margin: 2rem 0 0 0;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        display: flex !important;
    }

    .cancelPendingMsg > div {
        width: 100%;
        margin: 0 2rem;
    }
    /* .cancelPendingMsg > div > p {
        text-align: center;
    } */

    .cancelPendingMsg > div:first-child {
        margin-bottom: 2.6rem !important;
    }

    .tds-pagination__navigation-button:first-child {
        justify-content: flex-start;
        width: 40%;
    }

    .tds-pagination__navigation-button:last-child {
        justify-content: center;
        width: 25%;
    }

    .paymentHistoryHeader.pendingProcessed {
        margin-top: 0;
    }

    .pendingProcessed_header_wrapper {
        flex-wrap: wrap;
    }
    .pending_processed {
        margin: 1.25rem 0 0 0;
    }

    .pending_processed.processed {
        margin: .5rem 0 0 0;
    }

    .mobilePaymentterm {
        margin-bottom: 1.75rem;
    }

    .paymentterm {
        margin-bottom: -1rem !important;
    }

    .paymentterm > button {
        width: 100%;
        justify-content: flex-start;
    }

    .exportLinks {
        margin: 0;
    }

    .noDataPrintDownload {
        border-top: 1px solid rgb(151, 151, 151);
        border-bottom: none;
        margin: 0;
        height: unset;
        width: 100%;
        padding: 2.25rem 0;
    }

    .noDataPrintDownload.processed {
        height: 6.275rem;
    }

    .noDataHistory {
        margin-top: 1rem;
    }

    .noHistory {
        margin-top: unset;
    }

    .mobile-paginate-contact-row {
        padding: 30px 0;
    }

    .mobile-paginate-contact-row.noProcessed {
        padding: 0;
    }

    .mobile-paginate-contact-row.noPending {
        border-top: 1px solid rgb(151, 151, 151);
    }

    .pendingProcessed_tabs {
        width: 85%;
        margin-left: 2.8rem !important;
    }

    .pendingHeader {
        margin-top: 0rem;
        margin-left: 1.5rem;
    }

    .pendingHeader.subHeader {
        margin: 1rem 0 1rem 1.25rem
    }

    .noDataDateSelector {
        margin: 0;
        align-items: center;
        width: 100%;
    }
    
    .mobilePrintDownload >.exportLinks > .exportLabel {
        width: unset;
    }

    #exportDownloadLabel_0 {
        width: 100%;
        justify-content: flex-end;
        margin-right: .5rem;
        margin-left: 2.4rem !important;
    }
    #exportDownloadLabel_1 {
        width: 100%;
        justify-content: flex-end;
        margin-right: .5rem;
        margin-left: 2.4rem !important;
    }
    .tds-tab__tab-list-underline{
        margin-bottom: 2.5rem !important;
    }
    .paymentHistoryHeader.pendingProcessed{
        margin-bottom: 0px;
    }
    .mobileHistoryTable.payments{
        margin-top: 2.625rem !important;
    }
    .mobileHistoryTable.payments .mobileHistoryData, .mobileHistoryTable.pending .mobileHistoryData{
        margin-top: 0px !important;
    }
    .mobileHistoryHeading.processed h2 {
        height: 2.8rem !important;
    }
    .mobileHistoryHeading.billing h2 {
        height: auto;
    }
    .historyAccordion--Collapse{
        margin-top: 20px !important;
    }
    .payMethodSubHeading h4{
        padding: 10px 0px !important;
    }

}

@media (max-width: 321px) {
    .processedTab > button {
        margin-right: 0px !important;
        min-width: 8rem !important;
    }
    .mobileHistoryTable.payments {
        margin-top: 1.5rem !important;
    }
    .pendingProcessed_tabs {
        margin-left: 2.5rem !important;
    }

    .mobileHistoryHeading {
        margin-left: 0;
    }

    .pendingHeader {
        margin-left: 1.5rem;
    }

    .mobileHistoryData {
        margin-top: 49px !important;
    }

    .mobileHistoryTable.billing {
        margin-top: -3rem !important;
        width: 100%;
    }

    .processedHeader, .pendingHeader {
        margin-left: 0;
    }
    
    .mobile {
        padding: 0 !important;
        width: 100%;
    }
    
    .paymentterm.pending, .paymentterm.processed {
        margin-bottom: 3rem !important;
    }

    .paymentHistoryHeader.pendingProcessed.processed {
        margin-bottom: -2rem;
    }

    .paymentHistoryHeader.pendingProcessed {
        margin-bottom: -5rem;
    }
}